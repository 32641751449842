import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import ClubViewMain from "../clubview/ClubMain";
import { clubsController, competitionsController } from "../../database/controllers";

const ClubFixturesSchedule = (props) => {
  const clubId = useSelector((state) => state.club.id);
  const [participant, setParticipant] = useState(null);
  const [matches, setMatches] = useState([]);
  const [viewClubId, setViewClubId] = useState(null); // State to manage the visibility of ClubViewMain
  const [club, setClub] = useState(null);

  useEffect(() => {
    if (clubId) {
      clubsController
        .findOne(clubId)
        .then((club) => {
          setClub(club);
        })
        .catch(console.error);
    }
  }, [clubId]);

  // Copied from SelectClub.js could better be a component instead of keep repeating the same code
  const handleCloseModal = () => {
    setViewClubId(null);
  };

  useEffect(() => {
    if (club) {
      competitionsController
        .findMatchesByClub(club.id)
        .then(({ participant, matches }) => {
          setParticipant(participant);
          setMatches(matches);
        })
        .catch(console.error);
    }
  }, [club]);

  if (!club || !participant) {
    return <div className="game-main">Loading...</div>;
  }

  return (
    <>
      <div className="game-status">Season playing schedule for Division {participant._division?.divisionNo}</div>
      <div className="game-status mb-2 bg-black/50 text-center text-lg font-bold text-yellow-400 py-2">Devlog: Also the scores should be printed if a match is already played</div>
      <div className="bg-black/50 p-2 w-full flex justify-center">
        <div className="w-full h-full overflow-y-auto">
          {matches.map((match, i) => {
            return (
              <div key={i} className="flex border-b border-gray-500 py-2">
                <div className="w-1/6 text-center">
                  <button className="w-full rounded shadow-sm border text-white">{moment(match.matchDate).format("ll")}</button>
                </div>
                <div
                  style={{ cursor: match._participant1?._club?.id === clubId ? "default" : "pointer" }}
                  className="w-5/12 text-white font-semibold text-center"
                  onClick={() => {
                    if (match._participant1?._club?.id !== clubId) setViewClubId(match._participant1?._club?.id);
                  }}
                >
                  {match._participant1?._club?.name}
                </div>
                <div className="w-1/12 text-yellow-300 font-semibold text-center">VS</div>
                <div
                  style={{ cursor: match._participant2?._club?.id === clubId ? "default" : "pointer" }}
                  className="w-5/12 text-white font-semibold text-center"
                  onClick={() => {
                    if (match._participant2?._club?.id !== clubId) setViewClubId(match._participant2?._club?.id);
                  }}
                >
                  {match._participant2?._club?.name}
                </div>

                {/* <div onClick={() => setViewClubId(match.Participant1.Club.id)} style={{ cursor: 'pointer' }} className="w-5/12 text-white font-semibold text-center">{match.Participant1.Club.name}</div>
                <div className="w-1/12 text-yellow-300 font-semibold text-center">VS</div>
                <div onClick={() => setViewClubId(match.Participant2.Club.id)} style={{ cursor: 'pointer' }} className="w-5/12 text-white font-semibold text-center">{match.Participant2.Club.name}</div> */}
              </div>
            );
          })}
        </div>
      </div>
      {viewClubId && <ClubViewMain clubId={viewClubId} closeModal={handleCloseModal} />}
    </>
  );
};

export default ClubFixturesSchedule;
