import React, { useEffect, useState } from "react";
import staffImage from "../assets/images/StaffImage.jpg";
import FacilitiesExpansion from "../assets/images/FacilitiesExpansion.jpg";
import Sponsor from "../assets/images/Sponsor.jpg";
import InteractingFans from "../assets/images/InteractingFans.jpg";
import ManageClub from "../assets/images/ManageClub.jpg";
import BoardConfidence from "../assets/images/BoardConfidence.jpg";
import { Link } from "react-scroll";
import { useSelector } from "react-redux";
import { clubsController } from "../../database/controllers";

const ClubManage = (props) => {
  const clubId = useSelector((state) => state.club.id);
  const [club, setClub] = useState(null);

  useEffect(() => {
    if (clubId) {
      clubsController
        .findOne(clubId)
        .then((club) => {
          setClub(club);
        })
        .catch(console.error);
    }
  }, [clubId]);

  const [isSponsorsVisible, setIsSponsorsVisible] = useState(true);
  const [isFansVisible, setIsFansVisible] = useState(true);
  const [isFacilitiesVisible, setIsFacilitiesVisible] = useState(true);
  const [isStaffVisible, setIsStaffVisible] = useState(true);
  const [isBoardConfidenceVisible, setIsBoardConfidenceVisible] = useState(true);
  const [isClubManagementVisible, setIsClubManagementVisible] = useState(true);

  const numPhysios = () => club._staffContracts.filter((contract) => contract._staff && contract._staff.role === "physio").length;
  const numCoaches = () => club._staffContracts.filter((contract) => contract._staff && contract._staff.role === "coach").length;
  const numScouts = () => club._staffContracts.filter((contract) => contract._staff && contract._staff.role === "scout").length;
  const numTrainers = () => club._staffContracts.filter((contract) => contract._staff && contract._staff.role === "trainer").length;

  if (!club) {
    return <div className="game-main">Loading...</div>;
  }

  return (
    <>
      {/* <div className="game-status">On this screen I will work on managing staff and facilities and other things!</div> */}
      <div className="game-status text-yellow-500">WIP: Need to make this work with financial system</div>

      <div className="container mx-auto p-2">
        <div className="grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          {/* Sponsor Money Section */}
          <div className="card-white">
            <h2 className="animate text-2xl font-bold mb-4 text-center">Sponsor Money</h2>
            <Link to="sponsors" smooth={true} duration={500}>
              <img src={Sponsor} alt="Sponsor" />
            </Link>
            <p className="card-text">Sponsors income: $1,200,000</p>
            <div className="mt-auto">
              <button className="menu-btn rounded-lg">
                <Link to="sponsors" smooth={true} duration={500} onClick={() => setIsSponsorsVisible(true)}>
                  Manage Sponsors
                </Link>
              </button>
            </div>
          </div>

          {/* Fan Interaction Section */}
          <div className="card-white">
            <h2 className="text-2xl font-bold mb-4 text-center">Fan Interaction</h2>
            <Link to="fan" smooth={true} duration={500}>
              <img className="" src={InteractingFans} alt="InteractingFans" />
            </Link>
            <p className="card-text">Total Fans: {club.fanbase}</p>
            <div className="mt-auto">
              <button className="menu-btn rounded-lg">
                <Link to="fan" smooth={true} duration={500} onClick={() => setIsFansVisible(true)}>
                  Interact with Fans
                </Link>
              </button>
            </div>
          </div>

          {/* Facilities Expansion Section */}
          <div className="card-white">
            <h2 className="text-2xl font-bold mb-4 text-center">Facilities Expansion</h2>
            <Link to="facilities" smooth={true} duration={500}>
              <img className="" src={FacilitiesExpansion} alt="Staff" />
            </Link>
            <p className="card-text">Stadium Capacity: {club._stadium.capacity}</p>
            <p className="card-text">Training Ground Level: </p>
            <p className="card-text">Staff Building Level: </p>
            <div className="mt-auto">
              <button className="menu-btn rounded-lg">
                <Link to="facilities" smooth={true} duration={500} onClick={() => setIsFacilitiesVisible(true)}>
                  Expand Facilities
                </Link>
              </button>
            </div>
          </div>

          {/* Team Management Section */}
          <div className="card-white">
            <h2 className="text-2xl font-bold mb-4 text-center">Staff Management</h2>
            <Link to="staff" smooth={true} duration={500}>
              <img className="" src={staffImage} alt="Staff" />
            </Link>

            <p className="card-text">Total Coaches: {numCoaches()}</p>
            <p className="card-text">Total Physios: {numPhysios()}</p>
            <p className="card-text">Total Scouts: {numScouts()}</p>
            <p className="card-text">Total Trainers: {numTrainers()}</p>

            <div className="mt-auto">
              <button className="menu-btn rounded-lg">
                <Link to="staff" smooth={true} duration={500} onClick={() => setIsStaffVisible(true)}>
                  Manage Staff
                </Link>
              </button>
            </div>
          </div>

          {/* Board Confidence Section */}
          <div className="card-white">
            <h2 className="text-2xl font-bold mb-4 text-center">Board Confidence</h2>
            <Link to="board" smooth={true} duration={500}>
              <img className="" src={BoardConfidence} alt="Board" />
            </Link>
            <p className="card-text">Board Confidence checkup</p>
            <div className="mt-auto">
              <button className="menu-btn rounded-lg">
                <Link to="board" smooth={true} duration={500} onClick={() => setIsBoardConfidenceVisible(true)}>
                  View Board Confidence
                </Link>
              </button>
            </div>
          </div>

          {/* Club Management Section */}
          <div className="card-white">
            <h2 className="text-2xl font-bold mb-4 text-center">Club Management</h2>
            <Link to="management" smooth={true} duration={500}>
              <img className="" src={ManageClub} alt="ManageClub" />
            </Link>
            <p className="card-text">Change club colorst</p>
            <p className="card-text">Change Shirts</p>
            <p className="card-text">Change Logo</p>
            <div className="mt-auto">
              <button className="menu-btn rounded-lg">
                <Link to="management" smooth={true} duration={500} onClick={() => setIsClubManagementVisible(true)}>
                  View Club Management
                </Link>
              </button>
            </div>
          </div>
        </div>

        {/* Accordion Section */}
        <div className="mt-6">
          {isSponsorsVisible && (
            <div id="sponsors" className="bg-white opacity-70 py-2 p-6 rounded-lg shadow-md mb-4">
              <div className="flex justify-between items-start">
                <h2 className="text-xl font-semibold mb-4">Manage Sponsors</h2>
                <button onClick={() => setIsSponsorsVisible(false)} className="focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-500">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <p>Here you can manage all your sponsorships and sponsors for better revenue.</p>
            </div>
          )}

          {isFansVisible && (
            <div id="fan" className="bg-white opacity-70 py-2 p-6 rounded-lg shadow-md mb-4">
              <div className="flex justify-between items-start">
                <h2 className="text-xl font-semibold mb-4">Interact with Fans</h2>
                <button onClick={() => setIsFansVisible(false)} className="focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-500">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <p>Engage with fans through social media, events, and promotions to increase fan loyalty.</p>
            </div>
          )}

          {isFacilitiesVisible && (
            <div id="facilities" className="bg-white opacity-70 py-2 p-6 rounded-lg shadow-md mb-4">
              <div className="flex justify-between items-start">
                <h2 className="text-xl font-semibold mb-4">Expand Facilities</h2>
                <button onClick={() => setIsFacilitiesVisible(false)} className="focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-500">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <p>Invest in upgrading your stadium, training grounds, and staff facilities to improve overall performance.</p>
            </div>
          )}

          {isStaffVisible && (
            <div id="staff" className="bg-white opacity-70 py-2 p-6 rounded-lg shadow-md mb-4">
              <div className="flex justify-between items-start">
                <h2 className="text-xl font-semibold mb-4">Manage Staff</h2>
                <button onClick={() => setIsStaffVisible(false)} className="focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-500">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <p>Review all your staff and take desired actions.</p>
            </div>
          )}

          {isBoardConfidenceVisible && (
            <div id="board" className="bg-white opacity-70 py-2 p-6 rounded-lg shadow-md mb-4">
              <div className="flex justify-between items-start">
                <h2 className="text-xl font-semibold mb-4">View Board Confidence</h2>
                <button onClick={() => setIsBoardConfidenceVisible(false)} className="focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-500">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <p>Check if your on schedule to meet board demands and goals.</p>
            </div>
          )}

          {isClubManagementVisible && (
            <div id="management" className="bg-white opacity-70 py-2 p-6 rounded-lg shadow-md mb-4">
              <div className="flex justify-between items-start">
                <h2 className="text-xl font-semibold mb-4">View Club Management</h2>
                <button onClick={() => setIsClubManagementVisible(false)} className="focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-500">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <p>Club Management here you can adjust club properties like logo or shirts or colors of the club.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ClubManage;
