import { createSlice } from "@reduxjs/toolkit";

export const managerSlice = createSlice({
  name: "manager",
  initialState: {
    firstName: "",
    lastName: "",
    country: "",
  },
  reducers: {
    setManager: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setManager } = managerSlice.actions;

export default managerSlice.reducer;
