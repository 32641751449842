export const nl = {

  translation: {

    general: {
      alldivision: "Alle Divisies",
      division1: "Eerste Divisie",
      division2: "Tweede Divisie",
      division3: "Derde Divisie",
      division4: "Vierde Divisie",
      founded: "Opgericht",
      generalinfo: "Algemene Informatie",
      stadiumground: "Stadion en club faciliteiten",
      stadiumname: "Stadionnaam",
      stadiumcapacity: "Stadioncapaciteit",
      fanbase: "Fanbase",
      reputation: "Reputatie",
      youthfacilities: "Jeugdvoorzieningen",
      trainingfacilities: "Trainingsfaciliteiten",
      trainingfields: "Trainingsvelden",
      transferbudget: "Transferbudget",
      wagebudget: "Loonbudget",
      league: "Competitie",
      league_position: "Competitie Positie",
    },

    header: {
      title: "Welkom bij FootballClubBuilder!",
    },

    menu: {
      inbox: "Inbox",
      introduction: "Introductie",
      selectClub: "Selecteer Club",
      scouting: "Scouting",
      settings: "Instellingen",
      credits: "Credits",
      startGame: "Start Spel",
      clubInfo: "Club Info",
      clubFinances: "Club Financiën",
      clubFixtures: "Club Programma",
      clubSquad: "Club Selectie",
      clubManage: "Club Beheer",
      clubYouthPlayers: "Jeugdspelers Club",
      competitions: "Competities",
      competitionsSecondDivision: "Tweede Divisie",
      competitionsThirdDivision: "Derde Divisie",
      competitionsFourthDivision: "Vierde Divisie",
      competitionsCupCompetition: "Bekercompetitie",
      currencySetting: "Valuta instellingen",
      languageSetting: "Taalinstellingen",
      manageTeam: "Manage Team",
      myTeamTactics: "Mijn Teamtactieken",
      selectClubToManage: "Selecteer je club om te beheren",
      otherTeamTactics: "Andere Teamtactieken",
      exitGame: "Spel stoppen",
      enterDetails: "Voer je gegevens in",
      back: "Terug",
      next: "Volgende",
    },

    add: {
      addManager: "Voeg Manager Toe",
      selectAClub: "Selecteer een Club",
      select: "Selecteer",
    },
  },
};
