import { useEffect, useState } from "react";
import MatchOverview from "./MatchOverview";
import MatchStats from "./MatchStats";
import ActionZones from "./ActionZones";
import MatchReport from "./MatchReport";
import LeagueStats from "./LeagueStats";
import PlayerRatings from "./PlayerRatings";
import LeagueTable from "./LeagueTable";

const MainField = ({ club }) => {
  const [view, setView] = useState(1);

  const renderView = () => {
    let section = <MatchOverview />;

    switch (view) {
      case 1:
        section = <MatchOverview />;
        break;
      case 2:
        section = <MatchStats />;
        break;
      case 3:
        section = <ActionZones />;
        break;
      case 4:
        section = <MatchReport />;
        break;
      case 5:
        section = <LeagueStats />;
        break;
      case 6:
        section = <PlayerRatings />;
        break;
      case 7:
        section = <LeagueTable />;
        break;
      case 8:
        section = <LeagueStats />;
        break;
      default:
        section = <MatchOverview />;
    }

    return section;
  };

  if (!club) {
    return <div className="game-main">Loading...</div>;
  }

  return (
    <>
      <div className="clubs-title w-full h-14 flex shadow mb-2">
        <div className="club-1 h-full w-full bg-red-500 flex items-center justify-between p-3 rounded-l">
          <div className="club-name text-lg text-white font-bold">{club.name}</div>
          <div className="goal-count w-10 h-10 rounded bg-white shadow-inner flex items-center justify-center text-xl font-bold">0</div>
        </div>
        <div className="club-2 h-full w-full bg-blue-500 flex items-center justify-between p-3 rounded-r">
          <div className="club-name text-lg text-white font-bold">$OpponentName</div>
          <div className="goal-count w-10 h-10 rounded bg-white shadow-inner flex items-center justify-center text-xl font-bold">0</div>
        </div>
      </div>
      <div className="game-menu mb-2 flex">
        <button onClick={() => setView(1)} className="blue-btn">
          Match Overview
        </button>
        <button onClick={() => setView(2)} className="blue-btn">
          Match Stats
        </button>
        <button onClick={() => setView(3)} className="blue-btn">
          Action Zones
        </button>
        <button onClick={() => setView(4)} className="blue-btn">
          Match Report
        </button>
      </div>
      {renderView()}
      <div className="bottom-menu mb-2 flex">
        <button onClick={() => setView(5)} className="blue-btn">
          {club.name} Stats
        </button>
        <button onClick={() => setView(6)} className="blue-btn">
          Player Ratings
        </button>
        {/* <button className="blue-btn">Latest Scores</button> */}
        <button onClick={() => setView(7)} className="blue-btn">
          League Table
        </button>
        <button onClick={() => setView(8)} className="blue-btn">
          Opponent.club Stats
        </button>
      </div>
      <div className="stadium-name font-semibold text-center bg-gray-400 text-white rounded shadow py-1 px-2">Field Mill, Mansfield</div>
    </>
  );
};

export default MainField;
