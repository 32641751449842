import fullStar from "../assets/images/icons/fluent_star_filled_icon.svg";
import halfStar from "../assets/images/icons/fluent_star_half_filled_icon.svg";
import quarterStar from "../assets/images/icons/fluent_star_one_quarter_icon.svg";
import threeQuarterStar from "../assets/images/icons/fluent_star_three_quarter_icon.svg";
import emptyStar from "../assets/images/icons/fluent_star_empty_icon.svg";


// Convert floating point numbers to star ratings in the UI
function createStarRating(value, maxStars = 10) { 
  if (typeof value !== 'number' || value < 0) {
    console.error(`Invalid value for star rating: ${value}`);
    return { stars: [] };
  }

  const wholeStars = Math.floor(value);
  let starFraction = 0;

  const decimal = value - wholeStars;

  if (decimal >= 0.75) {
    starFraction = 0.75;
  } else if (decimal >= 0.5) {
    starFraction = 0.5;
  } else if (decimal >= 0.25) {
    starFraction = 0.25;
  }

  const stars = Array(wholeStars).fill(fullStar);
  if (starFraction === 0.25) stars.push(quarterStar);
  else if (starFraction === 0.5) stars.push(halfStar);
  else if (starFraction === 0.75) stars.push(threeQuarterStar);

  // Fill the rest of the stars array with empty stars up to maxStars
  while (stars.length < maxStars) {
    stars.push(emptyStar);
  }

  return { stars };
}

export default createStarRating;