import database from "..";
import { faker } from "@faker-js/faker";

const facilities = ["Poor", "Minimal", "Adequate", "Good", "Excellent", "Top Class"];
const clubreputation = ["Amateur", "City level", "Regional", "Provincial", "National", "Continental", "Worldwide"];

const clubSeeder = {
  tableName: "clubs",

  async up() {
    await database.write(async () => {
      const stadiums = await database.get("stadiums").query().fetch();
      const table = database.get(this.tableName);

      for (let i = 0; i < stadiums.length; i++) {
        await table.create((tb) => {
          tb.stadium.set(stadiums[i]);
          tb.name = faker.location.city() + " Club";
          tb.founded = Math.floor(Math.random() * (1980 - 1870 + 1)) + 1870;
          tb.fanbase = faker.number.int(50000, 100000);
          tb.desc = faker.lorem.sentences(6);
          tb.address = faker.location.streetAddress();
          tb.reputation = clubreputation[Math.floor(Math.random() * clubreputation.length)];
          tb.youthFacilities = facilities[Math.floor(Math.random() * facilities.length)];
          tb.trainingFacilities = facilities[Math.floor(Math.random() * facilities.length)];
          tb.transferBudget = faker.number.int({ min: 150000, max: 15000000 });
          tb.wageBudget = faker.number.int({ min: 150000, max: 1500000 });
        });
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default clubSeeder;
