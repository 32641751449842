export const fr = {
  translation: {

    general: {
      alldivision: "Toutes les divisions",
      division1: "Première Division",
      division2: "Deuxième Division",
      division3: "Troisième Division",
      division4: "Quatrième Division",
      founded: "Fondé",
      generalinfo: "Informations Générales",
      stadiumground: "Vue d'ensemble du Stade",
      stadiumname: "Nom du Stade",
      stadiumcapacity: "Capacité du Stade",
      fanbase: "Base de Fans",
      reputation: "Réputation",
      youthfacilities: "Installations de Jeunes",
      trainingfacilities: "Installations d'Entraînement",
      trainingfields: "Terrains d'Entraînement",
      transferbudget: "Budget de Transfert",
      wagebudget: "Budget de Salaire",
      league: "Ligue",
      league_position: "Position en Ligue",
    },

    header: {
      title: "Bienvenue sur FootballClubBuilder!",
    },

    menu: {
      inbox: "Messages",
      introduction: "Introduction",
      selectClub: "Sélectionner le Club",
      scouting: "Recrutement",
      settings: "Paramètres",
      credits: "Crédits",
      startGame: "Commencer le jeu",
      clubInfo: "Infos du Club",
      clubFinances: "Finances du Club",
      clubFixtures: "Calendrier du Club",
      clubSquad: "Équipe du Club",
      clubManage: "Gestion du Club",
      clubYouthPlayers: "Jeunes Joueurs du Club",
      competitions: "Compétitions",
      competitionsSecondDivision: "Deuxième Division",
      competitionsThirdDivision: "Troisième Division",
      competitionsFourthDivision: "Quatrième Division",
      competitionsCupCompetition: "Compétition de Coupe",
      currencySetting: "Paramètres de devise",
      exitGame: "Arrêter",
      enterDetails: "Entrez vos informations personnelles",
      languageSetting: "Paramètres de langue",
      myTeamTactics: "Tactiques de Mon Équipe",
      manageTeam: "Gérer l'équipe",
      otherTeamTactics: "Tactiques de l'Autre Équipe",
      selectClubToManage: "Sélectionnez votre club à gérer",
      back: "Retour",
      next: "Suivant",
    },

    add: {
      addManager: "Ajouter un Manager",
      select: "Sélectionner",
    },
  },
};
