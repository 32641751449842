import { Model } from "@nozbe/watermelondb";
import { field, text, children, relation, date } from "@nozbe/watermelondb/decorators";

class ClubFinance extends Model {
  static table = "club_finances";

  static associations = {
    clubs: { type: "belongs_to", key: "club_id" },
  };

  @relation("clubs", "club_id") club;

  @field("i_tv_money") iTvMoney;
  @field("i_sponsors") iSponsors;
  @field("i_transfers") iTransfers;
  @field("i_price_money") iPriceMoney;
  @field("i_ticket_sales") iTicketSales;
  @field("i_merchandise") iMerchandise;
  @field("i_other") iOther;
  @field("e_player_salaries") ePlayerSalaries;
  @field("e_staff_salaries") eStaffSalaries;
  @field("e_transfer_fees") eTransferFees;
  @field("e_tadium_maintenance") eStadiumMaintenance;
  @field("e_youth_development") eYouthDevelopment;
  @field("e_scouting") eScouting;
  @field("e_other") eOther;
}

export default ClubFinance;
