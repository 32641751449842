// import {
//   FiChevronLeft,
//   FiChevronRight,
//   FiChevronsLeft,
//   FiChevronsRight,
// } from 'react-icons/fi'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageClick = (ev, page) => {
    ev.preventDefault();
    onPageChange(page);
  };

  return (
    <nav className="mt-3 flex justify-between" aria-label="Page navigation">
      <div></div>
      <ul className="flex items-center -space-x-px h-10 text-base">
        <li>
          <button
            disabled={currentPage <= 1}
            onClick={(ev) => handlePageClick(ev, 1)}
            className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-50 bg-gray-500/30 border rounded-s-lg border-gray-50 hover:bg-gray-500 disabled:bg-gray-500"
          >
            {/* <FiChevronsLeft size={22} /> */}
            {"<<"}
          </button>
        </li>
        <li>
          <button
            disabled={currentPage <= 1}
            onClick={(ev) => handlePageClick(ev, currentPage > 1 ? currentPage - 1 : 1)}
            className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-50 bg-gray-500/30 border border-gray-50 hover:bg-gray-500 disabled:bg-gray-500"
          >
            {/* <FiChevronLeft size={22} /> */}
            {"<"}
          </button>
        </li>

        {currentPage - 2 >= 1 && (
          <li>
            <button
              onClick={(ev) => handlePageClick(ev, currentPage - 2)}
              className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-50 bg-gray-500/30 border border-gray-50 hover:bg-gray-500"
            >
              {currentPage - 2}
            </button>
          </li>
        )}
        {currentPage - 1 >= 1 && (
          <li>
            <button
              onClick={(ev) => handlePageClick(ev, currentPage - 1)}
              className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-50 bg-gray-500/30 border border-gray-50 hover:bg-gray-500"
            >
              {currentPage - 1}
            </button>
          </li>
        )}
        <li>
          <button
            disabled
            aria-current="page"
            className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-50 bg-gray-500/30 border border-gray-50 hover:bg-gray-500 font-bold disabled:bg-gray-500"
          >
            {currentPage}
          </button>
        </li>
        {currentPage + 1 <= totalPages && (
          <li>
            <button
              onClick={(ev) => handlePageClick(ev, currentPage + 1)}
              className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-50 bg-gray-500/30 border border-gray-50 hover:bg-gray-500"
            >
              {currentPage + 1}
            </button>
          </li>
        )}
        {currentPage + 2 <= totalPages && (
          <li>
            <button
              onClick={(ev) => handlePageClick(ev, currentPage + 2)}
              className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-50 bg-gray-500/30 border border-gray-50  hover:bg-gray-500"
            >
              {currentPage + 2}
            </button>
          </li>
        )}

        <li>
          <button
            disabled={currentPage >= totalPages}
            onClick={(ev) => handlePageClick(ev, currentPage < totalPages ? currentPage + 1 : totalPages)}
            className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-50 bg-gray-500/30 border border-gray-50 hover:bg-gray-500 disabled:bg-gray-500"
          >
            {/* <FiChevronRight size={22} /> */}
            {">"}
          </button>
        </li>
        <li>
          <button
            disabled={currentPage >= totalPages}
            onClick={(ev) => handlePageClick(ev, totalPages)}
            className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-50 bg-gray-500/30 border rounded-e-lg border-gray-50 hover:bg-gray-500 disabled:bg-gray-500"
          >
            {/* <FiChevronsRight size={22} /> */}
            {">>"}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
