import ClubScreenHeader from "../helpers/ClubScreenHeader";

const GameCredits = (props) => {
  return (
    <div className="game-main">
      <ClubScreenHeader title="Football Club Builder" />

      <div className="game-status">Game Credits</div>

      <div className="board-main p-2 mb-2 rounded bg-black/20">
        <div className="bg-black/50 p-2 w-full flex justify-center">
          <div className="w-full h-96 overflow-y-auto flex justify-center">
            <table className="w-full ml-6 mr-6">
              <tbody>
                {[
                  {
                    name: "Ruud",
                    url: "https://www.footballclubbuilder.com",
                    siteName: "Footballclubbuilder.com",
                  },
                  
                  ...Array(13).fill({ name: "", url: "", siteName: "" }),
                ].map((item, i) => (
                  <tr key={i} className="border-b border-gray-500">
                    <td className="w-20 text-yellow-400 px-2 text-center font-bold">
                      {i + 1}
                    </td>
                    <td className="text-white px-2 font-bold">{item.name}</td>
                    <td className="text px-2 font-bold">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {item.siteName}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="bottom-menu mb-2 grid grid-cols-1 gap-1">
        <button
          onClick={props.onGoBack}
          className="menu-btn w-full h-full text-center bg-gray-400 text-white p-1 rounded shadow"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default GameCredits;