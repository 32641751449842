import { useState } from "react";

const LeagueStats = () => {
   return (
      <div className="board-main mb-2 rounded bg-black/30 p-2">
         <div className="h-96 overflow-y-auto">
            <table className="w-full">
               <tr className="border-b border-gray-500">
                  <th className="w-20 text-yellow-400 px-2 text-center"></th>
                  <th className="text-white px-2"></th>
                  <th className="text-white px-2">Pas</th>
                  <th className="text-white px-2">Cmp</th>
                  <th className="text-white px-2">Key</th>
                  <th className="text-white px-2">Tck</th>
                  <th className="text-white px-2">Won</th>
                  <th className="text-white px-2">Hea</th>
                  <th className="text-white px-2">Point</th>
               </tr>
               {Array(10)
                  .fill(1)
                  .map((v) => (
                     <tr className="border-b border-gray-500">
                        <td className="w-16 text-yellow-400 px-2 text-center">
                           <button className="border px-4 rounded-sm shadow-sm">1</button>
                        </td>
                        <td className="text-white">Gavin Kelly</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">2</td>
                     </tr>
                  ))}
            </table>
         </div>
      </div>
   );
};

export default LeagueStats;
