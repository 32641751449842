import { useState } from "react";
import ClubScreenHeader from "../helpers/ClubScreenHeader";
import InboxBackgroundImage from "../assets/images/InboxBackgroundImage.jpg";
import { useSelector } from "react-redux";

// I want to use the icon with new emails to show the manager that there are new messages in the inbox

const ManagerInbox = (props) => {
  const [messages, setMessages] = useState([
    {
      id: 1,
      content:
        "Surprise announcement! $YourName has just been named manager of $ClubName, catching everyone off guard!",
      category: "Clubnews",
      read: false,
    },
    {
      id: 2,
      content:
        "Board Expectations. The board expects you to retain a respectable position in $DivisionName",
      category: "League News",
      read: false,
    },
    {
      id: 3,
      content:
        "$PlayerName has been transfered to $ClubName for $TransferFee, this is a recordtransfer for the club",
      category: "Transfers",
      read: false,
    },
    {
      id: 4,
      content:
        "Player Injury. $PlayerName has been injured and will be out for $InjuryLength",
      category: "Injuries",
      read: false,
    },
    {
      id: 5,
      content:
        "$Playername ($Player_Age) has announced his retirement at the end of the season",
      category: "Clubnews",
      read: false,
    },
    {
      id: 6,
      content:
        "Fixtures have been released. Check the fixtures for the upcoming season",
      category: "League News",
      read: false,
    },
    // This must be replaced by a system that uses events to generate messages
  ]);

  // Function to mark a message as read
  const handleMarkAsRead = (id) => {
    setMessages(
      messages.map((message) =>
        message.id === id ? { ...message, read: true } : message
      )
    );
  };

  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleDelete = (id) => {
    setMessages(messages.filter((message) => message.id !== id));
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const manager = useSelector((state) => state.manager);

  const categories = [
    "All",
    "Transfers",
    "Injuries",
    "Clubnews",
    "League News",
  ];

  return (
    <div
      className="game-main"
      style={{ backgroundImage: `url(${InboxBackgroundImage})` }}
    >
      <ClubScreenHeader
        title={`${manager.firstName} ${manager.lastName} Messages`}
      />

      <div className="game-status">
        These messages are seeking your attention:
      </div>

      <table className="bg-black/50 table-auto w-full text-left whitespace-no-wrap">
        <thead>
          <tr>
            <th className="px-4 py-3 title-font tracking-wider text-bold font-medium text-white text-sm bg-gray-800">
              <select
                onChange={handleCategoryChange}
                name="category"
                id="category"
                className="px-4 py-3 title-font tracking-wider text-bold text-white text-sm bg-gray-800 font-bold"
              >
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </th>
            <th className="px-4 py-3 title-font tracking-wider text-bold text-white text-sm bg-gray-800 font-bold">
              Category
            </th>
            <th className="px-4 py-3 title-font tracking-wider text-bold text-white text-sm bg-gray-800 font-bold">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {messages
            .filter(
              (message) =>
                selectedCategory === "All" ||
                message.category === selectedCategory
            )
            .map((message) => (
              <tr
                key={message.id}
                className="border-b border-gray-200 bg-gray-800 opacity-50 hover:opacity-75"
              >
                <td className="text-white font-bold px-4 py-3">
                  {message.content}
                </td>
                <td className="text-white font-bold px-4 py-3">
                  {message.category}
                </td>
                <td className="text-white font-bold px-4 py-3 text-center">
                  <button
                    onClick={() => handleDelete(message.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                  {!message.read && (
                    <button
                      onClick={() => handleMarkAsRead(message.id)}
                      className="ml-4 text-blue-500 hover:text-blue-700"
                    >
                      Mark as read
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
     
      <div className="game-status mb-2 bg-black/50 text-center text-lg font-bold text-yellow-400 py-2">
        Devlog: These message must be mass deleted for each season as new season
        starts
      </div>
    </div>
  );
};

export default ManagerInbox;