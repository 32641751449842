import React, { useState } from 'react';

const PieChart = ({ data }) => {
    const total = data.reduce((acc, item) => acc + item.value, 0);
    let cumulative = 0;
    const [activeIndex, setActiveIndex] = useState(null);

    return (
        <div className="grid grid-cols-2 gap-2">
            <div>
                
                <ul>
                    <button className="system-btn mb-1 text-white font-semibold">I am a Legend, and so are you</button>
                    {data.map((item, index) => (
                        <li 
                            key={index} 
                            className={`mb-1 flex items-center bg-gray-500 text-white p-2 rounded opacity-80 ${activeIndex === index ? 'bg-gray-700 opacity-80' : ''}`}
                            onMouseEnter={() => setActiveIndex(index)}
                            onMouseLeave={() => setActiveIndex(null)}
                        >
                            <div style={{ backgroundColor: item.color, width: '20px', height: '20px' }}></div>
                            <span className="ml-2">{item.label}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <svg width="100%" height="100%" viewBox="-1 -1 2 2" style={{ transform: 'rotate(-0.25turn)' }}>
                    {data.map((item, index) => {

                        const [startX, startY] = getCoordinatesForPercent(cumulative);
                        cumulative += item.value / total;
                        const [endX, endY] = getCoordinatesForPercent(cumulative);
                        const largeArcFlag = item.value / total > 0.5 ? 1 : 0;

                        const pathData = [
                            `M ${startX} ${startY}`, // Move
                            `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
                            `L 0 0`, // Line
                        ].join(' ');
                        return <path d={pathData} fill={item.color} stroke="white" strokeWidth="0.02" opacity={activeIndex === index ? '1' : '0.2'} key={index} />;
                        })}
                </svg>
            </div>
        </div>
    );
};

function getCoordinatesForPercent(percent) {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);
    return [x, y];
}

const ChartContainer = () => {
    const [view, setView] = useState('combined');

    const incomeData = [
        { label: 'Equity', value: 600, color: 'green' },
        { label: 'Stadium Ground Equity', value: 200, color: 'green' },
        { label: 'Sponsors', value: 300, color: 'green' },
        { label: 'Transfers', value: 400, color: 'green' },
        { label: 'TV Rights', value: 500, color: 'green' },
        { label: 'Season Tickets', value: 300, color: 'green' },
        { label: 'Ticket revenue', value: 700, color: 'green' },
    ];

    const expenditureData = [
        { label: 'Player Wages', value: 350, color: 'red' },
        { label: 'Staff Wages', value: 150, color: 'red' },
        { label: 'Maintenance', value: 100, color: 'red' },
        { label: 'Transfers', value: 400, color: 'red' },
        { label: 'Taxes', value: 200, color: 'red' },
        { label: 'Other Expenses', value: 300, color: 'red' },
    ];

    const combinedData = [...incomeData, ...expenditureData];
    const nextView = view === 'income' ? 'expenditure' : view === 'expenditure' ? 'combined' : 'income';
    const buttonText = nextView === 'income' ? 'Show Incomes' : nextView === 'expenditure' ? 'Show Expenditures' : 'Switch to Combined View';


    return (
        <div className="w-full p-1">
            <button className="menu-btn" onClick={() => setView(nextView)}>
                {buttonText}
            </button>

            {view === 'income' && <PieChart data={incomeData} />}
            {view === 'expenditure' && <PieChart data={expenditureData} />}
            {view === 'combined' && <PieChart data={combinedData} />}
        </div>
    );
};

export default ChartContainer;