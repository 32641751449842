import fieldMap from "../assets/images/field-map-v.jpg";

const OpponentTeamTactics = ({ club }) => {
  return (
    <>
      <div className="clubs-title w-full h-14 flex shadow mb-2">
        <div className="full w-full bg-red-500 p-3 rounded">
          <div className="club-name text-lg text-white font-bold text-center">Opponent.Club name here plz</div>
        </div>
      </div>
      <div className="game-menu mb-2 flex justify-between">
        <div className="flex">
          <button className="menu-btn text-sm w-40 text-center h-full mr-1 bg-gray-500 text-white p-1 rounded shadow">Team Instructions</button>
        </div>
      </div>
      <div className="overflow-y-auto grid grid-cols-2 gap-2 mb-1">
        <div className="text-center text-yellow-400 font-semibold text-lg bg-black/30">5-3-2</div>
        <div className="flex">
          <button className="menu-btn text-sm w-full text-center h-full mr-1 bg-blue-700 text-white p-1 rounded shadow">Overview</button>
          <button className="menu-btn text-sm w-full text-center h-full mr-1 bg-blue-700 text-white p-1 rounded shadow"></button>
          <button className="menu-btn text-sm w-full text-center h-full bg-blue-700 text-white p-1 rounded shadow"></button>
        </div>
      </div>
      <div className="board-main mb-2 rounded bg-black/30 p-2">
        <div className="overflow-y-auto grid grid-cols-2 gap-2" style={{ height: 480 }}>
          <div className="h-full">
            <table className="w-full">
              <tbody>
                {Array(10)
                  .fill(1)
                  .map((v, i) => (
                    <tr key={i} className="border-b border-gray-500">
                      <td className="w-16 text-yellow-400 px-2 text-center">
                        <button className="border px-4 rounded-sm shadow-sm">1</button>
                      </td>
                      <td className="text-white">Gavin Kelly</td>
                      <td className="text-orange-300 text-center">capt</td>
                      <td className="text-blue-300 text-center w-6">5</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="h-full px-2 mb-2 flex justify-center items-center">
            <div className="relative" style={{ width: 320, height: 470 }}>
              <div className="w-full h-full object-cover flex items-center justify-center">
                <img className="w-full h-full" src={fieldMap} alt="" />
              </div>
              <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-menu mb-2 grid grid-cols-3 gap-1">
        <button className="menu-btn col-span-2 w-full h-full flex justify-center items-center bg-gray-400 text-white p-1 rounded shadow">Back</button>
        <button className="menu-btn col-span-1 w-full h-full flex justify-center items-center bg-gray-400 text-white p-1 rounded shadow">Next</button>
      </div>
    </>
  );
};

export default OpponentTeamTactics;
