import React from 'react';
import { Box, Grid, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SettingsMenu = ({ labelText, buttonText, selectOptions, selectedValue, onSelectChange }) => {
  return (
    <Box className="menu-item-py" sx={{ width: '90%', margin: 'auto' }}>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={8} display="flex" alignItems="center">
          <Button variant="outlined" sx={{ width: '100%', height: '56px', backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'darkred' } }}>
            {buttonText}
          </Button>
        </Grid>
        <Grid item xs={4} display="flex" alignItems="center">
          <Box sx={{ width: '100%' }}>
            <FormControl fullWidth variant="outlined" sx={{ height: '56px', '.MuiOutlinedInput-root': { height: '100%' } }}>
            <InputLabel id="custom-select-label" sx={{ color: 'white' }}>{labelText}</InputLabel>              
            <Select
                labelId="custom-select-label"
                id="custom-select"
                value={selectedValue}
                label="Currency"
                onChange={onSelectChange}
                sx={{
                  height: '100%',
                  '& .MuiSelect-select': {
                    backgroundColor: 'red',
                    color: 'white',
                    height: '100%',
                  },
                }}
              >
                {selectOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingsMenu;