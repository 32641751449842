import moment from "moment";
import ProgressBar from "../helpers/ProgressBar";

const TeamPlayerPositionSelection = ({ setPlayerId, playerContracts, isYouth }) => {
  return (
    <>
      <div className="rounded-lg bg-black/50 p-2 w-full flex justify-center">
        <div className="w-full h-full overflow-y-auto">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-2">
            <div className="menu-btn w-full flex items-center">
              <div className="w-1/6 text-center font-bold text-yellow-300 ml-4">Plays</div>
              <div className="w-3/6 text-center font-bold text-white">Player Name</div>
              <div className="w-1/6 text-center font-bold text-white ml-4">Form</div>
              <div className="w-1/6 text-center font-bold text-white ml-4">Age</div>
              <div className="w-2/6 text-center font-bold text-green-600 ml-4">Value</div>
            </div>
            <div className="menu-btn w-full items-center hidden md:flex">
              <div className="w-2/6 text-center font-bold text-white">Pos.</div>
              <div className="w-1/6 text-center font-bold text-yellow-300 ml-4">Plays</div>
              <div className="w-3/6 text-center font-bold text-white">Player Name</div>
              <div className="w-1/6 text-center font-bold text-white ml-4">Form</div>
              <div className="w-1/6 text-center font-bold text-white ml-4">Age</div>
              <div className="w-2/6 text-center font-bold text-green-600 ml-4">Value</div>
            </div>
            {/* This line is mapping over the 'Players' array of the 'club' object (if they exist), and for each contract 'ct' and its index 'i', it's executing a function (not shown here). This function will be called for each senior contract of the club. */}
            {playerContracts.map((ct, i) => (
              <div
                key={i}
                id={ct.id}
                className="w-full flex border-b border-gray-500 items-center hover:bg-gray-600 hover:opacity-75 transition-colors duration-200 cursor-pointer"
              >
                <div onClick={() => setPlayerId(ct._player.id)} className="w-1/6 text-center text-yellow-300 ml-4">
                  {ct._player?.playingPosition}
                </div>
                <div className="w-3/6 text-left text-white ml-4" onClick={() => setPlayerId(ct._player.id)}>
                  {ct._player?.name}
                </div>
                <div onClick={() => setPlayerId(ct._player.id)} className="w-1/6 text-center text-white ml-4">
                  <ProgressBar form={ct._layer?._personal?.form} />
                </div>
                <div onClick={() => setPlayerId(ct._player.id)} className="w-1/6 text-center text-white ml-4">
                  {moment().diff(moment(ct._player?.birthDate), "years")}
                </div>
                <div onClick={() => setPlayerId(ct._player.id)} className="w-2/6 text-center text-green-600 ml-4">
                  $ {ct.transferValue?.toLocaleString()}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamPlayerPositionSelection;
