import { Model } from "@nozbe/watermelondb";
import { field, text, children, relation, date } from "@nozbe/watermelondb/decorators";

class PlayerSkill extends Model {
  static table = "player_skills";

  static associations = {
    players: { type: "belongs_to", key: "player_id" },
  };

  @relation("players", "player_id") player;

  @field("acceleration") acceleration;
  @field("creativity") creativity;
  @field("crossing") crossing;
  @field("dribbling") dribbling;
  @field("finishing") finishing;
  @field("headers") headers;
  @field("dandling") dandling;
  @field("long_shots") longShots;
  @field("marking") marking;
  @field("pace") pace;
  @field("passing") passing;
  @field("positioning") positioning;
  @field("reactions") reactions;
  @field("reflexes") reflexes;
  @field("set_pieces") setPieces;
  @field("shooting") shooting;
  @field("stamina") stamina;
  @field("strength") strength;
  @field("tackling") tackling;
  @field("teamworking") teamworking;
  @field("technique") technique;
  @field("workrate") workrate;
}

export default PlayerSkill;
