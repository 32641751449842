import { createSlice } from "@reduxjs/toolkit";

const defaultTacticalFormation = "532";
const defaultTacticalPositionFormations = {
  532: [
    { id: 1, name: "GK", playerId: null },
    { id: 2, name: "DL", playerId: null },
    { id: 3, name: "DC", playerId: null },
    { id: 4, name: "DC", playerId: null },
    { id: 5, name: "DC", playerId: null },
    { id: 6, name: "DR", playerId: null },
    { id: 7, name: "ML", playerId: null },
    { id: 8, name: "MC", playerId: null },
    { id: 9, name: "MR", playerId: null },
    { id: 10, name: "FC", playerId: null },
    { id: 11, name: "FC", playerId: null },

    // extra
    { id: 12, name: "SB1", playerId: null },
    { id: 13, name: "SB2", playerId: null },
    { id: 14, name: "SB3", playerId: null },
    { id: 15, name: "SB4", playerId: null },
    { id: 16, name: "SB5", playerId: null },
    { id: 17, name: "SB6", playerId: null },
    { id: 18, name: "SB7", playerId: null },
    { id: 19, name: "SB8", playerId: null },
    { id: 20, name: "SB9", playerId: null },
  ],
  442: [
    { id: 1, name: "GK", playerId: null },
    { id: 2, name: "DL", playerId: null },
    { id: 3, name: "DC", playerId: null },
    { id: 4, name: "DC", playerId: null },
    { id: 5, name: "DR", playerId: null },
    { id: 6, name: "ML", playerId: null },
    { id: 7, name: "MC", playerId: null },
    { id: 8, name: "MC", playerId: null },
    { id: 9, name: "MR", playerId: null },
    { id: 10, name: "FC", playerId: null },
    { id: 11, name: "FC", playerId: null },

    // extra
    { id: 12, name: "SB1", playerId: null },
    { id: 13, name: "SB2", playerId: null },
    { id: 14, name: "SB3", playerId: null },
    { id: 15, name: "SB4", playerId: null },
    { id: 16, name: "SB5", playerId: null },
    { id: 17, name: "SB6", playerId: null },
    { id: 18, name: "SB7", playerId: null },
    { id: 19, name: "SB8", playerId: null },
    { id: 20, name: "SB9", playerId: null },
  ],
  433: [
    { id: 1, name: "GK", playerId: null },
    { id: 2, name: "DL", playerId: null },
    { id: 3, name: "DC", playerId: null },
    { id: 4, name: "DC", playerId: null },
    { id: 5, name: "DR", playerId: null },
    { id: 6, name: "ML", playerId: null },
    { id: 7, name: "MC", playerId: null },
    { id: 8, name: "MR", playerId: null },
    { id: 9, name: "FC", playerId: null },
    { id: 10, name: "FC", playerId: null },
    { id: 11, name: "FC", playerId: null },

    // extra
    { id: 12, name: "SB1", playerId: null },
    { id: 13, name: "SB2", playerId: null },
    { id: 14, name: "SB3", playerId: null },
    { id: 15, name: "SB4", playerId: null },
    { id: 16, name: "SB5", playerId: null },
    { id: 17, name: "SB6", playerId: null },
    { id: 18, name: "SB7", playerId: null },
    { id: 19, name: "SB8", playerId: null },
    { id: 20, name: "SB9", playerId: null },
  ],
};

export const clubSlice = createSlice({
  name: "club",
  initialState: {
    id: null,
    details: null,
    squadFormation: defaultTacticalFormation,
    youthFormation: defaultTacticalFormation,
    squadTacticalPositions: defaultTacticalPositionFormations,
    youthTacticalPositions: defaultTacticalPositionFormations,
  },
  reducers: {
    setSelectedClubId: (state, action) => {
      state.id = action.payload;
    },
    setSquadFormation: (state, action) => {
      state.squadFormation = action.payload;
    },
    setYouthFormation: (state, action) => {
      state.youthFormation = action.payload;
    },
    setSquadTacticalPositions: (state, action) => {
      state.squadTacticalPositions[state.squadFormation] = action.payload;
    },
    setYouthTacticalPositions: (state, action) => {
      state.youthTacticalPositions[state.youthFormation] = action.payload;
    },
    resetTacticalPositions: (state, action) => {
      state.squadTacticalPositions = defaultTacticalPositionFormations;
      state.youthTacticalPositions = defaultTacticalPositionFormations;
    },
  },
  selectors: {
    selectSquadTacticalPositions: (state) => state.squadTacticalPositions[state.squadFormation],
    selectYouthTacticalPositions: (state) => state.youthTacticalPositions[state.youthFormation],
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedClubId, setSquadFormation, setYouthFormation, setSquadTacticalPositions, setYouthTacticalPositions, resetTacticalPositions } = clubSlice.actions;
export const { selectSquadTacticalPositions, selectYouthTacticalPositions } = clubSlice.selectors;

export default clubSlice.reducer;
