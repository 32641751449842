import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlayerPositions } from "../helpers/ClubPlayerTeamPositions"; // Added start for reusable tactic team player position functions
import { useDispatch, useSelector } from "react-redux";
import PlayerMain from "../player/PlayerMain";
import TacticsFormationScreen from "../helpers/TacticsFormationScreenHelper";
import TeamPlayerPositionSelection from "../helpers/TeamPlayerPositionSelection";
import { selectYouthTacticalPositions, setYouthFormation, setYouthTacticalPositions } from "../../store/reducers/clubReducer";
import { clubsController } from "../../database/controllers";

const ClubYouthPlayers = () => {
  const [playerId, setPlayerId] = useState(null);
  const clubId = useSelector((state) => state.club.id);
  const [club, setClub] = useState(null);
  const tacticalFormation = useSelector((state) => state.club.youthFormation);
  const tacticalPositions = useSelector(selectYouthTacticalPositions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clubId) {
      clubsController
        .findOne(clubId)
        .then((club) => {
          setClub(club);
        })
        .catch(console.error);
    }
  }, [clubId]);

  const handleCloseModal = () => {
    setPlayerId(null);
  };

  const setTacticalFormation = (formation) => {
    dispatch(setYouthFormation(formation));
  };

  const setTacticalPositions = (positions) => {
    dispatch(setYouthTacticalPositions(positions));
  };

  if (!club) {
    return <div className="game-main">Loading...</div>;
  }

  return (
    <>
      <div className="game-status">
        The {club.name} youth squad is counting {club._youthContracts?.length} youth players.
        {club._youthContracts?.length < 18 && (
          <>
            That's too few players!, imagine if players get injured? Consider{" "}
            <Link to="/scouting" className="ml-2 text-blue-500 underline">
              buying or loaning youth players on the transfer market!
            </Link>
          </>
        )}
      </div>

      <PlayerPositions tacticalPositions={tacticalPositions} />

      <TeamPlayerPositionSelection
        playerContracts={club?._youthContracts}
        tacticalPositions={tacticalPositions}
        setPlayerId={setPlayerId}
        setTacticalPositions={setTacticalPositions}
      />

      {/* Load the tactic screen component */}
      <div className="game-status mt-3">Tactical Youth Squat Formation</div>

      <TacticsFormationScreen
        playerContracts={club?._youthContracts}
        tacticalPositions={tacticalPositions}
        setTacticalPositions={setTacticalPositions}
        tacticalFormation={tacticalFormation}
        setTacticalFormation={setTacticalFormation}
      />

      {playerId && <PlayerMain playerId={playerId} closeModal={handleCloseModal} />}
    </>
  );
};

export default ClubYouthPlayers;
