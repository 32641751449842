import { useState } from "react";

const LeagueTable = () => {
   return (
      <div className="board-main mb-2 rounded bg-black/30 p-2">
         <div className="h-96 overflow-y-auto">
            <table className="w-full">
            <tbody>
               <tr className="border-b border-gray-500">
                  <th className="w-20 text-yellow-400 px-2 text-center"></th>
                  <th className="text-white px-2"></th>
                  <th className="text-white px-2 w-16">Pid</th>
                  <th className="text-white px-2 w-16">Won</th>
                  <th className="text-white px-2 w-16">Dm</th>
                  <th className="text-white px-2 w-16">Lst</th>
                  <th className="text-white px-2 w-16">For</th>
                  <th className="text-white px-2 w-16">Ag</th>
                  <th className="text-white px-2 w-16">Pts</th>
               </tr>
               {Array(10)
                  .fill(1)
                  .map((v) => (
                     <tr className="border-b border-gray-500">
                        <td className="w-16 text-yellow-400 px-2 text-center">
                           <button className="border px-4 rounded-sm shadow-sm">1</button>
                        </td>
                        <td className="text-white">Gavin Kelly</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">0</td>
                        <td className="text-white text-center">
                           <button className="border px-4 rounded-sm shadow-sm">31</button>
                        </td>
                     </tr>
                  ))}
            </tbody>
            </table>
         </div>
      </div>
   );
};

export default LeagueTable;
