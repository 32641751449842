import { useEffect, useState } from "react";
import StaffHeader from "./StaffHeader";
import StaffProfile from "./StaffProfile";
import StaffContract from "./StaffContract";
import StaffHistory from "./StaffHistory";
import { staffsController } from "../../database/controllers";

const Modal = ({ children }) => {
  return (
    <div className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-black/50" id="exampleModalXl">
      <div className="pointer-events-none relative w-auto translate-y-[-50px] transition-all duration-300 ease-in-out mt-20 min-[576px]:mx-auto min-[576px]:mt-20 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]">
        <div className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-4 outline-none dark:bg-surface-dark">
          <div className="relative p-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

const StaffMain = ({ staffId, closeModal }) => {
  const [currentTab, setCurrentTab] = useState("profile");
  const [staff, setStaff] = useState(null);

  useEffect(() => {
    staffsController
      .findOne(staffId)
      .then((staff) => {
        console.log(staff);
        setStaff(staff);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [staffId]);

  if (!staff) {
    return (
      <Modal>
        <div className="w-full bg-gray-100">
          <div className="py-3 text-center text-xl">Loading...</div>
          <div className="bottom-menu mt-3 grid grid-cols-1 gap-1">
            <button onClick={closeModal} className="system-btn">
              Close
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  const getCurrentTab = () => {
    switch (currentTab) {
      case "profile":
        return <StaffProfile staff={staff} />;
      case "contract":
        return <StaffContract staff={staff} />;
      case "history":
        return <StaffHistory staff={staff} />;
      default:
        return <StaffProfile staff={staff} />;
    }
  };

  return (
    <Modal>
      <div className="w-full bg-gray-100">
        <StaffHeader staff={staff} currentTab={currentTab} setCurrentTab={setCurrentTab} />

        <div>{getCurrentTab()}</div>

        <div className="bottom-menu mt-3 grid grid-cols-1 gap-1">
          <button onClick={closeModal} className="system-btn">
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StaffMain;
