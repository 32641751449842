import { useState } from "react";

const MatchReport = () => {
   return (
      <div className="board-main mb-2 rounded bg-black/30 p-2">
         <div className="h-96 overflow-y-auto">
            <table className="w-full">
               {Array(100)
                  .fill(1)
                  .map((v) => (
                     <tr className="border-b border-gray-500">
                        <td className="w-32 text-yellow-400 px-2 text-center">1 min</td>
                        <td className="text-white px-2">We are going to copy the message events from the Match Overview here!</td>
                     </tr>
                  ))}
            </table>
         </div>
      </div>
   );
};

export default MatchReport;
