import ScoutingBackgroundImage from "../assets/images/ScoutingBackgroundImage.jpg";
import { Link } from "react-router-dom";
import ClubScreenHeader from "../helpers/ClubScreenHeader";
import { useEffect, useState } from "react";
import { clubsController, playersController } from "../../database/controllers";
import moment from "moment";
import Pagination from "../helpers/Pagination";

const TransferMarket = (props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [clubs, setClubs] = useState([]);
  const [players, setPlayers] = useState([]);
  const [totalPlayers, setTotalPlayers] = useState([]);

  useEffect(() => {
    clubsController.index().then((clubs) => {
      setClubs(clubs);
    });
    playersController.index(page, perPage).then(({ players, totalPlayers }) => {
      setPlayers(players), setTotalPlayers(totalPlayers);
    });
  }, [page, perPage]);

  return (
    <div
      className="game-main"
      style={{
        backgroundImage: `url(${ScoutingBackgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <ClubScreenHeader title="Football Club Builder" />
      {/* <div className="clubs-title w-full h-14 flex shadow mb-2">
        <div className="club-1 h-full w-full bg-red-600 p-3 rounded">
          <div className="club-name text-2xl text-white font-semibold text-center">
            Football Club Builder
          </div>
        </div>
      </div> */}

      <div className="game-status bg-black/80">Transfermarkt</div>

      <div className="game-status mb-2 rounded bg-black/80 p-2">
        @Mohsin Goal is to show all clubs and players here and be able to search for players by name, and on an attribute set filter.
        <Link to="https://github.com/InteractiveWebdesign/football-new/issues/7" target="_blank" rel="noopener noreferrer">
          More info here
        </Link>
      </div>

      <div className="board-main mb-2 rounded bg-black/60 p-2">
        <div className="overflow-y-auto grid grid-cols-2 gap-2 mb-1">
          <button className="system-btn">Filters</button>
          <select className="system-btn" name="contractStatusSelector" id="contractStatusSelector">
            <option>Contract Status:</option>
            <option>Free Player</option>
            <option>Expiring</option>
            <option>Transfer Listed</option>
            <option>Under Contract</option>
          </select>
        </div>

        <table className="w-full">
          <thead>
            <tr>
              <th className="col-span-1 text-yellow-400 px-2 text-left font-extrabold">Name</th>
              <th className="col-span-1 text-yellow-400 px-2 text-left font-extrabold">Age</th>
              <th className="col-span-1 text-yellow-400 px-2 text-left font-extrabold">Nationality</th>
              <th className="col-span-1 text-yellow-400 px-2 text-left font-extrabold">Transfer Value</th>
              <th className="col-span-1 text-yellow-400 px-2 text-left font-extrabold">Contract Length</th>
              <th className="col-span-1 text-yellow-400 px-2 text-left font-extrabold">Form</th>
              <th className="col-span-1 text-yellow-400 px-2 text-left font-extrabold">Club</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player, index) => (
              <tr key={index}>
                <td className="col-span-1 px-2 text-white">{player.name}</td>
                <td className="col-span-1 px-2 text-white">{moment().diff(moment(player.birthDate), "years")}</td>
                <td className="col-span-1 px-2 text-white">{player.nationality}</td>
                <td className="col-span-1 px-2 text-white">{player._contract ? player._contract.transferValue : ""}</td>
                <td className="col-span-1 px-2 text-white">{player._contract ? player._contract.contractLength : ""}</td>
                <td className="col-span-1 px-2 text-white">{player._personal ? player._personal.form : ""}</td>
                <td className="col-span-1 px-2 text-white">{player._contract ? player._contract._club?.name : ""}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="mt-5 flex justify-end">
          <Pagination currentPage={page} totalPages={Math.round(totalPlayers / perPage)} onPageChange={(pg) => setPage(pg)} />
        </div>
      </div>

      <div className="bottom-menu mb-2 grid grid-cols-1 gap-1">
        <button onClick={props.onGoBack} className="menu-btn w-full h-full text-center bg-gray-400 text-white p-1 rounded shadow">
          Back
        </button>
        {/* <button className="menu-btn w-full h-full text-center bg-gray-400 text-white p-1 rounded shadow">Next</button> */}
      </div>
    </div>
  );
};

export default TransferMarket;
