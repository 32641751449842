import { Link } from "react-router-dom";

const Injuries = ({ player }) => {
  // console.log(player);
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-2 py-2 game-status mb-2 bg-black/75 text-center text-xl font-bold text-white">
        Player's likelihood to be injured can be reduced, and injuries can bestly threated by
        <Link to="/club/manage" className="ml-2 text-blue-500 underline">
          investing in your hiring extra physio staff or upgrade the staffbuilding.
        </Link>
      </div>
      <dl>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-50 border-b border-gray-500 flex justify-between">
          <dt className="text-black font-bold capitalize">Player Condition</dt>
          <dd className="mt-1 text-m font-bold text-gray-900 sm:mt-0 sm:col-span-2">{player.condition}%</dd>
        </div>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-50 border-b border-gray-500 flex justify-between">
          <dt className="text-black font-bold capitalize">Player Injuries</dt>
          <dd className={`mt-1 text-m font-bold text-gray-900 sm:mt-0 sm:col-span-2 ${player.injured ? "text-red-500" : "text-green-500"}`}>
            This player is {player.injured ? "injured" : "not injured"}.
          </dd>
        </div>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-50 border-b border-gray-500 flex justify-between">
          <dt className="text-black font-bold capitalize">Player prone to injuries</dt>
          <dd className="mt-1 text-m font-bold text-gray-900 sm:mt-0 sm:col-span-2">{player._personal?.proneToInjuries}</dd>
        </div>
      </dl>
    </div>
  );
};

export default Injuries;
