// model/schema.js
import { appSchema, tableSchema } from "@nozbe/watermelondb";

const schema = appSchema({
  version: 1,
  tables: [
    tableSchema({
      name: "managers",
      columns: [
        { name: "first_name", type: "string" },
        { name: "last_name", type: "string" },
        { name: "password", type: "string" },
      ],
    }),
    tableSchema({
      name: "stadiums",
      columns: [
        { name: "name", type: "string" },
        { name: "desc", type: "string", isOptional: true },
        { name: "address", type: "string" },
        { name: "capacity", type: "number" },
        { name: "capacity_seated", type: "number" },
        { name: "capacity_roofed", type: "number" },
        { name: "capacity_heated", type: "number" },
        { name: "monthly_cost", type: "number" },
      ],
    }),
    tableSchema({
      name: "clubs",
      columns: [
        { name: "stadium_id", type: "string", isIndexed: true },
        { name: "name", type: "string" },
        { name: "founded", type: "number" },
        { name: "fanbase", type: "number" },
        { name: "address", type: "string" },
        { name: "reputation", type: "string" },
        { name: "youth_facilities", type: "string" },
        { name: "training_facilities", type: "string" },
        { name: "transfer_budget", type: "number" },
        { name: "wage_budget", type: "number" },
      ],
    }),
    tableSchema({
      name: "club_finances",
      columns: [
        { name: "club_id", type: "string", isIndexed: true },
        { name: "i_tv_money", type: "number" },
        { name: "i_sponsors", type: "number" },
        { name: "i_transfers", type: "number" },
        { name: "i_price_money", type: "number" },
        { name: "i_ticket_sales", type: "number" },
        { name: "i_merchandise", type: "number" },
        { name: "i_other", type: "number" },
        { name: "e_player_salaries", type: "number" },
        { name: "e_staff_salaries", type: "number" },
        { name: "e_transfer_fees", type: "number" },
        { name: "e_tadium_maintenance", type: "number" },
        { name: "e_youth_development", type: "number" },
        { name: "e_scouting", type: "number" },
        { name: "e_other", type: "number" },
      ],
    }),
    tableSchema({
      name: "players",
      columns: [
        { name: "name", type: "string" },
        { name: "birth_date", type: "number" },
        { name: "nationality", type: "string" },
        { name: "playing_position", type: "string" },
        { name: "is_youth", type: "boolean" },
        { name: "morale", type: "string" },
        { name: "condition", type: "number" },
        { name: "injured", type: "boolean" },
        { name: "squad_status", type: "string" },
      ],
    }),
    tableSchema({
      name: "player_skills",
      columns: [
        { name: "player_id", type: "string", isIndexed: true },
        { name: "acceleration", type: "number" },
        { name: "creativity", type: "number" },
        { name: "crossing", type: "number" },
        { name: "dribbling", type: "number" },
        { name: "finishing", type: "number" },
        { name: "headers", type: "number" },
        { name: "dandling", type: "number" },
        { name: "long_shots", type: "number" },
        { name: "marking", type: "number" },
        { name: "pace", type: "number" },
        { name: "passing", type: "number" },
        { name: "positioning", type: "number" },
        { name: "reactions", type: "number" },
        { name: "reflexes", type: "number" },
        { name: "set_pieces", type: "number" },
        { name: "shooting", type: "number" },
        { name: "stamina", type: "number" },
        { name: "strength", type: "number" },
        { name: "tackling", type: "number" },
        { name: "teamworking", type: "number" },
        { name: "technique", type: "number" },
        { name: "workrate", type: "number" },
      ],
    }),
    tableSchema({
      name: "player_personals",
      columns: [
        { name: "player_id", type: "string", isIndexed: true },
        { name: "form", type: "number" },
        { name: "professionalism", type: "string" },
        { name: "sportsmanship", type: "string" },
        { name: "temperament", type: "string" },
        { name: "experience", type: "number" },
        { name: "leadership", type: "number" },
        { name: "morale", type: "string" },
        { name: "prefered_foot", type: "string" },
        { name: "prone_to_injuries", type: "string" },
      ],
    }),
    tableSchema({
      name: "player_contracts",
      columns: [
        { name: "club_id", type: "string", isIndexed: true },
        { name: "player_id", type: "string", isIndexed: true },
        { name: "contract_bonus", type: "string" },
        { name: "contract_length", type: "string" },
        { name: "contract_expires", type: "number" },
        { name: "transfer_value", type: "number" },
        { name: "happiness", type: "string" },
        { name: "is_youth", type: "boolean" },
        { name: "is_captain", type: "boolean" },
        { name: "on_transfer_list", type: "boolean" },
        { name: "on_loan_list", type: "boolean" },
        { name: "monthly_wage", type: "number" },
      ],
    }),
    tableSchema({
      name: "staffs",
      columns: [
        { name: "name", type: "string" },
        { name: "role", type: "string" },
        { name: "birth_date", type: "number" },
        { name: "nationality", type: "string" },
        { name: "experience", type: "number" },
        { name: "physiotherapy", type: "number" },
        { name: "rehabilitation_therapy", type: "number" },
        { name: "communication", type: "number" },
        { name: "judging_ability", type: "number" },
        { name: "vision_on_potential", type: "number" },
        { name: "working_with_youth", type: "number" },
        { name: "tactical_knowledge", type: "number" },
        { name: "motivator_abilities", type: "number" },
        { name: "negotiation", type: "number" },
        { name: "networking", type: "number" },
        { name: "development_abilities", type: "number" },
        { name: "coaching_goalkeepers", type: "number" },
        { name: "coaching_field_players", type: "number" },
      ],
    }),
    tableSchema({
      name: "staff_contracts",
      columns: [
        { name: "club_id", type: "string", isIndexed: true },
        { name: "staff_id", type: "string", isIndexed: true },
        { name: "monthly_wage", type: "number" },
        { name: "contract_length", type: "string" },
        { name: "contract_expires", type: "number" },
        { name: "value", type: "number" },
        { name: "happiness", type: "string" },
        { name: "bonuses", type: "number" },
      ],
    }),
    tableSchema({
      name: "competitions",
      columns: [{ name: "name", type: "string" }],
    }),
    tableSchema({
      name: "divisions",
      columns: [
        { name: "competition_id", type: "string", isIndexed: true },
        { name: "division_no", type: "number" },
        { name: "participants_count", type: "number" },
      ],
    }),
    tableSchema({
      name: "participants",
      columns: [
        { name: "club_id", type: "string", isIndexed: true },
        { name: "division_id", type: "string", isIndexed: true },
        { name: "played", type: "number" },
        { name: "wins", type: "number" },
        { name: "draws", type: "number" },
        { name: "loses", type: "number" },
        { name: "points", type: "number" },
        { name: "goals_ag", type: "number" },
        { name: "goals_for", type: "number" },
      ],
    }),
    tableSchema({
      name: "rounds",
      columns: [
        { name: "division_id", type: "string", isIndexed: true },
        { name: "round_no", type: "number" },
        { name: "round_type", type: "string" },
      ],
    }),
    tableSchema({
      name: "matches",
      columns: [
        { name: "round_id", type: "string", isIndexed: true },
        { name: "participant1_id", type: "string" },
        { name: "participant2_id", type: "string" },
        { name: "club1_goals", type: "number" },
        { name: "club2_goals", type: "number" },
        { name: "match_date", type: "number" },
      ],
    }),
  ],
});

export default schema;
