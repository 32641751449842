import { Model } from "@nozbe/watermelondb";
import { field, text, children, date } from "@nozbe/watermelondb/decorators";

class Stadium extends Model {
  static table = "stadiums";
  static associations = {
    clubs: { type: "has_many", foreignKey: "stadium_id" },
  };

  @children("clubs") clubs;

  @text("name") name;
  @text("desc") desc;
  @text("address") address;
  @field("capacity") capacity;
  @field("capacity_seated") capacitySeated;
  @field("capacity_roofed") capacityRoofed;
  @field("capacity_heated") capacityHeated;
  @field("monthly_cost") monthlyCost;
}

export default Stadium;
