import database from "..";
import { faker } from "@faker-js/faker";

const playerSeeder = {
  tableName: "players",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);
      const clubs = await database.get("clubs").query().fetch();

      const maxPlayersPerClub = 26; // adding max players can needed, extra will unassigned for any club.
      const requiredPositions = ["GK", "GK", "DL", "DL", "DC", "DC", "DC", "DR", "DR", "MC", "MC", "ML", "MR", "LW", "RW", "CM/CAM", "FC", "ST", "ST"];
      const allPositions = [
        //"GK",
        //"DL",
        //"DC",
        //"DR",
        "DL/DR",
        "DL/DC",
        "DR/DC",
        //"MC",
        //"ML",
        //"MR",
        "ML/MR",
        //"CAM",
        "CM/CAM",
        "FC",
        "FC/ST",
        "ST",
        //"LW",
        //"RW",
        "LW/RW",
      ];
      const squadStatusOptions = ["Key player", "First team player", "Squad member", "Backup player", "Youth player"];
      const playersCount = clubs.length * maxPlayersPerClub;

      for (let i = 0; i < playersCount; i++) {
        // Generate a random index for squad status
        const randomStatusIndex = Math.floor(Math.random() * squadStatusOptions.length);

        // Use the random index to get a random squad status
        const squadStatus = squadStatusOptions[randomStatusIndex];

        await table.create((tb) => {
          tb.name = faker.person.fullName();
          tb.birthDate = faker.date.between({
            from: new Date(new Date().setFullYear(new Date().getFullYear() - 34)),
            to: new Date(new Date().setFullYear(new Date().getFullYear() - 19)),
          });
          tb.nationality = faker.location.country();
          tb.isYouth = false;
          tb.playingPosition = i < requiredPositions.length ? requiredPositions[i] : faker.helpers.arrayElement(allPositions);
          tb.morale = faker.helpers.arrayElement(["Excellent", "Good", "Decent", "Avarage", "Below avarage", "Bad"]);
          tb.condition = Math.floor(Math.random() * 21) + 80; // between 80 and 100 condition at start
          tb.injured = faker.datatype.boolean();
          tb.squadStatus = squadStatus; // "Squad member" is default now for all seniors maybe add more logic to make based on playerskill total points
        });
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default playerSeeder;
