import { Link, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import ClubScreenHeader from "./ClubScreenHeader";

const ClubHeader = ({ club }) => {
  const location = useLocation();

  const tabs = [
    { name: "Clubinfo", to: "/club" },
    { name: "Finances", to: "/club/finances" },
    { name: "Fixtures", to: "/club/fixtures" },
    { name: "Squad", to: "/club/squad" },
    { name: "Youth Players", to: "/club/youth-players" },
    { name: "Manage Club", to: "/club/manage" },
  ];

  const currentTab = tabs.find(
    (tab) => location.pathname.replace(/\/$/, "") === tab.to
  );

  return (
    <>
      <ClubScreenHeader title={currentTab ? `${currentTab.name} - ${club.name}` : ""} />

      <div className="mb-2 w-full grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-6 gap-2">
        {tabs.map((tab, index) => (
          <Button
            key={index}
            variant="outlined"
            color="primary"
            component={Link}
            to={tab.to}
            className={`w-full ${location.pathname.replace(/\/$/, "") === tab.to ? "active-tab" : ""}`} // Apply active-tab class for the active tab
          >
            {tab.name}
          </Button>
        ))}
      </div>
    </>
  );
};

export default ClubHeader;