import React, { useEffect, useState } from "react";
import _ from "lodash";
import PlayerMain from "../player/PlayerMain";
import ProgressBar from "./ProgressBar";
import TacticsFormationPlayingPositions from "./TacticsFormationPlayingPositions";
import fieldMap from "../assets/images/field-map-v.jpg";

const TacticsFormationScreen = ({ playerContracts, tacticalPositions, setTacticalPositions, tacticalFormation, setTacticalFormation }) => {
  // This must take it the choosen formation position that is work to be done so this must work with the formation that is choosen
  // const tacticalPosition = ["GK", "DL", "DC", "DC", "DC", "DR", "ML", "MC", "MR", "FC", "FC", "SB1", "SB2", "SB3", "SB4", "SB5", "SB6", "SB7", "SB8", "SB9"];
  // So when formation is 442 you should have GK, DL, DC, DC, DR, ML, MC, MC, MR, FC, FC and when formation is 532 you should have GK, DL, DC, DC, DC, DR, ML, MC, MR, FC, FC

  const [tacticalPositionFormations, setTacticalPositionFormations] = useState(TacticsFormationPlayingPositions);

  const handleFormationChange = (event) => {
    setTacticalFormation(event.target.value);
  };

  // Copied these functions from ClubSquad.js to get the tactical position of a player
  const handleDragStart = (event, position) => {
    event.dataTransfer.setData("text/plain", position.id);
  };

  const renderTacticalPosition = (playerId) => {
    let position = getTacticalPosition(playerId);
    if (!position) return null;
    return (
      <div onClick={() => removeTacticalPosition(position)} className={"field-position-btn"} draggable onDragStart={(event) => handleDragStart(event, position)}>
        {position.name}
      </div>
    );
  };

  const getTacticalPosition = (playerId) => {
    const position = tacticalPositions.find((pos) => pos.playerId === playerId);
    return position;
  };

  const removeTacticalPosition = (position) => {
    const positions = _.cloneDeep(tacticalPositions);
    const tPos = positions.find((pos) => pos.id === position.id);
    if (tPos) tPos.playerId = null;
    setTacticalPositions([...positions]);
  };

  // Copied this: closing the player about page modal
  const handleCloseModal = () => {
    setPlayerId(null);
  };

  // Copied this for: opening the player about page modal
  const [playerId, setPlayerId] = useState(null);

  const getPlayer = (playerId) => {
    const pc = playerContracts.find((contract) => contract._player.id === playerId);
    if (pc) return pc._player;
    return null;
  };

  const getSelectedPlayerContracts = () => {
    const pcs = [];
    for (let tp of tacticalPositions) {
      if (tp.playerId) {
        const pc = playerContracts.find((contract) => contract._player?.id === tp.playerId);
        if (pc) {
          pcs.push(pc);
        }
      }
    }
    return pcs;
  };

  useEffect(() => {
    const formations = _.cloneDeep(tacticalPositionFormations);
    const positions = formations[tacticalFormation];
    for (let i = 0; i < positions.length; i++) {
      const tp = tacticalPositions.find((pos) => {
        const assigned = positions.find((ps) => ps.playerId === pos.playerId);
        return !assigned && pos.name === positions[i].name;
      });

      if (tp) {
        positions[i].playerId = tp.playerId;
      }
    }
    formations[tacticalFormation] = positions;
    setTacticalPositionFormations(formations);
  }, [tacticalPositions, tacticalFormation]);

  return (
    <>
      <div className="board-main mb-2 rounded bg-black/50 p-2">
        <div className="overflow-y-auto grid sm:grid-cols-1 md:grid-cols-2 gap-2">
          <div className="h-full">
            <table className="w-full">
              <thead className="system-btn text-white w-full">
                <tr>
                  <th className="px-2 text-center w-16">Position</th>
                  <th className="px-2 text-center w-1/6">Plays</th>
                  <th className="px-2 text-center w-1/6">Form</th>
                  <th className="px-2 text-center w-2/6">Name</th>
                  <th className="px-2 text-center ">Captain</th>
                </tr>
              </thead>
              <tbody>
                {getSelectedPlayerContracts().map((ct, index) => (
                  <tr key={index} className="border-b hover:bg-gray-600 hover:opacity-75 transition-colors duration-200 cursor-pointer">
                    <td className="py-2 px-2 w-16 text-yellow-400 text-center">
                      <button className="w-full rounded shadow-sm">{renderTacticalPosition(ct._player?.id)}</button>
                    </td>
                    <td className="w-1/6 py-2 text-center text-yellow-300 ml-4" onClick={() => setPlayerId(ct._player?.id)}>
                      {ct._player?.playingPosition}
                    </td>
                    <td className="w-1/6 py-2 text-left text-white" onClick={() => setPlayerId(ct._player?.id)}>
                      <div>
                        <ProgressBar form={ct._player?._personal?.form} />
                      </div>
                    </td>
                    <td className="w-2/6 py-2 text-left text-white" onClick={() => setPlayerId(ct._player?.id)}>
                      {ct._player?.name}
                    </td>
                    <td className="py-2 text-center text-orange-300">{index === 0 ? "capt" : ""}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="h-full px-2 mb-2 flex flex-col">
            <div className="w-full">
              <select
                className="system-btn w-full rounded-none focus:outline-none"
                defaultValue={tacticalFormation}
                onChange={handleFormationChange}
                name="formationPicker"
                id="formationPicker"
              >
                <option value="442">4-4-2</option>
                <option value="532">5-3-2</option>
                <option value="433">4-3-3</option>
              </select>
            </div>
            <div className="flex-1 flex justify-center items-center">
              <div className="relative" style={{ width: 320, height: 470 }}>
                <div className="w-full h-full flex items-center justify-center">
                  <img className="object-contain" src={fieldMap} alt="" />
                </div>
                {tacticalPositionFormations[tacticalFormation].map((position, index) => (
                  <div key={index} className="absolute bg-red-500 rounded-full w-12 h-12 flex items-center justify-center" style={position}>
                    <span className="text-xs font-extrabold text-white">{position.name}</span>{" "}
                    <div className="text-white absolute top-8 text-center leading-4">{position.playerId ? getPlayer(position.playerId)?.name : ""}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {playerId && <PlayerMain playerId={playerId} closeModal={handleCloseModal} />}
    </>
  );
};

export default TacticsFormationScreen;
