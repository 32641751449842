import moment from "moment";
import { Link } from "react-router-dom";

const Contract = ({ player }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-2 py-2 game-status mb-2 bg-black/75 text-center text-lg font-bold text-white">
        Player's likelihood to sign or renew contract will depend on morale and on staffbuilding upgrade "negation room"
        <Link to="/club/manage" className="ml-2 text-blue-500 underline">
          visit and upgrade the staffbuilding.
        </Link>
      </div>
      <div className="mb-4 font-bold text-xl">Player Contract details and renew options</div>

      <table className="table-auto w-full text-left whitespace-no-wrap">
        <tbody className="mb-3 mt-3">
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Contract Length</td>
            <td className="px-4 py-2 border-b border-gray-200">{player._contract?.contractlength}</td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Contract Expire Date</td>
            <td className="px-4 py-2 border-b border-gray-200">{player._contract?.contractExpires ? moment(player._contract?.contractExpires).format("ll") : ""}</td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Contract Bonusses:</td>
            <td className="px-4 py-2 border-b border-gray-200">{player._contract?.contractBonus}</td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Transfer Value:</td>
            <td className="px-4 py-2 border-b border-gray-200 text-green-600">$ {player._contract?.transferValue.toLocaleString()}</td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Montly wage</td>
            <td className="px-4 py-2 border-b border-gray-200">$ {player._contract?.monthlyWage.toLocaleString()}</td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Willing to stay:</td>
            <td className="px-4 py-2 border-b border-gray-200">
              <button className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-700">{player._contract?.happiness}</button>
            </td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Offer New Contract</td>
            <td className="px-4 py-2 border-b border-gray-200">
              <button className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-700">Perform</button>
            </td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Terminate Contract</td>
            <td className="px-4 py-2 border-b border-gray-200">
              <button className="px-4 py-2 rounded bg-red-500 text-white hover:bg-red-700">Perform</button>
            </td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Put on Loan List</td>
            <td className="px-4 py-2 border-b border-gray-200">
              <button className="px-4 py-2 rounded bg-yellow-500 text-white hover:bg-yellow-700">Perform</button>
            </td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Put on Transfer List</td>
            <td className="px-4 py-2 border-b border-gray-200">
              <button className="px-4 py-2 rounded bg-green-500 text-white hover:bg-green-700">Perform</button>
            </td>
          </tr>

          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Morale</td>
            <td className="px-4 py-2 border-b border-gray-200">{player._personals?.morale}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Contract;
