import { useDispatch, useSelector } from "react-redux";
import TacticsFormationScreen from "../helpers/TacticsFormationScreenHelper";
import { selectSquadTacticalPositions, setSquadFormation, setSquadTacticalPositions } from "../../store/reducers/clubReducer";

const MyTeamTactics = ({ club }) => {
  const tacticalPositions = useSelector(selectSquadTacticalPositions);
  const tacticalFormation = useSelector((state) => state.club.squadFormation);
  const dispatch = useDispatch();

  const setTacticalFormation = (formation) => {
    dispatch(setSquadFormation(formation));
  };

  const setTacticalPositions = (positions) => {
    dispatch(setSquadTacticalPositions(positions));
  };

  return (
    <>
      <div className="clubs-title w-full h-14 flex shadow mb-2">
        <div className="full w-full bg-red-500 p-3 rounded">
          <div className="club-name text-lg text-white font-bold text-center">Tactics - {club.name}</div>
          {/* <div className="club-name text-lg text-white font-bold text-center">My ClubName here plz!</div> */}
        </div>
      </div>

      <TacticsFormationScreen
        playerContracts={club?._seniorContracts}
        tacticalPositions={tacticalPositions}
        setTacticalPositions={setTacticalPositions}
        tacticalFormation={tacticalFormation}
        setTacticalFormation={setTacticalFormation}
      />
    </>
  );
};

export default MyTeamTactics;
