import moment from "moment";
import createStarRating from "../helpers/starRating";


const StaffProfile = ({ staff }) => {
  const staffRoles = { chairman: "Chairman", asst_manager: "Assistant Manager", coach: "Coach", physio: "Physio", trainer: "Trainer", scout: "Scout" };
  const {
    nationality,
    birthDate,
    experience,
    communication,
    physiotherapy,
    rehabilitationTherapy,
    negotiation,
    networking,
    judgingAbility,
    visionOnPotential,
    workingWithYouth,
    tacticalKnowledge,
    motivatorAbilities,
    developmentAbilities,
    coachingGoalkeepers,
    coachingFieldPlayers,
  } = staff;

  const fields = {
    nationality,
    birthDate,
    experience,
    communication,
    physiotherapy,
    rehabilitationTherapy,
    judgingAbility,
    visionOnPotential,
    workingWithYouth,
    tacticalKnowledge,
    motivatorAbilities,
    negotiation,
    networking,
    developmentAbilities,
    coachingGoalkeepers,
    coachingFieldPlayers,
  };

  return (
    <>
      <div className="w-full bg-gray-100"></div>

      <div className="boxHeader">
          Born {moment(staff.birthDate).format("D.MM.YYYY")} (Age {moment().diff(moment(staff.birthDate), "years")})
      </div>

      <div className="board-main mb-2 rounded bg-gray-300 p-2">
        <div className="grid grid-cols-2 gap-5">
          <div className="border-b border-gray-500 flex justify-between">
            <div className="boxText">Nationality</div>
            <div className="font-semibold">{fields.nationality}</div>
          </div>
          <div className="border-b border-gray-500 flex justify-between">
            <div className="boxText">Birth Date</div>
            <div className="font-semibold">{moment(fields.birthDate).format('YYYY-MM-DD')}</div>
          </div>
        </div>
      </div>
      
      <div className="game-status mb-2 bg-black/50 text-center text-lg font-bold text-yellow-400 py-2">Role: {staffRoles[staff.role]}</div>

      <div className="board-main mb-2 rounded bg-gray-300 p-2">
        <div className="grid grid-cols-2 gap-4">

          {Object.entries(fields).map(([key, value]) => {
            if (value === null || ['nationality', 'birthDate'].includes(key)) return null;
            
            let displayValue = value;
            if (!['nationality', 'birthDate'].includes(key)) {
            // if (['experience', 'communication', 'physiotherapy', 'negotiation', 'judgingAbility', 'visionOnPotential', 'workingWithYouth', 'tacticalKnowledge', 'motivatorAbilities', 'developmentAbilities', 'coachingGoalkeepers', 'coachingFieldPlayers'].includes(key)) {
              const { stars } = createStarRating(value);
              displayValue = (
                <div style={{display: 'flex'}}>
                  {stars.map((star, index) => (
                    <img src={star} alt="Star" key={index} />
                  ))}
                </div>
              );
            }

            return (
              <div key={key} className={`border-b border-gray-500 flex justify-between`}>
                <div className="boxText">{key.split(/(?=[A-Z])/).join(" ")}</div>
                <div className="font-semibold">{displayValue}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default StaffProfile;
