import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedClubId } from "../../store/reducers/clubReducer";
import toast from "react-hot-toast";
import ClubViewMain from "../clubview/ClubMain";
import { 
  Button, 
  Container, 
  Typography, 
  Grid, 
  Paper, 
  Radio, 
  FormControlLabel,
  Box
} from "@mui/material";
import ClubScreenHeader from "../helpers/ClubScreenHeader";
import { useTranslation } from "react-i18next";
import { clubsController } from "../../database/controllers";
import BackNextMenuButtons from '../helpers/BackNextMenuButtons';

const SelectClub = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeClubId = useSelector((state) => state.club.id);
  const [clubId, setClubId] = useState(storeClubId);
  const [clubs, setClubs] = useState([]);
  const [filteredClubs, setFilteredClubs] = useState([]);
  const [viewClubId, setViewClubId] = useState(null);
  const serialize = { 1: "1TH", 2: "2ND", 3: "3RD", 4: "4TH" };
  // const [selectedDivision, setSelectedDivision] = useState(1);
  const [selectedDivision, setSelectedDivision] = useState(0);
  const divisions = {
    0: t("general.alldivision"),
    1: t("general.division1"),
    2: t("general.division2"),
    3: t("general.division3"),
    4: t("general.division4"),
  };

  const getClubs = () => {
    clubsController.index().then((clubs) => {
      console.log(clubs);
      setClubs(clubs);
      setFilteredClubs(clubs);
    });
  };

  const getDivisionNo = (club) => {
    let divisionNo = 0;
    if (club._participant && club._participant._division) {
      divisionNo = club._participant._division.divisionNo;
    }
    return serialize[divisionNo];
  };

  const onClubChanged = (ev) => {
    let id = ev.target.value;
    setClubId(id);
  };

  const goNextPage = (ev) => {
    if (!clubId) {
      toast.error(t("menu.selectAClub"));
      return;
    }
    dispatch(setSelectedClubId(clubId));
    navigate("/club");
  };

  const handleCloseModal = () => {
    setViewClubId(null);
  };

  const filterClubs = (key) => {
    setSelectedDivision(key);
    const _clubs = _.cloneDeep(clubs);
    if (parseInt(key) === 0) {
      setFilteredClubs(_clubs);
    } else {
      const filtered = _clubs.filter(
        (club) => club._participant?._division?.divisionNo === parseInt(key)
      );
      setFilteredClubs(filtered);
    }
  };

  useEffect(() => {
    getClubs();
  }, []);

  return (
    <Container maxWidth={false} disableGutters>
      <Box className="game-main" sx={{ backgroundImage: 'url(your-background-image.jpg)', backgroundSize: 'cover', minHeight: '100vh' }}>
        <ClubScreenHeader title="Football Club Builder" />

        <Typography className="game-status" variant="h6" sx={{ color: 'white', mb: 1 }}>
          {t("menu.selectClubToManage")}
        </Typography>

        <div>
          <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-1 mb-1">
            {Object.entries(divisions).map(([key, division]) => (
              <Button
                key={division}
                onClick={() => filterClubs(key)}
                variant="outlined"
                color="primary"
                className={selectedDivision == key ? "active-tab" : ""}
                // className={`w-full ${selectedDivision === key ? "active-tab" : ""}`} // Apply active-tab class for the active division
              >
                {division}
              </Button>
            ))}
          </div>
        </div>

        <BackNextMenuButtons backLink="/add-manager" nextAction={goNextPage} />

        <Paper sx={{ p: 0.5, bgcolor: 'rgba(0, 0, 0, 0.7)', overflow: 'auto' }}>
        <Grid container spacing={0}>
          {filteredClubs.map((club, i) => (
            <Grid item xs={6} key={i}>
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                  },
                }}
              >
                <Box 
                  sx={{ 
                    width: 40, 
                    bgcolor: 'warning.main', 
                    color: 'warning.contrastText', 
                    p: 0.5, 
                    borderRadius: 1,
                    textAlign: 'center',
                    mr: 1,
                    fontSize: '0.75rem',
                  }}
                >
                  {getDivisionNo(club)}
                </Box>
                <Typography 
                  sx={{ 
                    flex: 1, 
                    color: 'common.white', 
                    cursor: 'pointer',
                    fontSize: '0.875rem',
                    '&:hover': { textDecoration: 'underline' }
                  }}
                  onClick={() => setViewClubId(club.id)}
                >
                  {club.name}
                </Typography>
                <Radio
                  checked={club.id === clubId}
                  onChange={onClubChanged}
                  value={club.id}
                  name="club_id"
                  sx={{ 
                    color: club.id === clubId ? 'gold' : 'grey.400',
                    '&.Mui-checked': {
                      color: 'gold',
                    },
                    padding: 0.5,
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <BackNextMenuButtons backLink="/add-manager" nextAction={goNextPage} />

      </Box>
      {viewClubId && (
        <ClubViewMain clubId={viewClubId} closeModal={handleCloseModal} />
      )}
    </Container>
  );

};

export default SelectClub;