import database from "..";
import { Q } from "@nozbe/watermelondb";

class StaffsController {
  tableName = "staffs";

  index = async () => {
    const table = database.get(this.tableName);
    const staffs = await table.query(Q.sortBy("name", Q.asc)).fetch();
    return staffs;
  };

  findOne = async (id) => {
    const table = database.get(this.tableName);
    const staff = await table.find(id);
    const [_contract] = await staff.contracts.fetch();
    if (_contract) {
      const _club = await _contract.club.fetch();
      if (_club) {
        _contract._club = _club;
      }
      staff._contract = _contract;
    }
    return staff;
  };
}

export default new StaffsController();
