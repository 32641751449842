import stadiumSeeder from "./20240326181937-stadiums-seeder";
import clubSeeder from "./20240326232305-clubs-seeder";
import playerSeeder from "./20240326232340-players-seeder";
import youthSeeder from "./20240326232340-youths-seeder";
import chairmanSeeder from "./20240326232400-chairman-seeder";
import asstManagerSeeder from "./20240326232401-asst-manager-seeder";
import coachSeeder from "./20240326232401-coaches-seeder";
import scoutSeeder from "./20240326232420-scouts-seeder";
import physioSeeder from "./20240326232444-physios-seeder";
import trainerSeeder from "./20240326232445-trainer-seeder";
import playerSkillSeeder from "./20240326232532-player-skills-seeder";
import youthSkillSeeder from "./20240326232532-youth-skills-seeder";
import playerContractSeeder from "./20240503195219-player-contract-seeder";
import youthContractSeeder from "./20240503195219-youth-contract-seeder";
import chairmanContractSeeder from "./20240503195315-chairman-contract-seeder";
import asstManagerContractSeeder from "./20240503195316-asst-manager-contract-seeder";
import scoutContractSeeder from "./20240504195245-scout-contract-seeder";
import physioContractSeeder from "./20240504195255-physio-contract-seeder";
import coachContractSeeder from "./20240504195305-coach-contract-seeder";
import trainerContractSeeder from "./20240504195305-trainer-contract-seeder";
import clubFinanceSeeder from "./20240509134501-club-finance-seeder";
import playerPersonalSeeder from "./20240518085230-player-personal-seeder";
import youthPersonalSeeder from "./20240518085230-Youth-personal-seeder";
import competitionSeeder from "./20240525211024-competitions-seeder";
import divisionSeeder from "./20240525211730-divisions-seeder";
import participantSeeder from "./20240525211730-participants-seeder";
import roundSeeder from "./20240525211738-rounds-seeder";
import matchSeeder from "./20240525211739-matches-seeder";

const runSeeders = async () => {
  // DOWN
  await stadiumSeeder.down();
  await clubSeeder.down();
  await playerSeeder.down();
  await youthSeeder.down();
  await chairmanSeeder.down();
  await asstManagerSeeder.down();
  await coachSeeder.down();
  await scoutSeeder.down();
  await physioSeeder.down();
  await trainerSeeder.down();
  await playerSkillSeeder.down();
  await youthSkillSeeder.down();
  await playerContractSeeder.down();
  await youthContractSeeder.down();
  await chairmanContractSeeder.down();
  await asstManagerContractSeeder.down();
  await scoutContractSeeder.down();
  await physioContractSeeder.down();
  await coachContractSeeder.down();
  await trainerContractSeeder.down();
  await clubFinanceSeeder.down();
  await playerPersonalSeeder.down();
  await youthPersonalSeeder.down();
  await competitionSeeder.down();
  await divisionSeeder.down();
  await participantSeeder.down();
  await roundSeeder.down();
  await matchSeeder.down();
  // UP
  await stadiumSeeder.up();
  await clubSeeder.up();
  await playerSeeder.up();
  await youthSeeder.up();
  await chairmanSeeder.up();
  await asstManagerSeeder.up();
  await coachSeeder.up();
  await scoutSeeder.up();
  await physioSeeder.up();
  await trainerSeeder.up();
  await playerSkillSeeder.up();
  await youthSkillSeeder.up();
  await playerContractSeeder.up();
  await youthContractSeeder.up();
  await chairmanContractSeeder.up();
  await asstManagerContractSeeder.up();
  await scoutContractSeeder.up();
  await physioContractSeeder.up();
  await coachContractSeeder.up();
  await trainerContractSeeder.up();
  await clubFinanceSeeder.up();
  await playerPersonalSeeder.up();
  await youthPersonalSeeder.up();
  await competitionSeeder.up();
  await divisionSeeder.up();
  await participantSeeder.up();
  await roundSeeder.up();
  await matchSeeder.up();

  localStorage.setItem("seeding_complete", "true");
};

export default runSeeders;
