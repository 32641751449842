import { Model } from "@nozbe/watermelondb";
import { field, text, date, relation } from "@nozbe/watermelondb/decorators";

class Match extends Model {
  static table = "matches";
  static associations = {
    rounds: { type: "belongs_to", key: "round_id" },
  };

  @relation("rounds", "round_id") round;

  @text("participant1_id") participant1Id;
  @text("participant2_id") participant2Id;
  @field("club1_goals") club1Goals;
  @field("club2_goals") club2Goals;
  @date("match_date") matchDate;
}

export default Match;
