import database from "..";
import { faker } from "@faker-js/faker";

const youthSeeder = {
  tableName: "players",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);
      const clubs = await database.get("clubs").query().fetch();

      const maxYouthsPerClub = 22;
      const requiredPositions = ["GK", "GK", "DL", "DC", "DC", "DC", "DR", "MC", "MC", "ML", "MR", "LW", "RW", "CM/CAM", "FC", "ST"];
      const allPositions = [
        //"GK",
        //"DL",
        //"DC",
        //"DR",
        "DL/DR",
        "DL/DC",
        "DR/DC",
        "MC",
        "ML",
        "MR",
        "ML/MR",
        "CAM",
        "CM/CAM",
        "FC",
        "FC/ST",
        "ST",
        //"LW",
        //"RW",
        "LW/RW",
      ];
      const playersCount = clubs.length * maxYouthsPerClub;

      for (let i = 0; i < playersCount; i++) {
        await table.create((tb) => {
          tb.name = faker.person.fullName();
          tb.birthDate = faker.date.between({
            from: new Date(new Date().setFullYear(new Date().getFullYear() - 21)),
            to: new Date(new Date().setFullYear(new Date().getFullYear() - 15)),
          });
          tb.isYouth = true;
          tb.nationality = faker.location.country();
          tb.playingPosition = i < requiredPositions.length ? requiredPositions[i] : faker.helpers.arrayElement(allPositions);
          tb.morale = faker.helpers.arrayElement(["Excellent", "Good", "Decent", "Avarage", "Below avarage", "Bad"]);
          tb.condition = Math.floor(Math.random() * 21) + 80; // between 80 and 100 condition at start
          tb.injured = faker.datatype.boolean();
          tb.squadStatus = "Youth player"; // "Youth all have squatstatus "Youth player"
        });
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default youthSeeder;
