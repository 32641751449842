import { Model } from "@nozbe/watermelondb";
import { field, text, children, date } from "@nozbe/watermelondb/decorators";

class Manager extends Model {
  static table = "managers";
  static associations = {
    clubs: { type: "has_many", foreignKey: "stadium_id" },
  };

  @text("first_name") firstName;
  @text("last_name") lastName;
  @text("password") password;
}

export default Manager;
