import { useEffect, useState } from "react";
import moment from "moment";
import ClubViewMain from "./ClubMain";
import { competitionsController } from "../../database/controllers";

const ClubViewFixtures = ({ club }) => {
  const [participant, setParticipant] = useState(null);
  const [matches, setMatches] = useState([]);
  //added for Clubinfo pop up screen
  const [viewClubId, setViewClubId] = useState(null); // State to manage the visibility of ClubViewMain

  // Copied from SelectClub.js could better be a component instead of keep repeating the same code
  const handleCloseModal = () => {
    setViewClubId(null);
  };

  useEffect(() => {
    competitionsController
      .findMatchesByClub(club.id)
      .then(({ participant, matches }) => {
        setParticipant(participant);
        setMatches(matches);
      })
      .catch(console.error);
  }, [club]);

  return (
    <>
      <div className="game-status">
        Season playing schedule for Division {participant?._division?.divisionNo}.
        {/* Division: {participant?.Division.divisionNo}, Competition: {participant?.Division.Competition.name} */}
      </div>
      <div className="game-status mb-2 bg-black/50 text-center text-lg font-bold text-yellow-400 py-2">Devlog: Also the scores should be printed if a match is already played</div>

      <div className="board-main p-2 mb-2 rounded bg-black/20">
        <div className="bg-black/50 p-2 w-full flex justify-center">
          <div className="w-full h-screen overflow-y-auto">
            {matches.map((match, i) => {
              return (
                <div key={i} className="flex border-b border-gray-500 py-2">
                  <div className="w-1/6 text-center">
                    <button className="w-full rounded shadow-sm border text-white">{moment(match.matchDate).format("ll")}</button>
                  </div>

                  <div
                    style={{ cursor: match._participant1?._club?.id === club.id ? "default" : "pointer" }}
                    className="w-5/12 text-white font-semibold text-center"
                    onClick={() => {
                      if (match._participant1?._club?.id !== club.id) setViewClubId(match._participant1?._club?.id);
                    }}
                  >
                    {match._participant1?._club?.name}
                  </div>
                  <div className="w-1/12 text-yellow-300 font-semibold text-center">VS</div>
                  <div
                    style={{ cursor: match._participant2?._club?.id === club.id ? "default" : "pointer" }}
                    className="w-5/12 text-white font-semibold text-center"
                    onClick={() => {
                      if (match._participant2?._club?.id !== club.id) setViewClubId(match._prticipant2?._club?.id);
                    }}
                  >
                    {match._participant2?._club?.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {viewClubId && <ClubViewMain clubId={viewClubId} closeModal={handleCloseModal} />}
    </>
  );
};

export default ClubViewFixtures;
