// Added for MUI theming support
import { createTheme, ThemeProvider } from "@mui/material/styles"; // Step 1: Import createTheme and ThemeProvider
import CssBaseline from "@mui/material/CssBaseline";
import Main from "./game/Main";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import FlashScreen from "./FlashScreen";
import { Toaster } from "react-hot-toast";
//RW Multiple imports from the index.js filesfrom game/*/index.js files
import { ManagerInbox, Introduction, SelectClub, Scouting, GameSettings, GameCredits, AddManager, StartGame } from "./game/menu";
import { MatchPlayScreen } from "./game/match";
import { ClubInfo, ClubSquad, ClubFinances, ClubManage, ClubYouthPlayers, ClubMain, ClubFixturesSchedule } from "./game/club";
import { Competitions, CompetitionsMain, CompetitionsSecondDivision, CompetitionsThirdDivision, CompetitionsFourthDivision, CompetitionsCupCompetition } from "./game/competitions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { resetTacticalPositions } from "./store/reducers/clubReducer";
import runSeeders from "./database/seeders";

// Define custom MUI theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
      contrastText: "#dc2626", // Ensuring text is red
    },
    secondary: {
      main: "#bea76c", // Gold
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Apply styles to the root element of the Button
          backgroundColor: "#FFFFFF", // Use the primary main color for background
          color: "#dc2626", // Red text
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#ffe5e5", // Light grey background on hover
          },
        },
      },
    },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const [seeded, setSeeded] = useState(false);
  const router = createBrowserRouter([
    {
      path: "/*",
      Component: Main,
      children: [
        {
          path: "",
          Component: StartGame,
          children: [
            {
              path: "",
              Component: Introduction,
            },
            {
              path: "add-manager", // Don't think urls also needs to translate
              Component: AddManager,
            },
            {
              path: "select-club", // Don't think urls also needs to translate
              Component: SelectClub,
            },
          ],
        },
        {
          path: "club/*",
          Component: ClubMain,
          children: [
            {
              path: "",
              Component: ClubInfo,
            },
            {
              path: "finances",
              Component: ClubFinances,
            },
            {
              path: "fixtures",
              Component: ClubFixturesSchedule,
            },
            {
              path: "squad",
              Component: ClubSquad,
            },
            {
              path: "manage", // Don't think urls also needs to translate, not a good idea
              Component: ClubManage,
            },
            {
              path: "youth-players",
              Component: ClubYouthPlayers,
            },
          ],
        },
        {
          path: "inbox",
          Component: ManagerInbox,
        },
        {
          path: "competitions/*",
          Component: CompetitionsMain,
          children: [
            {
              path: "",
              Component: Competitions,
            },
            {
              path: "second-division",
              Component: CompetitionsSecondDivision,
            },
            {
              path: "third-division",
              Component: CompetitionsThirdDivision,
            },
            {
              path: "fourth-division",
              Component: CompetitionsFourthDivision,
            },
            {
              path: "cup-competition",
              Component: CompetitionsCupCompetition,
            },
          ],
        },
        {
          path: "scouting",
          Component: Scouting,
        },
        {
          path: "settings",
          Component: GameSettings,
        },
        {
          path: "credits",
          Component: GameCredits,
        },
      ],
    },
    {
      path: "/match/*",
      Component: MatchPlayScreen,
    },
  ]);

  useEffect(() => {
    const seed = async () => {
      const _seeded = localStorage.getItem("seeding_complete") === "true";
      if (!_seeded) {
        dispatch(resetTacticalPositions());
        await runSeeders();
      }
      setSeeded(true);
    };
    seed();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {/* Wrap application with ThemeProvider MUI */}
      <CssBaseline /> {/* Optional: It helps with consistent baseline styling? */}
      {/* <FlashScreen> */}
      {seeded && <RouterProvider router={router} />}
      <Toaster />
      {/* </FlashScreen> */}
    </ThemeProvider>
  );
};

export default App;
