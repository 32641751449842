import database from "..";
import { Q } from "@nozbe/watermelondb";

class ClubController {
  tableName = "competitions";

  index = async () => {
    const competitions = await database.get(this.tableName).query(Q.sortBy("name", Q.asc)).fetch();
    return competitions;
  };

  findOne = async (id) => {
    const competition = await database.get(this.tableName).find(id);
    return competition;
  };

  findOneDivision = async (id, divisionNo) => {
    const competition = await database.get(this.tableName).find(id);
    if (!competition) return null;
    const [division] = await database.get("divisions").query(Q.where("competition_id", competition.id), Q.where("division_no", divisionNo)).fetch();
    if (division) {
      const _participants = await division.participants.fetch();
      if (_participants) {
        for (const _participant of _participants) {
          const _club = await _participant.club.fetch();
          if (_club) {
            _participant._club = _club;
          }
        }
        division._participants = _participants;
      }
    }

    const _rounds = await division.rounds.fetch();
    for (const _round of _rounds) {
      const _matches = await _round.matches.fetch();
      for (const _match of _matches) {
        const _participant1 = await database.get("participants").find(_match.participant1Id);
        if (_participant1) {
          const _club = await _participant1.club.fetch();
          if (_club) {
            _participant1._club = _club;
          }
          _match._participant1 = _participant1;
        }
        const _participant2 = await database.get("participants").find(_match.participant2Id);
        if (_participant2) {
          const _club = await _participant2.club.fetch();
          if (_club) {
            _participant2._club = _club;
          }
          _match._participant2 = _participant2;
        }
      }
      _round._matches = _matches;
    }
    division._rounds = _rounds;

    return division;
  };

  findDivisionByClub = async (clubId) => {
    const [participant] = await database.get("participants").query(Q.where("club_id", clubId)).fetch();
    if (!participant) return null;
    const division = await participant.division.fetch();
    const _participants = await division.participants.fetch();
    if (_participants) {
      division._participants = _participants;
    }
    return division;
  };

  findMatchesByClub = async (clubId) => {
    const [participant] = await database.get("participants").query(Q.where("club_id", clubId)).fetch();
    if (!participant) return { participant: null, matches: [] };

    const _division = await participant.division.fetch();
    if (_division) {
      participant._division = _division;
    }
    const matches = await database
      .get("matches")
      .query(Q.or(Q.where("participant1_id", participant.id), Q.where("participant2_id", participant.id)))
      .fetch();

    for (const match of matches) {
      const _participant1 = await database.get("participants").find(match.participant1Id);
      if (_participant1) {
        const _club = await _participant1.club.fetch();
        if (_club) {
          _participant1._club = _club;
        }
        match._participant1 = _participant1;
      }
      const _participant2 = await database.get("participants").find(match.participant2Id);
      if (_participant2) {
        const _club = await _participant2.club.fetch();
        if (_club) {
          _participant2._club = _club;
        }
        match._participant2 = _participant2;
      }
    }

    return { participant, matches };
  };
}

export default new ClubController();
