import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { countryListAlpha2 } from "../helpers/countryList";
import { useDispatch, useSelector } from "react-redux";
import { setManager as setStoreManager } from "../../store/reducers/managerReducer";
import toast from "react-hot-toast";
import ClubScreenHeader from "../helpers/ClubScreenHeader";
import { useTranslation } from "react-i18next";

const AddManager = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeManager = useSelector((state) => state.manager);

  const [manager, setManager] = useState(storeManager);

  const onChangeValue = (ev) => {
    let field = ev.target.name;
    let mr = { ...manager };
    mr[field] = ev.target.value;
    setManager(mr);
  };

  const onSubmitForm = (ev) => {
    ev.preventDefault();
    if (!manager.firstName || !manager.lastName || !manager.country) {
      toast.error("Please fill all the fields!");
      return;
    }
    dispatch(setStoreManager(manager));
    navigate("/select-club");
  };

  return (
    <div className="game-main">
      <ClubScreenHeader title="Football Club Builder" />
     
      <div className="game-status">{t("menu.enterDetails")}</div>

      <div className="game-box h-96 mb-2 bg-black/50 relative py-6 px-2">
        <div className="h-full flex justify-center">
          <form onSubmit={onSubmitForm} className="text-white font-semibold text-base">
            <div className="mb-2 flex justify-between">
              <label className="w-48">First Name</label>
              <div className="mr-1">: </div>
              <input
                name="firstName"
                value={manager.firstName}
                onChange={onChangeValue}
                className="flex-1 py-1 px-2 bg-transparent border-b border-gray-500 focus:outline-none"
                type="text"
              />
            </div>
            <div className="mb-2 flex justify-between">
              <label className="w-48">Last Name </label>
              <div className="mr-1">: </div>
              <input
                name="lastName"
                value={manager.lastName}
                onChange={onChangeValue}
                className="flex-1 py-1 px-2 bg-transparent border-b border-gray-500 focus:outline-none"
                type="text"
              />
            </div>
            <div className="mb-2 flex justify-between">
              <label className="w-48">Country (Optional) </label>
              <div className="mr-1">: </div>
              <select
                name="country"
                id="CountryPicker"
                value={manager.country}
                onChange={onChangeValue}
                className="py-1 px-1 bg-transparent border-b text-white border-gray-500 focus:outline-none"
                type="text"
              >
                <option value={""} disabled className="text-black">
                  Select country...
                </option>
                {Object.entries(countryListAlpha2).map(([key, value]) => (
                  <option key={key} value={key} className="text-black">
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </form>
        </div>
      </div>

      <div className="bottom-menu mb-2 grid grid-cols-2 gap-1">
        <Link to="/" className="game-status shadow rounded">{t('menu.back')}</Link>
        <button onClick={onSubmitForm} className="game-status shadow rounded">{t('menu.next')}</button>
      </div>
    </div>
  );
};

export default AddManager;
