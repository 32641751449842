import database from "..";
import { Q } from "@nozbe/watermelondb";

class StadiumsController {
  tableName = "stadiums";

  index = async () => {
    const table = database.get(this.tableName);
    const stadiums = await table.query(Q.sortBy("name", Q.asc)).fetch();
    return stadiums;
  };

  findOne = async (id) => {
    const table = database.get(this.tableName);
    const stadium = await table.find(id);
    const clubs = await stadium.clubs.fetch();

    return {
      ...stadium,
      _clubs: clubs,
    };
  };
}

export default new StadiumsController();
