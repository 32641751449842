import database from "..";
import { faker } from "@faker-js/faker";

const competitionSeeder = {
  tableName: "competitions",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);

      for (let i = 0; i < 1; i++) {
        await table.create((tb) => {
          tb.name = faker.lorem.sentence();
        });
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default competitionSeeder;
