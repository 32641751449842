import React, { useState, useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { clubsController } from "../../database/controllers";
import MainField from "./MainField";
import MyTeamTactics from "./MyTeamTactics";
import OpponentTeamTactics from "./OpponentTeamTactics";

const MatchPlayScreen = (props) => {
  const clubId = useSelector((state) => state.club.id);
  const [club, setClub] = useState(null);
  // Game Time Counter
  const [time, setTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [firstHalfExtraTime, setFirstHalfExtraTime] = useState(Math.floor(Math.random() * 9) + 1);
  const [secondHalfExtraTime, setSecondHalfExtraTime] = useState(Math.floor(Math.random() * 9) + 1);
  const [halfTime, setHalfTime] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  // const extraTime = time >= 45 && time < 90 ? firstHalfExtraTime : secondHalfExtraTime;

  const [page, setPage] = useState("MATCH"); // 'MATCH', 'TACTICS_1', 'TACTICS_2'

  useEffect(() => {
    if (clubId) {
      clubsController
        .findOne(clubId)
        .then((club) => {
          setClub(club);
        })
        .catch(console.error);
    }
  }, [clubId]);

  const gameTimeCounter = () => {
    if (isPaused) {
      clearInterval(intervalId);
      setIsPaused(false);
    } else {
      const id = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime < 45) {
            return prevTime + 1;
          } else if (prevTime === 45 && firstHalfExtraTime > 0) {
            setFirstHalfExtraTime(firstHalfExtraTime - 1);
            return prevTime + 1;
          } else if (prevTime === 45 && firstHalfExtraTime === 0) {
            setHalfTime(true);
            clearInterval(id);
            return prevTime; // Keep the time at 45 minutes
          } else if (prevTime > 45 && prevTime < 90) {
            setHalfTime(false);
            return prevTime + 1;
          } else if (prevTime === 90 && secondHalfExtraTime > 0) {
            setSecondHalfExtraTime(secondHalfExtraTime - 1);
            return prevTime + 1;
          } else if (prevTime === 90 && secondHalfExtraTime === 0) {
            setGameEnded(true);
            clearInterval(id);
            return prevTime; // Keep the time at 90 minutes
          }
        });
      }, 1000);
      setIntervalId(id);
      setIsPaused(true);
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);
  // end of GameTimeCounter

  const renderPage = (club) => {
    switch (page) {
      case "MATCH":
        return <MainField club={club} />;
      case "TACTICS_1":
        return <MyTeamTactics club={club} />;
      case "TACTICS_2":
        return <OpponentTeamTactics club={club} />;
    }
  };

  if (!club) {
    return <div className="game-main">Loading...</div>;
  }

  return (
    <div className="game-cont flex min-h-screen">
      <div className="left-menu">
        <div className="text-black font-bold text-lg border border-black rounded bg-gray-300 shadow w-full flex justify-center mb-2 py-2 p-2">
          {halfTime ? `Half Time + ${firstHalfExtraTime}` : gameEnded ? `Full Time + ${secondHalfExtraTime}` : time}
        </div>
        {/* <div className="text-black font-bold text-lg border border-black rounded bg-gray-300 shadow w-full flex justify-center mb-2 py-2 p-2">
      {time >= 45 && time < 90 && extraTime > 0 ? `Half Time + ${extraTime}` : time >= 90 && extraTime > 0 ? `Full Time + ${extraTime}` : time >= 45 && time < 90 && extraTime === 0 ? "Half Time" : time >= 90 && extraTime === 0 ? "Full Time" : time}
      </div> */}
        {/* <div className="text-black font-bold text-lg border border-black rounded bg-gray-300 shadow w-full flex justify-center mb-2 py-2 p-2">{time}</div> */}
        <button onClick={gameTimeCounter} className={`menu-btn ${isPaused ? "active-menu-btn" : "rounded-lg"}`}>
          {isPaused ? "Pause Game" : "Kick Off Match"}
        </button>
        <button onClick={() => setPage("MATCH")} className={page == "MATCH" ? "menu-btn active-menu-btn" : "menu-btn rounded-lg"}>
          {/* Always not back to */}
          {/* Back to Match */}
          Match
        </button>
        <button
          onClick={() => {
            setPage("TACTICS_1");
            setIsPaused(true);
          }}
          className={page == "TACTICS_1" ? "menu-btn active-menu-btn" : "menu-btn rounded-lg"}
        >
          {club.name} Tactics
        </button>
        <button
          onClick={() => {
            setPage("TACTICS_2");
            setIsPaused(true);
          }}
          className={page == "TACTICS_2" ? "menu-btn active-menu-btn" : "menu-btn rounded-lg"}
        >
          Opponent.club Tactics
        </button>

        <label htmlFor="commentary_speed" className="menu-btn cursor-pointer text-yellow-400 border px-2 border-cyan-500 rounded bg-cyan-700 shadow w-full mb-2 py-1 block">
          <div>Commentary Speed:</div>
          <div>
            <select id="commentary_speed" className="cursor-pointer focus:outline-none bg-cyan-700 text-center w-full" defaultValue="3">
              <option value="5">Very Fast</option>
              <option value="4">Fast</option>
              <option value="3">Normal</option>
              <option value="2">Slow</option>
              <option value="1">Very Slow</option>
            </select>
          </div>
        </label>

        <NavLink to="/" className={"menu-btn rounded-lg"}>
          Main Menu
        </NavLink>
      </div>

      <div className="game-main overflow-x-auto" style={{ minWidth: 800 }}>
        {/* Better to do match one page, can need pass data one pate to other */}
        {renderPage(club)}
      </div>
    </div>
  );
};

export default MatchPlayScreen;
