import database from "..";
import { faker } from "@faker-js/faker";
import { Q } from "@nozbe/watermelondb";

function getFirstSaturdayOfAugust() {
  const currentYear = new Date().getFullYear();
  const firstDayOfAugust = new Date(currentYear, 7, 1); // August is month 7 (0-based index)

  // Calculate the day of the week of the first day of August
  const dayOfWeek = firstDayOfAugust.getDay();

  // Calculate the number of days to the first Saturday
  const daysToFirstSaturday = (6 - dayOfWeek + 7) % 7;

  // Adjust the date object to the first Saturday
  const firstSaturday = new Date(firstDayOfAugust);
  firstSaturday.setDate(firstDayOfAugust.getDate() + daysToFirstSaturday);

  return firstSaturday;
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const matchSeeder = {
  tableName: "matches",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);

      const divisions = await database.get("divisions").query().fetch();
      const firstSaturdayOfAugust = getFirstSaturdayOfAugust();

      for (let k = 0; k < divisions.length; k++) {
        const rounds = await database.get("rounds").query(Q.where("division_id", divisions[k].id)).fetch();
        const participants = await database.get("participants").query(Q.where("division_id", divisions[k].id)).fetch();

        console.log(participants);

        const totalRounds = rounds.length;
        const matches = [];

        // Generate round-robin schedule
        const roundRobinSchedule = [];
        for (let i = 0; i < participants.length - 1; i++) {
          const round = [];
          for (let j = 0; j < participants.length / 2; j++) {
            let home = (i + j) % (participants.length - 1);
            let away = (participants.length - 1 - j + i) % (participants.length - 1);
            if (j === 0) {
              away = participants.length - 1;
            }
            round.push([participants[home], participants[away]]);
          }
          roundRobinSchedule.push(round);
        }

        // Duplicate schedule for home and away matches
        const fullSchedule = [];
        roundRobinSchedule.forEach((round) => {
          const homeRound = round.map((match) => ({ home: match[0], away: match[1] }));
          const awayRound = round.map((match) => ({ home: match[1], away: match[0] }));
          fullSchedule.push(homeRound);
          fullSchedule.push(awayRound);
        });

        // Shuffle rounds
        shuffleArray(fullSchedule);

        // Assign matches to rounds
        for (let roundIndex = 0; roundIndex < totalRounds; roundIndex++) {
          const currentRoundMatches = fullSchedule[roundIndex % fullSchedule.length];
          const round = rounds[roundIndex];

          for (let i = 0; i < currentRoundMatches.length; i++) {
            const match = currentRoundMatches[i];

            const matchDate = new Date(firstSaturdayOfAugust); // Initialize the match date
            const dateAdd = i < 5 ? 0 : 1;
            const weekendDay = roundIndex * 7 + dateAdd;
            matchDate.setDate(matchDate.getDate() + weekendDay); // Increment the date

            const data = {
              participant1Id: match.home.id,
              participant2Id: match.away.id,
              club1Goals: faker.number.int({ min: 0, max: 5 }),
              club2Goals: faker.number.int({ min: 0, max: 5 }),
              matchDate: new Date(matchDate),
            };

            matches.push(data);

            await table.create((tb) => {
              tb.round.set(round);
              tb.participant1Id = data.participant1Id;
              tb.participant2Id = data.participant2Id;
              tb.club1Goals = data.club1Goals;
              tb.club2Goals = data.club2Goals;
              tb.matchDate = data.matchDate;
            });
          }
        }

        // Update participant stats after all matches are generated
        for (let match of matches) {
          const pc1 = await database.get("participants").find(match.participant1Id);
          if (pc1) {
            const win = match.club1Goals > match.club2Goals ? 1 : 0;
            const lose = match.club1Goals < match.club2Goals ? 1 : 0;
            const draw = match.club1Goals === match.club2Goals ? 1 : 0;
            const point = win === 1 ? 3 : draw === 1 ? 1 : 0;

            await pc1.update(() => {
              pc1.played = pc1.played + 1;
              pc1.wins = pc1.wins + win;
              pc1.loses = pc1.loses + lose;
              pc1.draws = pc1.draws + draw;
              pc1.points = pc1.points + point;
              pc1.goalsAg = pc1.goalsAg + match.club2Goals;
              pc1.goalsFor = pc1.goalsFor + match.club1Goals;
            });
          }

          const pc2 = await database.get("participants").find(match.participant2Id);
          if (pc2) {
            const win = match.club2Goals > match.club1Goals ? 1 : 0;
            const lose = match.club2Goals < match.club1Goals ? 1 : 0;
            const draw = match.club1Goals === match.club2Goals ? 1 : 0;
            const point = win === 1 ? 3 : draw === 1 ? 1 : 0;

            await pc2.update(() => {
              pc2.played = pc2.played + 1;
              pc2.wins = pc2.wins + win;
              pc2.loses = pc2.loses + lose;
              pc2.draws = pc2.draws + draw;
              pc2.points = pc2.points + point;
              pc2.goalsAg = pc2.goalsAg + match.club1Goals;
              pc2.goalsFor = pc2.goalsFor + match.club2Goals;
            });
          }
        }
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default matchSeeder;
