import { Model } from "@nozbe/watermelondb";
import { field, text, relation, date } from "@nozbe/watermelondb/decorators";

class PlayerContract extends Model {
  static table = "player_contracts";

  static associations = {
    clubs: { type: "belongs_to", key: "club_id" },
    players: { type: "belongs_to", key: "player_id" },
  };

  @relation("clubs", "club_id") club;
  @relation("players", "player_id") player;

  @text("contract_bonus") contractBonus;
  @text("contract_length") contractLength;
  @date("contract_expires") contractExpires;
  @field("transfer_value") transferValue;
  @text("happiness") happiness;
  @field("is_youth") isYouth;
  @field("is_captain") isCaptain;
  @field("on_transfer_list") onTransferList;
  @field("on_loan_list") onLoanList;
  @field("monthly_wage") monthlyWage;
}

export default PlayerContract;
