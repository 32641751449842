import { en } from "./en";
import { fr } from "./fr";
import { de } from "./de";
import { nl } from "./nl";
import { es } from "./es";

const resources = {
  en,
  fr,
  de,
  nl,
  es,
};

export default resources;
