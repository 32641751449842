import React from 'react';

const ProgressBar = ({ form }) => {
  const completedScaled = Math.ceil(form / 20); // Scale the form value from 0-100 to 1-5

  const getColor = (index) => {
    if (index < completedScaled) {
      switch (completedScaled) {
        case 1:
          return "bg-red-600"; // 1-20
        case 2:
          return "bg-red-400"; // 21-40
        case 3:
          return "bg-yellow-500"; // 41-60
        case 4:
          return "bg-green-400"; // 61-80
        case 5:
          return "bg-green-600"; // 81-100
        default:
          return "bg-gray-300";
      }
    } else {
      return "bg-gray-300";
    }
  };

  return (
    <div className="w-full flex">
      {Array(5)
        .fill(0)
        .map((_, i) => (
            <div key={i} className="w-4 h-2 bg-gray-300 rounded mr-0.5">
                <div className={`${getColor(i)} h-full`}></div>
            </div>
        ))}
    </div>
  );
};

export default ProgressBar;