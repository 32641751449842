import database from "..";
import { faker } from "@faker-js/faker";
import { Q } from "@nozbe/watermelondb";

const clubFinanceSeeder = {
  tableName: "club_finances",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);
      const clubs = await database.get("clubs").query().fetch();

      for (let i = 0; i < clubs.length; i++) {
        const playersContracts = await database.get("player_contracts").query(Q.where("club_id", clubs[i].id)).fetch();
        const staffsContracts = await database.get("staff_contracts").query(Q.where("club_id", clubs[i].id)).fetch();

        const playersTotalSalary = playersContracts.reduce((n, { monthlyWage }) => n + monthlyWage, 0);
        const staffsTotalSalary = staffsContracts.reduce((n, { monthlyWage }) => n + monthlyWage, 0);

        await table.create((tb) => {
          tb.club.set(clubs[i]);
          // This code first generates a random number between 150000 and 1500000. It then adds 499 to the number and divides it by 500, rounding the result to the nearest integer.
          tb.iTvMoney = Math.round((faker.number.int({ min: 150000, max: 1500000 }) + 499) / 500) * 500;
          tb.iSponsors = Math.round((faker.number.int({ min: 50000, max: 1000000 }) + 499) / 500) * 500;
          tb.iTransfers = Math.round((faker.number.int({ min: 100000, max: 2000000 }) + 499) / 500) * 500;
          tb.iPriceMoney = Math.round((faker.number.int({ min: 50000, max: 500000 }) + 499) / 500) * 500;
          tb.iTicketSales = Math.round((faker.number.int({ min: 100000, max: 1000000 }) + 499) / 500) * 500;
          tb.iMerchandise = Math.round((faker.number.int({ min: 50000, max: 500000 }) + 499) / 500) * 500;
          tb.iOther = Math.round((faker.number.int({ min: 10000, max: 200000 }) + 499) / 500) * 500;
          tb.ePlayerSalaries = playersTotalSalary;
          tb.eStaffSalaries = staffsTotalSalary;
          tb.eTransferFees = Math.round((faker.number.int({ min: 100000, max: 2000000 }) + 499) / 500) * 500;
          tb.eStadiumMaintenance = Math.round((faker.number.int({ min: 50000, max: 500000 }) + 499) / 500) * 500;
          tb.eYouthDevelopment = Math.round((faker.number.int({ min: 50000, max: 500000 }) + 499) / 500) * 500;
          tb.eScouting = Math.round((faker.number.int({ min: 10000, max: 200000 }) + 499) / 500) * 500;
          tb.eOther = Math.round((faker.number.int({ min: 10000, max: 200000 }) + 499) / 500) * 500;
        });
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default clubFinanceSeeder;
