import React, { useEffect, useState } from 'react';

// Use require.context to dynamically import all .png files in the directory
const imagesContext = require.context('../assets/images/players/profilePictures', true, /\.png$/);

const PlayerImageGenerator = () => {
  const [imagePath, setImagePath] = useState('');

  useEffect(() => {
    // Get all image keys (paths) from the context
    const imageKeys = imagesContext.keys();
    // Select a random key
    const randomKey = imageKeys[Math.floor(Math.random() * imageKeys.length)];
    // Use the random key to get the corresponding image
    const randomImage = imagesContext(randomKey);
    
    // Try to get the default export, if available (depending on how images are exported)
    const resolvedImagePath = randomImage.default || randomImage;

    setImagePath(resolvedImagePath); 
  }, []);

  if (!imagePath) {
    return <div>Loading...</div>; // Or another placeholder if you prefer
  }

  return <img src={imagePath} alt="Player" className="w-24 h-24" />;
};

export default PlayerImageGenerator;