// TacticsFormationPlayingPositions.js

// This file creates the playing positions on the field for each formation

export const TacticsFormationPlayingPositions = {
  532: [
    // 1 goalkeeper
    { top: "90%", left: "45%", name: "GK", playerId: null },

    // 5 defenders
    { top: "70%", left: "5%", name: "DL", playerId: null },
    { top: "70%", left: "25%", name: "DC", playerId: null },
    { top: "70%", left: "45%", name: "DC", playerId: null },
    { top: "70%", left: "65%", name: "DC", playerId: null },
    { top: "70%", left: "85%", name: "DR", playerId: null },

    // 3 midfielders
    { top: "45%", left: "15%", name: "ML", playerId: null },
    { top: "45%", left: "45%", name: "MC", playerId: null },
    { top: "45%", left: "75%", name: "MR", playerId: null },

    // 2 forwards
    { top: "20%", left: "30%", name: "FC", playerId: null },
    { top: "20%", left: "60%", name: "FC", playerId: null },
  ],
  442: [
    { top: "90%", left: "45%", name: "GK", playerId: null },

    { top: "70%", left: "10%", name: "DL", playerId: null },
    { top: "70%", left: "30%", name: "DC", playerId: null },
    { top: "70%", left: "60%", name: "DC", playerId: null },
    { top: "70%", left: "80%", name: "DR", playerId: null },

    { top: "45%", left: "10%", name: "ML", playerId: null },
    { top: "45%", left: "30%", name: "MC", playerId: null },
    { top: "45%", left: "60%", name: "MC", playerId: null },
    { top: "45%", left: "80%", name: "MR", playerId: null },

    { top: "20%", left: "30%", name: "FC", playerId: null },
    { top: "20%", left: "60%", name: "FC", playerId: null },
  ],
  433: [
    { top: "90%", left: "45%", name: "GK", playerId: null },

    { top: "70%", left: "10%", name: "DL", playerId: null },
    { top: "70%", left: "30%", name: "DC", playerId: null },
    { top: "70%", left: "60%", name: "DC", playerId: null },
    { top: "70%", left: "80%", name: "DR", playerId: null },

    { top: "45%", left: "15%", name: "ML", playerId: null },
    { top: "45%", left: "40%", name: "MC", playerId: null },
    { top: "45%", left: "65%", name: "MR", playerId: null },

    { top: "20%", left: "20%", name: "FC", playerId: null },
    { top: "20%", left: "40%", name: "FC", playerId: null },
    { top: "20%", left: "60%", name: "FC", playerId: null },
  ],
};

export default TacticsFormationPlayingPositions;
