import React from 'react';

const ClubScreenHeader = ({ title }) => (
  <div className="clubs-title w-full h-14 flex shadow mb-2">
    <div className="club-1 h-full w-full bg-red-600 p-3 rounded">
      <div className="club-name text-2xl text-white font-semibold text-center">{title}</div>
    </div>
  </div>
);

export default ClubScreenHeader;