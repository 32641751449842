import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PlayerMain from "../player/PlayerMain";
import TeamPlayerPositionSelection from "../helpers/TeamPlayerPositionSelection";
import TacticsFormationScreen from "../helpers/TacticsFormationScreenHelper";
// Thinking of in the future moving Playerpositions to the TacticsFormationScreenHelper.js file... For now its ok.
import { PlayerPositions } from "../helpers/ClubPlayerTeamPositions";
import { selectSquadTacticalPositions, setSquadFormation, setSquadTacticalPositions } from "../../store/reducers/clubReducer";
import { clubsController } from "../../database/controllers";

const ClubSquad = (props) => {
  const [playerId, setPlayerId] = useState(null);
  const clubId = useSelector((state) => state.club.id);
  const [club, setClub] = useState(null);
  const tacticalPositions = useSelector(selectSquadTacticalPositions);
  const tacticalFormation = useSelector((state) => state.club.squadFormation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clubId) {
      clubsController
        .findOne(clubId)
        .then((club) => {
          setClub(club);
        })
        .catch(console.error);
    }
  }, [clubId]);

  const handleCloseModal = () => {
    setPlayerId(null);
  };

  const setTacticalFormation = (formation) => {
    dispatch(setSquadFormation(formation));
  };

  const setTacticalPositions = (positions) => {
    dispatch(setSquadTacticalPositions(positions));
  };

  if (!club) {
    return <div className="game-main">Loading...</div>;
  }

  return (
    <>
      <div className="game-status">
        The {club.name} squad is counting {club?._seniorContracts?.length} First squad players.
        {club?._seniorContracts?.length < 20 && (
          <>
            That's too few players!, imagine if players get injured?
            <Link to="/scouting" className="ml-2 text-blue-500 underline">
              Consider buying or loaning players on the transfer market!
            </Link>
          </>
        )}
      </div>

      <PlayerPositions tacticalPositions={tacticalPositions} />

      <TeamPlayerPositionSelection
        playerContracts={club?._seniorContracts}
        tacticalPositions={tacticalPositions}
        setPlayerId={setPlayerId}
        setTacticalPositions={setTacticalPositions}
      />

      {/* Load the tactic screen component after a message*/}
      <div className="mt-3 game-status">Tactical Squat Formation</div>

      <TacticsFormationScreen
        playerContracts={club?._seniorContracts}
        tacticalPositions={tacticalPositions}
        setTacticalPositions={setTacticalPositions}
        tacticalFormation={tacticalFormation}
        setTacticalFormation={setTacticalFormation}
      />

      {playerId && <PlayerMain playerId={playerId} closeModal={handleCloseModal} />}
    </>
  );
};

export default ClubSquad;
