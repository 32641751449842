export const de = {
  translation: {

    general: {
      alldivision: "Alle Divisionen",
      division1: "Erste Liga",
      division2: "Zweite Liga",
      division3: "Dritte Liga",
      division4: "Vierte Liga",
      founded: "Gegründet",
      generalinfo: "Allgemeine Informationen",
      stadiumground: "Stadionübersicht",
      stadiumname: "Stadionname",
      stadiumcapacity: "Stadionkapazität",
      fanbase: "Fanbasis",
      reputation: "Ruf",
      youtfacilities: "Jugendanlagen",
      trainingfacilities: "Trainingsanlagen",
      trainingfields: "Trainingsplätze",
      transferbudget: "Transferbudget",
      wagebudget: "Gehaltsbudget",
      league: "Liga",
      leagueposition: "Ligaposition",
    },

    header: {
      title: "Willkommen zu FootballClubBuilder!",
    },

    menu: {
      inbox: "Posteingang",
      introduction: "Einführung",
      selectClub: "Verein Wählen",
      scouting: "Scouting",
      settings: "Einstellungen",
      credits: "Credits",
      startGame: "Spiel Starten",
      clubInfo: "Vereinsinfo",
      clubFinances: "Vereinsfinanzen",
      clubFixtures: "Spielplan des Vereins",
      clubSquad: "Vereinsmannschaft",
      clubManage: "Vereinsmanagement",
      clubYouthPlayers: "Jugendspieler des Vereins",
      competitions: "Wettbewerbe",
      competitionsSecondDivision: "Zweite Liga",
      competitionsThirdDivision: "Dritte Liga",
      competitionsFourthDivision: "Vierte Liga",
      competitionsCupCompetition: "Pokalwettbewerb",
      currencySetting: "Währungseinstellung",
      languageSetting: "Spracheinstellung",
      exitGame: "Spiel Beenden",
      enterDetails: "Geben Sie Ihre persönlichen Daten ein",
      manageTeam: "Team Verwalten",
      myTeamTactics: "Taktiken Meines Teams",
      otherTeamTactics: "Gegner-Taktiken",
      selectAClub: "Bitte Club wählen",
      selectClubToManage: "Wählen Sie Ihren Club zur Verwaltung aus",
      back: "Zurück",
      next: "Weiter",
    },

    add: {
      addManager: "Manager hinzufügen",
      selectAClub: "Bitte Club wählen",
      select: "Auswählen",
    },

  },
};
