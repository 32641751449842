import { useSelector } from "react-redux";
import FacilitiesExpansion from "../assets/images/FacilitiesExpansion.jpg";
import { useEffect, useState } from "react";
// import StaffDetails from "../staff/StaffDetails"; to show staff details popup
import StaffMain from "../staff/StaffMain";
import { competitionsController } from "../../database/controllers";

const renderTableRow = (label, value, key) => (
  <tr key={key} className="border-b border-gray-500">
    <td className="text-custom-gold px-2 font-extrabold">{label}</td>
    <td className="text-white font-bold">{value}</td>
  </tr>
);

const ClubViewInfo = ({ club }) => {
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [staffContracts, setStaffContracts] = useState([]);
  const [division, setDivision] = useState(null);

  const staffRoles = { chairman: "Chairman", asst_manager: "Assistant Manager", coach: "Coach", physio: "Physio", trainer: "Trainer", scout: "Scout" };

  const serialize = { 1: "1st", 2: "2nd", 3: "3rd", 4: "4th" };

  useEffect(() => {
    const getData = async () => {
      const order = ["chairman", "asst_manager", "coach", "physio", "trainer", "scout"];
      const staffContracts = [...club._staffContracts];

      staffContracts.sort(function (a, b) {
        return order.indexOf(a._staff.role) - order.indexOf(b._staff.role);
      });
      setStaffContracts(staffContracts);

      competitionsController
        .findDivisionByClub(club.id)
        .then((division) => {
          setDivision(division);
        })
        .catch(console.error);
    };

    getData();
  }, [club]);

  const getLeagueRank = (participants) => {
    for (let i = 0; i < participants.length; i++) {
      if (participants[i].clubId === club.id) {
        return i + 1;
      }
    }
    return null;
  };

  return (
    <>
      <div className="game-status rounded">Estimated Club value $110,000,000</div>
      <div className="board-main mb-2 rounded bg-black/50 p-2">
        {/* I think these stats will all belong to different classess. But they are all important club stats */}
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
          <div className="w-full">
            <div>
              <button className="system-btn w-full rounded-tl rounded-tr">General Info</button>
            </div>

            <table className="w-full">
              {/* <div><button className="system-btn w-full rounded-t">General Info</button></div> */}

              <tbody>
                {renderTableRow("Founded", club.founded, "founded")}
                {renderTableRow("Stadium Name", club._stadium?.name, "stadium-name")}
                {renderTableRow("Stadium Capacity", club?._stadium?.capacity, "stadium-capacity")}
                {renderTableRow("Fanbase", club.fanbase, "fanbase")}
                {renderTableRow("Reputation", club.reputation, "reputation")}
                {renderTableRow("Youth Facilities", club.youthFacilities, "youth-facilities")}
                {renderTableRow("Training Facilities", club.trainingFacilities, "training-facilities")}
                {renderTableRow("Training Fields", "2", "training-fields")}
                {renderTableRow("Transfer Budget", new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(club?.transferBudget), "transfer-budget")}
                {renderTableRow("Wage Budget", new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(club?.wageBudget), "wage-budget")}
                {renderTableRow("League", division ? `${serialize[division?.divisionNo]} Division` : "", "league")}
                {renderTableRow("League Position", division ? getLeagueRank(division._participants) : null, "league-position")}
              </tbody>
            </table>
          </div>
          <div className="w-full mb-2 justify-center">
            <div>
              <button className="system-btn rounded-tl rounded-tr">Stadium Ground Overview</button>
            </div>
            <div className="flex items-center justify-center max-h-screen overflow-auto">
              <img className="w-full h-full object-cover" src={FacilitiesExpansion} alt="Go to your stadium ground!" />
            </div>
          </div>
        </div>
      </div>

      <div className="game-status rounded-lg">Board of Directors and staff</div>

      <div className="board-main mb-2 mt-2 rounded-lg bg-black/30 p-2">
        <div className="w-full overflow-y-auto">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-2">
            {staffContracts?.map((contract, i) => (
              <div key={i} className="w-full flex border-b border-gray-500">
                <div className="w-1/2 text-center mr-1 items-center">
                  <button onClick={() => setSelectedStaff(contract._staff.id)} className="menu-btn">
                    {staffRoles[contract._staff?.role]}
                  </button>
                </div>
                <div className="ml-2 w-1/2 text-white text-xl font-bold flex items-center justify-center">{contract._staff?.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="board-main mb-2 mt-2 rounded-lg bg-black/30 p-2">
        <div className="w-full overflow-y-auto">
          <div className="grid grid-cols-2 gap-2">
            {staffContracts?.map((contract, i) => (
              <div key={i} className="w-full flex border-b border-gray-500">
                <div className="w-1/2 text-center mr-1 items-center">
                  <button onClick={() => setSelectedStaffContract(contract.Staff)} className="menu-btn">
                    {staffRoles[contract._staff.role]}
                  </button>
                </div>
                <div className="ml-2 w-1/2 text-white text-xl font-bold flex items-center justify-center">{contract._staff.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {selectedStaff && <StaffMain club={club} staffId={selectedStaff} closeModal={() => setSelectedStaff(null)} />}
    </>
  );
};

export default ClubViewInfo;
