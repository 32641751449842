import { useEffect, useState } from "react";
import moment from "moment";
import { competitionsController } from "../../database/controllers";
import ClubViewMain from "../clubview/ClubMain";

const CompetitionsThirdDivision = (props) => {
  const [openRound, setOpenRound] = useState(null);
  const [division, setDivision] = useState(null);
  const [viewClubId, setViewClubId] = useState(null);
  const divisionNo = 3;

  // State to manage sorting
  const [sortedParticipants, setSortedParticipants] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "points",
    direction: "descending",
  });

  const handleCloseModal = () => {
    setViewClubId(null);
  };

  useEffect(() => {
    competitionsController
      .index()
      .then((competitions) => {
        if (competitions.length > 0) {
          competitionsController
            .findOneDivision(competitions[0].id, divisionNo)
            .then((division) => {
              if (division) {
                setDivision(division);
                setSortedParticipants(division._participants);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (division) {
      const sorted = [...division._participants].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === "ascending" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === "ascending" ? 1 : -1;
        return 0;
      });
      setSortedParticipants(sorted);
    }
  }, [sortConfig, division]);

  return (
    <>
      <div className="game-main rounded bg-black/20">
        <div className="game-status rounded">Third Divsion 20 clubs</div>
        <div className="game-status rounded">This competition has two relegating clubs at the bottom and the top 2 clubs will be promoted to the Second Division.</div>

        <div className="h-180 overflow-y-auto">
          <table className="w-full bg-black/40">
            <thead>
              <tr className="pt-3 pb-3 sm:pt-4 sm:pb-4 bg-black rounded-lg">
                <th className="tableHeader w-20 text-custom-gold cursor-pointer" onClick={() => requestSort("points")}>
                  Pos
                </th>
                <th className="tableHeader text-left cursor-pointer w-56" onClick={() => requestSort("Club.name")}>
                  Clubname
                </th>
                <th className="tableHeader w-10 cursor-pointer" onClick={() => requestSort("played")}>
                  Pld
                </th>
                <th className="tableHeader w-10 cursor-pointer" onClick={() => requestSort("wins")}>
                  Won
                </th>
                <th className="tableHeader w-10 cursor-pointer" onClick={() => requestSort("draws")}>
                  Draw
                </th>
                <th className="tableHeader w-10 cursor-pointer" onClick={() => requestSort("loses")}>
                  Lst
                </th>
                <th className="tableHeader w-10 cursor-pointer" onClick={() => requestSort("goalsFor")}>
                  For
                </th>
                <th className="tableHeader w-10 cursor-pointer" onClick={() => requestSort("goalsAg")}>
                  Ag
                </th>
                <th className="tableHeader w-10 cursor-pointer" onClick={() => requestSort("points")}>
                  Points
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedParticipants.map((pc, i) => (
                <tr key={i} className={`border ${i === 0 ? "border-b-3 border-green-500" : i === 15 ? "border-b-3 border-red-500" : "border-gray-500"} hover:bg-gray-700`}>
                  <td className="w-20 text-custom-gold px-2 text-center">
                    <button className="w-20">{i + 1}</button>
                  </td>
                  <td className="text-white cursor-pointer w-80" onClick={() => setViewClubId(pc._club?.id)}>
                    {pc._club?.name}
                  </td>
                  <td className="text-white text-center w-10">{pc.played}</td>
                  <td className="text-white text-center w-10">{pc.wins}</td>
                  <td className="text-white text-center w-10">{pc.draws}</td>
                  <td className="text-white text-center w-10">{pc.loses}</td>
                  <td className="text-white text-center w-10">{pc.goalsFor}</td>
                  <td className="text-white text-center w-10">{pc.goalsAg}</td>
                  <td className="text-white text-center w-10">{pc.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="game-status rounded mt-3">Match fixtures for this season</div>

      {division?._rounds.map((round, index) => (
        <div key={index} id="accordion" className="w-full">
          <div className="round my-2 bg-white/50 rounded-lg shadow-md overflow-hidden">
            <h3 className="text-lg font-semibold bg-gray-800 text-white py-2 px-4 cursor-pointer" onClick={() => setOpenRound(openRound === index ? null : index)}>
              Round {round.roundNo}
            </h3>
            {openRound === index && (
              <div className="matches p-4">
                {round._matches.map((match, idx) => (
                  <div key={idx} className="py-1 grid grid-cols-5 gap-x-3 border-b border-gray-200 last:border-b-0">
                    <span className="text-gray-700 col-span-1">{moment(match.matchDate).format("YYYY-MMM-DD")}</span>
                    <div className="grid grid-cols-5 gap-x-3 col-span-4">
                      <span className="text-gray-700 col-span-2">{match._participant1?._club?.name}</span>
                      <span>vs</span>
                      <span className="text-gray-700 col-span-2">{match._participant2?._club?.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}

      {viewClubId && <ClubViewMain clubId={viewClubId} closeModal={handleCloseModal} />}
    </>
  );
};

export default CompetitionsThirdDivision;
