import { useState } from "react";
import PlayerMain from "../player/PlayerMain";
import { Link } from "react-router-dom";
import TeamPlayerPositionSelection from "./TeamPlayerPositionSelection";

const ClubViewSquad = ({ club }) => {
  const [playerId, setPlayerId] = useState(null);
  const handleCloseModal = () => {
    setPlayerId(null);
  };

  return (
    <>
      <div className="game-status">
        The {club.name} squad is counting {club?._seniorContracts?.length} First squad players.
        {club?._seniorContracts?.length < 20 && (
          <>
            That's too few players!, imagine if players get injured?
            <Link to="/scouting" className="ml-2 text-blue-500 underline">
              Consider buying or loaning players on the transfer market!
            </Link>
          </>
        )}
      </div>

      <TeamPlayerPositionSelection playerContracts={club?._seniorContracts} setPlayerId={setPlayerId} isYouth={false} />

      {playerId && <PlayerMain playerId={playerId} closeModal={handleCloseModal} />}
    </>
  );
};

export default ClubViewSquad;
