import database from "..";

const divisionSeeder = {
  tableName: "divisions",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);
      const competition = await database.get("competitions").query().fetch();

      await table.create((tb) => {
        tb.competition.set(competition[0]);
        tb.divisionNo = 1;
        tb.participantsCount = 18;
      });

      await table.create((tb) => {
        tb.competition.set(competition[0]);
        tb.divisionNo = 2;
        tb.participantsCount = 18;
      });

      await table.create((tb) => {
        tb.competition.set(competition[0]);
        tb.divisionNo = 3;
        tb.participantsCount = 20;
      });

      await table.create((tb) => {
        tb.competition.set(competition[0]);
        tb.divisionNo = 4;
        tb.participantsCount = 22;
      });

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default divisionSeeder;
