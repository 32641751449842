import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BackNextMenuButtons = ({ backLink, nextAction }) => {
  const { t } = useTranslation();

  return (
    <div className="bottom-menu mt-2 grid grid-cols-2 gap-1">
      <Link to={backLink} className="game-status p-1 rounded shadow">
        {t("menu.back")}
      </Link>
      <button onClick={nextAction} className="game-status p-1 rounded shadow">
        {t("menu.next")}
      </button>
    </div>
  );
};

export default BackNextMenuButtons;