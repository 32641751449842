import { Model } from "@nozbe/watermelondb";
import { field, text, children, relation } from "@nozbe/watermelondb/decorators";

class Division extends Model {
  static table = "divisions";

  static associations = {
    competitions: { type: "belongs_to", key: "competition_id" },
    rounds: { type: "has_many", foreignKey: "division_id" },
    participants: { type: "has_many", foreignKey: "division_id" },
  };

  @relation("competitions", "competition_id") competition;
  @children("rounds") rounds;
  @children("participants") participants;

  @field("division_no") divisionNo;
  @field("participants_count") participantsCount;
}

export default Division;
