const StaffHeader = ({ staff, currentTab, setCurrentTab }) => {
  const tabs = [
    { name: "Profile", to: "profile" },
    { name: "Contract", to: "contract" },
    { name: "History", to: "history" },
  ];

  return (
    <>
      <div className="clubs-title w-full h-14 flex shadow mb-2">
        <div className="club-1 h-full w-full bg-red-500 p-3 rounded">
          <div className="club-name text-2xl text-white font-semibold text-center">{currentTab ? `${staff.name} - ${staff._contract?._club?.name}` : ""}</div>
        </div>
      </div>

      <div className="mb-2 w-full grid grid-cols-3 gap-2">
        {tabs.map((tab, index) => (
          <div key={index} className="font-semibold text-center">
            <button onClick={() => setCurrentTab(tab.to)} className={`block teamscreen-btn ${currentTab === tab.to ? "teamscreen-btn-active" : ""}`}>
              {tab.name}
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default StaffHeader;
