import database from "..";

const roundSeeder = {
  tableName: "rounds",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);

      const divisions = await database.get("divisions").query().fetch();

      for (let j = 0; j < divisions.length; j++) {
        let roundNo = 1;
        for (let i = 0; i < (divisions[j].participantsCount - 1) * 2; i++) {
          await table.create((tb) => {
            tb.division.set(divisions[j]);
            tb.roundNo = roundNo;
            tb.roundType = i % 2 == 0 ? "HOME" : "AWAY";
          });
          roundNo += 1;
        }
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default roundSeeder;
