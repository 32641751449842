import { useState } from "react";
import { Link } from "react-router-dom";
import PlayerMain from "../player/PlayerMain";
import TeamPlayerPositionSelection from "./TeamPlayerPositionSelection";

const ClubViewYouthPlayers = ({ club }) => {
  const [playerId, setPlayerId] = useState(null);

  const handleCloseModal = () => {
    setPlayerId(null);
  };

  return (
    <>
      <div className="game-status">
        The {club.name} youth squad is counting {club?._youthContracts?.length} youth players.
        {club?._youthContracts?.length < 18 && (
          <>
            That's too few players!, imagine if players get injured? Consider{" "}
            <Link to="/scouting" className="ml-2 text-blue-500 underline">
              buying or loaning youth players on the transfer market!
            </Link>
          </>
        )}
      </div>

      <TeamPlayerPositionSelection playerContracts={club?._youthContracts} setPlayerId={setPlayerId} isYouth={true} />

      {playerId && <PlayerMain playerId={playerId} closeModal={handleCloseModal} />}
    </>
  );
};

export default ClubViewYouthPlayers;
