import database from "..";
import { faker } from "@faker-js/faker";

const stadiumSeeder = {
  tableName: "stadiums",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);

      // @ Mohsin changed this from 80 to 78 since we only have 78 clubs at this point in the game...
      for (let i = 0; i < 78; i++) {
        // @ Mohsin we need to think about how wo are going to create stadium size according to the club properties in the future
        const capacity = faker.number.int({ min: 5000, max: 70000 });
        await table.create((tb) => {
          tb.name = faker.location.city() + " Stadium";
          tb.desc = faker.lorem.sentences(6);
          tb.address = faker.location.streetAddress();
          tb.capacity = capacity;
          tb.capacitySeated = faker.number.int({ min: 5000, max: 50000 });
          tb.capacityRoofed = faker.number.int({ min: 5000, max: 50000 });
          tb.capacityHeated = faker.number.int({ min: 5000, max: 50000 });
          tb.monthlyCost = 3 * capacity; // Set monthlyCost to be x amount of capacity
        });
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default stadiumSeeder;
