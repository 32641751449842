//import { add } from "lodash";
export const en = {

  translation: {

    general: {
      alldivision: "All Divisions",
      division1: "First Division",
      division2: "Second Division",
      division3: "Third Division",
      division4: "Fourth Division",
      founded: "Founded",
      generalinfo: "General Info",
      stadiumground: "Stadium and Club Facilities overview",
      stadiumname: "Stadium Name",
      stadiumcapacity: "Stadium Capacity",
      fanbase: "Fanbase",
      reputation: "Reputation",
      youthfacilities: "Youth Facilities",
      trainingfacilities: "Training Facilities",
      trainingfields: "Training Fields",  
      transferbudget: "Transfer Budget",
      wagebudget: "Wage Budget",
      league: "League",
      league_position: "League Position",
    },

    header: {
      title: "Welcome to FootballClubBuilder!",
    },

    menu: {
      inbox: "Inbox",
      introduction: "Introduction",
      selectClub: "Select Club",
      scouting: "Scouting",
      settings: "Settings",
      credits: "Credits",
      startGame: "Start Game",
      clubInfo: "Club Info",
      clubFinances: "Club Finances",
      clubFixtures: "Club Fixtures",
      clubSquad: "Club Squad",
      clubManage: "Club Manage",
      clubYouthPlayers: "Club Youth Players",
      competitions: "Competitions",
      competitionsSecondDivision: "Second Division",
      competitionsThirdDivision: "Third Division",
      competitionsFourthDivision: "Fourth Division",
      competitionsCupCompetition: "Cup Competition",
      currencySetting: "Currency Setting",
      languageSetting: "Language Setting",
      exitGame: "Exit Game",
      enterDetails: "Enter your personal information",
      manageTeam: "Manage Team",
      myTeamTactics: "My Team Tactics",
      otherTeamTactics: "Other Team Tactics",
      selectAClub: "Please select A Club",
      selectClubToManage: "Select your club to manage",
      back: "Back",
      next: "Next",
    },

    add: {
      addManager: "Add Manager",
      selectAClub: "Please select A Club",
      select: "Select",
    },

  },
};
