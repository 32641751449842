import { Model } from "@nozbe/watermelondb";
import { field, text, relation, date } from "@nozbe/watermelondb/decorators";

class StaffContract extends Model {
  static table = "staff_contracts";

  static associations = {
    clubs: { type: "belongs_to", key: "club_id" },
    staffs: { type: "belongs_to", key: "staff_id" },
  };

  @relation("clubs", "club_id") club;
  @relation("staffs", "staff_id") staff;

  @field("monthly_wage") monthlyWage;
  @text("contract_length") contractLength;
  @date("contract_expires") contractExpires;
  @field("value") value;
  @text("happiness") happiness;
  @field("bonuses") bonuses;
}

export default StaffContract;
