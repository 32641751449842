import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClubHeader from "../helpers/ClubHeader";
import { clubsController } from "../../database/controllers";

const ClubMain = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clubId = useSelector((state) => state.club.id);
  const [club, setClub] = useState(null);

  useEffect(() => {
    if (clubId) {
      clubsController
        .findOne(clubId)
        .then((club) => {
          console.log(club);
          setClub(club);
        })
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    } else {
      navigate("/");
    }
  }, [clubId, dispatch, navigate]);

  if (!club) {
    return <div className="game-main">Loading...</div>;
  }

  return (
    <div className="game-main">
      <ClubHeader club={club} />

      <Outlet />

      <div className="bottom-menu mb-2 grid grid-cols-1 gap-1">
        <button onClick={props.onGoBack} className="system-btn">
          Back
        </button>
      </div>
    </div>
  );
};

export default ClubMain;
