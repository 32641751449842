import React, { useState, useEffect } from 'react';

const BallPosessionMechanism = () => {
  const [team1Possession, setTeam1Possession] = useState(50);
  const [team2Possession, setTeam2Possession] = useState(50);
  const [intervalCount, setIntervalCount] = useState(0);
  const [message, setMessage] = useState('');

  useEffect(() => {
    let possessionIntervalId;
  
    const updatePossession = () => {
      const team1 = Math.floor(Math.random() * 101);
      const team2 = 100 - team1;
      setTeam1Possession(team1);
      setTeam2Possession(team2);
      setIntervalCount(prevCount => prevCount + 1);
    };
  
    const startInterval = () => {
      if (intervalCount !== 18) { // Don't restart the interval if the game is finished
        possessionIntervalId = setInterval(updatePossession, 5000);
      }
    };
  
    possessionIntervalId = setInterval(() => {
      if (intervalCount === 9 || intervalCount === 18) {
        clearInterval(possessionIntervalId);
        setMessage(intervalCount === 9 ? 'Half time' : 'The game is finished!');
        setTimeout(() => {
          setMessage('');
          if (intervalCount === 9) {
            setIntervalCount(0);
            startInterval();
          }
        }, 10000); // Display message for 10 seconds
      } else {
        updatePossession();
      }
    }, 5000); // Update possession every 5 seconds
  
    return () => {
      clearInterval(possessionIntervalId);
    };
  }, [intervalCount]);

  return (
    <div className="last-5min w-full px-2 absolute bottom-2">
      <div className="w-full flex shadow rounded bg-emerald-500 py-1 px-2">
        <div className="label-text text-white w-40">Last 5 Mins</div>
        <div className="predictions w-full flex">
          <div className="club-1p rounded-l font-semibold shadow-inner text-center bg-red-400" style={{ width: `${team1Possession}%` }}>
            {team1Possession}%
          </div>
          <div className="club-2p rounded-r font-semibold shadow-inner text-center bg-blue-400" style={{ width: `${team2Possession}%` }}>
            {team2Possession}%
          </div>
        </div>
        <div>{message}</div>
      </div>
    </div>
  );
};

export default BallPosessionMechanism;