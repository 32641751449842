import moment from "moment";
import ProgressBar from "./ProgressBar";
import _ from "lodash";

const TeamPlayerPositionSelection = ({ setPlayerId, playerContracts, tacticalPositions, setTacticalPositions }) => {
  // Seperate between Youth and Senior players tactical positions to make component reusable
  const handleDragStart = (event, position) => {
    event.dataTransfer.setData("text/plain", position.id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, playerId) => {
    event.preventDefault();
    const positions = _.cloneDeep(tacticalPositions);
    const droppedPosition = event.dataTransfer.getData("text/plain");
    const prevPos = positions.find((pos) => pos.playerId === playerId);
    const nextPos = positions.find((pos) => pos.id === parseInt(droppedPosition));

    if (prevPos && nextPos) {
      const tempPlayerId = prevPos.playerId;
      prevPos.playerId = nextPos.playerId;
      nextPos.playerId = tempPlayerId;
    } else if (nextPos) {
      nextPos.playerId = playerId;
    }
    setTacticalPositions([...positions]);
  };

  const getTacticalPosition = (playerId) => {
    const position = tacticalPositions.find((pos) => pos.playerId === playerId);
    return position;
  };

  const removeTacticalPosition = (position) => {
    const positions = _.cloneDeep(tacticalPositions);
    const tPos = positions.find((pos) => pos.id === position.id);
    if (tPos) tPos.playerId = null;
    setTacticalPositions([...positions]);
  };

  const renderTacticalPosition = (playerId) => {
    let position = getTacticalPosition(playerId);
    if (!position) return null;
    return (
      <button onClick={() => removeTacticalPosition(position)} className={"field-position-btn"} draggable onDragStart={(event) => handleDragStart(event, position)}>
        {position.name}
      </button>
    );
  };

  return (
    <>
      <div className="rounded-lg bg-black/50 p-2 w-full flex justify-center">
        <div className="w-full h-full overflow-y-auto">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-x-2">
            <div className="menu-btn w-full flex items-center">
              <div className="w-2/6 px-2 text-center font-bold text-white">Pos.</div>
              <div className="w-1/6 px-2 text-center font-bold text-yellow-300">Plays</div>
              <div className="w-3/6 px-2 text-center font-bold text-white">Player Name</div>
              <div className="w-1/6 px-2 text-center font-bold text-white">Form</div>
              <div className="w-1/6 px-2 text-center font-bold text-white">Age</div>
              <div className="w-2/6 px-2 text-center font-bold text-green-600">Value</div>
            </div>
            <div className="menu-btn w-full items-center hidden md:flex">
              <div className="w-2/6 px-2 text-center font-bold text-white">Pos.</div>
              <div className="w-1/6 px-2 text-center font-bold text-yellow-300">Plays</div>
              <div className="w-3/6 px-2 text-center font-bold text-white">Player Name</div>
              <div className="w-1/6 px-2 text-center font-bold text-white">Form</div>
              <div className="w-1/6 px-2 text-center font-bold text-white">Age</div>
              <div className="w-2/6 px-2 text-center font-bold text-green-600">Value</div>
            </div>
            {/* This line is mapping over the 'Players' array of the 'club' object (if they exist), and for each contract 'ct' and its index 'i', it's executing a function (not shown here). This function will be called for each senior contract of the club. */}
            {playerContracts.map((ct, i) => (
              <div
                key={i}
                id={ct.id}
                onDrop={(event) => handleDrop(event, ct._player?.id)}
                onDragOver={handleDragOver}
                className="w-full flex border-b border-gray-500 items-center hover:bg-gray-600 hover:opacity-75 transition-colors duration-200 cursor-pointer"
              >
                <div className="w-2/6 py-2 text-center text-white rounded shadow-sm px-2">{renderTacticalPosition(ct._player?.id)}</div>
                <div onClick={() => setPlayerId(ct._player?.id)} className="w-1/6 py-2 px-2 text-center text-yellow-300">
                  {ct._player?.playingPosition}
                </div>
                <div className="w-3/6 py-2 text-left text-white" onClick={() => setPlayerId(ct._player?.id)}>
                  {ct._player?.name}
                </div>
                <div onClick={() => setPlayerId(ct._player?.id)} className="w-1/6 py-2 px-2 text-center text-white">
                  <ProgressBar form={ct._player?._personal?.form} />
                </div>
                <div onClick={() => setPlayerId(ct._player?.id)} className="w-1/6 py-2 px-2 text-center text-white">
                  {moment().diff(moment(ct._player?.birthDate), "years")}
                </div>
                <div onClick={() => setPlayerId(ct._player?.id)} className="w-2/6 py-2 px-2 text-center text-green-600">
                  $ {ct.transferValue?.toLocaleString()}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamPlayerPositionSelection;
