import { useState } from "react";
import SettingScreenImage from "../assets/images/SettingScreenImage.jpg";
import SettingsMenu from "./Settings/SettingsMenu"; //RW Changed to Material UI
import ClubScreenHeader from "../helpers/ClubScreenHeader";
import { useTranslation } from "react-i18next";

const GameSettings = () => {
  const { t, i18n } = useTranslation();
  //RW Added new state for currency and language in the new Material UI dropdown
  const [currency, setCurrency] = useState("USD");

  //RW Added new functions to handle the change in the new Material UI dropdown
  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage); // Change the language of i18next
  };

  return (
    <div
      className="game-main"
      style={{
        backgroundImage: `url(${SettingScreenImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <ClubScreenHeader title="Football Club Builder" />

      <div className="board-main p-2 mb-2 rounded bg-black/30 flex items-center justify-center" style={{ height: "100vh" }}>
        {/* <div className="board-main p-2 mb-2 rounded bg-black/30"> */}
        <div className="w-full overflow-y-auto text-white text-center">
          <SettingsMenu
            labelText="Currency"
            buttonText={t('menu.currencySetting')}
            selectOptions={[
              { value: "USD", label: "USD" },
              { value: "EURO", label: "EURO" },
              { value: "POUND", label: "POUND" },
            ]}
            selectedValue={currency}
            onSelectChange={handleCurrencyChange}
          />

          <SettingsMenu
            labelText="Language"
            buttonText={t('menu.languageSetting')}
            // buttonText="Language Setting"
            selectOptions={[
              { value: "en", label: "English" },
              { value: "fr", label: "French" },
              { value: "de", label: "German" },
              { value: "nl", label: "Dutch" },
              { value: "es", label: "Spanish" },
            ]}
            selectedValue={i18n.language}
            onSelectChange={handleLanguageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default GameSettings;
