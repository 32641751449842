import moment from "moment";
import DefenderIcon from "../assets/images/icons/shield-defender.svg";
import GoalkeeperIcon from "../assets/images/icons/goalkeeper-icon.svg";
import AttackerIcon from "../assets/images/icons/attacker-icon.svg";
import MidfielderIcon from "../assets/images/icons/midfielder-icon.svg";
import { useState } from "react";
import createStarRating from "../helpers/starRating";
import ProgressBar from "../helpers/ProgressBar";
import PlayerImageGenerator from "./PlayerImageGenerator";

const Profile = ({ player }) => {
  const [hoveredField, setHoveredField] = useState(null);
  const fieldStats = {
    // Define which stats belong to which field
    defender: ["marking", "pace", "tackling", "headers", "stamina", "strength", "workrate"],
    midfielder: ["creativity", "crossing", "dribbling", "passing", "set_pieces", "teamworking", "technique", "long_shots", "stamina", "workrate"],
    attacker: ["finishing", "pace", "shooting", "long_shots", "dribbling", "technique", "teamworking"],
    goalkeeper: ["reactions", "reflexes", "positioning", "handling", "dandling", "set_pieces", "teamworking"],
  };
  // Determine what to show in the Football Playing Skills section
  const skillKeys = [
    "acceleration",
    "creativity",
    "crossing",
    "dribbling",
    "finishing",
    "headers",
    "dandling",
    "long_shots",
    "marking",
    "pace",
    "passing",
    "positioning",
    "reactions",
    "reflexes",
    "set_pieces",
    "shooting",
    "stamina",
    "strength",
    "tackling",
    "teamworking",
    "technique",
    "workrate",
  ];

  const renderSkills = (player) => {
    return Object.entries(player._skills._raw)
      .filter(([key]) => skillKeys.includes(key.toLowerCase()))
      .map(([key, value], index) => {
        if (typeof value !== "number" || value < 0) {
          console.error(`Invalid skill value for ${key}: ${value}`);
          return null;
        }
        const { stars } = createStarRating(value);
        return (
          <div
            key={index}
            className={`border-b border-gray-500 flex justify-between ${fieldStats[hoveredField]?.includes(key) ? "bg-yellow-500" : ""} ${index % 2 === 0 ? "bg-gray-200" : ""}`}
          >
            <div className="text-black font-bold capitalize px-4 py-2">{key.split(/_/).join(" ")}</div>
            <div className="font-semibold flex px-4 py-2">
              {stars.map((star, index) => (
                <img key={index} src={star} alt="Star" />
              ))}
            </div>
          </div>
        );
      });
  };

  return (
    <div className="board-main mb-2 rounded bg-gray-100">
      {/* added this section to start coding some personal data like form, nationality, etc*/}

      {/* here started a new section that should reflect player's status at the club */}
      <div className="boxHeader">Player's Club Status</div>
      <div className="ml-2 mr-2 grid grid-cols-12 gap-2">
        {/* Top left image */}
        <div className="col-span-2 flex items-center justify-center">
          <PlayerImageGenerator imageName={player?.imageName || "defaultImageName"} />
          {/* <img src={Playerhead} alt="Profile" className="w-24 h-24 rounded-full" /> */}
        </div>

        {/* Two-column layout for strings UPDATED  */}
        <div className="col-span-10 grid grid-cols-2 gap-2">
          <div className="font-bold text-lg flex items-center space-x-2 hover:bg-gray-200 p-2 rounded">
            <span>Squad Status:</span>
            <span>{player.squadStatus}</span>
          </div>
          <div className="font-bold text-lg flex items-center space-x-2 hover:bg-gray-200 p-2 rounded">
            <span>Transfer Value:</span>
            <span className="text-green-600">$ {player._contract?.transferValue.toLocaleString()}</span>
          </div>
          <div className="font-bold text-lg flex items-center space-x-2 hover:bg-gray-200 p-2 rounded">
            <span>Contract Expires:</span>
            <span>{moment(player._contract?.contractExpires).format("ll")}</span>
          </div>
          {/* Add more fields as needed */}
        </div>
      </div>

      <div className="boxHeader">
        Personality - Born {moment(player.birthDate).format("D.MM.YYYY")} (Age {moment().diff(moment(player.birthDate), "years")})
      </div>

      <div className="grid grid-cols-2 gap-4 ml-2 mr-2">
        {Object.entries({ playingPosition: player.playingPosition, nationality: player.nationality, ...player._personal?._row }).map(([key, value], index) => {
          const lowerCaseKey = key.toLowerCase();
          // Skip certain keys and values from PlayerPersonal in the display
          if (lowerCaseKey === "id" || lowerCaseKey === "playerid" || lowerCaseKey === "updatedat" || lowerCaseKey === "createdat") {
            return null;
          }

          let displayValue = value;
          // Show star ratings for leadership and experience
          if (lowerCaseKey === "leadership" || lowerCaseKey === "experience") {
            const { stars } = createStarRating(value);
            displayValue = (
              <div className="font-semibold flex">
                {stars.map((star, index) => (
                  <img key={index} src={star} alt="Star" />
                ))}
              </div>
            );
          }

          return (
            <div key={index} className={`border-b border-gray-500 flex justify-between`}>
              <div className="text-black font-bold capitalize">{key.split(/(?=[A-Z])/).join(" ")}</div>
              {lowerCaseKey === "form" ? (
                <div className={`flex justify-between`}>
                  <ProgressBar form={value} />
                </div>
              ) : (
                displayValue
              )}
            </div>
          );
        })}
      </div>

      <div className="boxHeader">Football Playing Skills</div>

      <div className="ml-2 mr-2 grid grid-cols-2 gap-2">
        <div
          className="font-bold text-lg flex items-center space-x-2 hover:bg-gray-200 p-2 rounded"
          onMouseEnter={() => setHoveredField("goalkeeper")}
          onMouseLeave={() => setHoveredField(null)}
        >
          <img src={GoalkeeperIcon} alt="Goalkeeper Icon" className="w-7 h-7" />
          <span>Goalkeeper Skills</span>
        </div>
        <div
          className="font-bold text-lg flex items-center space-x-2 hover:bg-gray-200 p-2 rounded"
          onMouseEnter={() => setHoveredField("defender")}
          onMouseLeave={() => setHoveredField(null)}
        >
          <img src={DefenderIcon} alt="Defender Icon" className="w-7 h-7" />
          <span>Defender Skills</span>
        </div>
        <div
          className="font-bold text-lg flex items-center space-x-2 hover:bg-gray-200 p-2 rounded"
          onMouseEnter={() => setHoveredField("midfielder")}
          onMouseLeave={() => setHoveredField(null)}
        >
          <img src={MidfielderIcon} alt="Midfielder Icon" className="w-7 h-7" />
          <span>Midfielder Skills</span>
        </div>
        <div
          className="font-bold text-lg flex items-center space-x-2 hover:bg-gray-200 p-2 rounded"
          onMouseEnter={() => setHoveredField("attacker")}
          onMouseLeave={() => setHoveredField(null)}
        >
          <img src={AttackerIcon} alt="Attacker Icon" className="w-7 h-7" />
          <span>Attacker Skills</span>
        </div>
        <hr className="col-span-2 border-black" />
      </div>

      <div className="ml-2 mr-2 grid grid-cols-2 gap-2">
        {/* BEgin of the section that displays the player's skills. */}
        {/* <div className="grid grid-cols-2 gap-2"></div> */}
        {renderSkills(player)}
      </div>
    </div>
  );
};

export default Profile;
