import ClubScreenHeader from "../helpers/ClubScreenHeader";

const ClubHeader = ({ club, selectedTab, setSelectedTab }) => {
  const tabs = [
    { name: "Club Info", to: "Info" },
    { name: "Finances", to: "Finances" },
    { name: "Fixtures", to: "Fixtures" },
    { name: "Squad", to: "Squad" },
    { name: "Youth", to: "Youth" },
    // { name: "Manage", to: "Manage" },
  ];

  const getTabName = (to) => {
    const tab = tabs.find((tab) => tab.to === to);
    return tab?.name || "";
  };

  return (
    <>
      <ClubScreenHeader title={`${getTabName(selectedTab)} - ${club.name}`} />

      <div className="mb-2 w-full grid sm:grid-cols-1 md:grid-cols-5 gap-2">
        {tabs.map((tab, index) => (
          <div key={index} className="font-semibold text-center">
            <button onClick={() => setSelectedTab(tab.to)} className={`block teamscreen-btn ${selectedTab === tab.to ? "teamscreen-btn-active" : ""}`}>
              {tab.name}
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default ClubHeader;
