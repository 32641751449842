import { Model, Q } from "@nozbe/watermelondb";
import { field, text, children, relation, date, lazy } from "@nozbe/watermelondb/decorators";

class Club extends Model {
  static table = "clubs";

  static associations = {
    stadiums: { type: "belongs_to", key: "stadium_id" },
    club_finances: { type: "has_many", foreignKey: "club_id" },
    participants: { type: "has_many", foreignKey: "club_id" },
    staff_contracts: { type: "has_many", foreignKey: "club_id" },
    player_contracts: { type: "has_many", foreignKey: "club_id" },
  };

  @relation("stadiums", "stadium_id") stadium;
  @children("club_finances") finances;
  @children("participants") participants;
  @children("staff_contracts") staffContracts;
  @children("player_contracts") playerContracts;

  @text("name") name;
  @field("founded") founded;
  @field("fanbase") fanbase;
  @text("address") address;
  @text("reputation") reputation;
  @text("youth_facilities") youthFacilities;
  @text("training_facilities") trainingFacilities;
  @field("transfer_budget") transferBudget;
  @field("wage_budget") wageBudget;

  // @lazy
  // finance = this.collections.get("club_finances").query(Q.where("club_id", this.id));
}

export default Club;
