import { useState } from "react";

const MatchStats = () => {
   const [stats, setStats] = useState({
      ShotsOnGoal: {
         name: "Shots On Goal",
         color: "#fff",
         valueIn: "int",
         values: [0, 0],
      },
      OnTarget: {
         name: "On Target",
         color: "#fff",
         valueIn: "int",
         values: [0, 0],
      },
      OffTarget: {
         name: "Off Target",
         color: "#fff",
         valueIn: "int",
         values: [0, 0],
      },
      Corners: {
         name: "Corners",
         color: "#fff",
         valueIn: "int",
         values: [0, 0],
      },
      FreeKicks: {
         name: "Free Kicks",
         color: "#fff",
         valueIn: "int",
         values: [0, 0],
      },
      ThrowIns: {
         name: "Throw Ins",
         color: "#fff",
         valueIn: "int",
         values: [0, 0],
      },
      Fouls: {
         name: "Fouls",
         color: "#fff",
         valueIn: "int",
         values: [0, 0],
      },
      Offsides: {
         name: "Offsides",
         color: "#fff",
         valueIn: "int",
         values: [0, 0],
      },
      PassedCompleted: {
         name: "Passed Completed",
         color: "#fff",
         valueIn: "pct",
         values: [0, 0],
      },
      TacklesWon: {
         name: "Tackles Won",
         color: "#fff",
         valueIn: "pct",
         values: [0, 0],
      },
      HeadersWon: {
         name: "Headers Won",
         color: "#fff",
         valueIn: "pct",
         values: [0, 0],
      },
      YellowCards: {
         name: "Yellow Cards",
         color: "#ff0",
         valueIn: "int",
         values: [0, 0],
      },
      RedCards: {
         name: "Red Cards",
         color: "#f00",
         valueIn: "int",
         values: [0, 0],
      },
   });

   return (
      <div className="board-main p-2 mb-2 rounded bg-black/30">
         <div className="game-box">
            {Object.values(stats).map((data, i) => (
               <div key={i} className="w-full flex justify-between font-semibold mb-1">
                  <button className="w-20 text-white border px-3 rounded-sm">
                     {data.values[0]}
                     {data.valueIn === "pct" ? "%" : ""}
                  </button>
                  <div className="text-white text-lg" style={{ color: data.color }}>
                     {data.name}
                  </div>
                  <button className="w-20 text-white border px-3 rounded-sm">
                     {data.values[1]}
                     {data.valueIn === "pct" ? "%" : ""}
                  </button>
               </div>
            ))}
         </div>
      </div>
   );
};

export default MatchStats;
