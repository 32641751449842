import { Database } from "@nozbe/watermelondb";
import LokiJSAdapter from "@nozbe/watermelondb/adapters/lokijs";

import schema from "./schema";
import Manager from "./models/Manager";
import Stadium from "./models/Stadium";
import Club from "./models/Club";
import ClubFinance from "./models/ClubFinance";
import Player from "./models/Player";
import PlayerSkill from "./models/PlayerSkill";
import PlayerPersonal from "./models/PlayerPersonal";
import PlayerContract from "./models/PlayerContract";
import Staff from "./models/Staff";
import StaffContract from "./models/StaffContract";
import Competition from "./models/Competition";
import Division from "./models/Division";
import Participant from "./models/Participant";
import Round from "./models/Round";
import Match from "./models/Match";

const adapter = new LokiJSAdapter({
  dbName: "fcb",
  schema,
  // (You might want to comment out migrations for development purposes -- see Migrations documentation)
  // migrations,
  useWebWorker: false,
  useIncrementalIndexedDB: true,

  // --- Optional, but recommended event handlers:
  onQuotaExceededError: (error) => {
    // Browser ran out of disk space -- offer the user to reload the app or log out
  },
  onSetUpError: (error) => {
    // Database failed to load -- offer the user to reload the app or log out
  },
  extraIncrementalIDBOptions: {
    onDidOverwrite: () => {
      // Called when this adapter is forced to overwrite contents of IndexedDB.
      // This happens if there's another open tab of the same app that's making changes.
      // Try to synchronize the app now, and if user is offline, alert them that if they close this
      // tab, some data may be lost
    },
    onversionchange: () => {
      // database was deleted in another browser tab (user logged out), so we must make sure we delete
      // it in this tab as well - usually best to just refresh the page
      // if (checkIfUserIsLoggedIn()) {
      //   window.location.reload();
      // }
    },
  },
});

// Then, make a Watermelon database from it!
const database = new Database({
  adapter,
  modelClasses: [Manager, Stadium, Club, ClubFinance, Player, PlayerSkill, PlayerPersonal, PlayerContract, Staff, StaffContract, Competition, Division, Participant, Round, Match],
});

export default database;
