import { Model } from "@nozbe/watermelondb";
import { field, text, relation, date } from "@nozbe/watermelondb/decorators";

class PlayerPersonal extends Model {
  static table = "player_personals";

  static associations = {
    players: { type: "belongs_to", key: "player_id" },
  };

  @relation("players", "player_id") player;

  @field("form") form;
  @text("professionalism") professionalism;
  @text("sportsmanship") sportsmanship;
  @text("temperament") temperament;
  @field("experience") experience;
  @field("leadership") leadership;
  @text("morale") morale;
  @text("prefered_foot") preferedFoot;
  @text("prone_to_injuries") proneToInjuries;
}

export default PlayerPersonal;
