import database from "..";
import { faker } from "@faker-js/faker";
import { Q } from "@nozbe/watermelondb";

const youthPersonalSeeder = {
  tableName: "player_personals",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);
      const players = await database.get("players").query(Q.where("is_youth", true)).fetch();

      for (let i = 0; i < players.length; i++) {
        await table.create((tb) => {
          tb.player.set(players[i]);
          // form: faker.helpers.arrayElement(["lowest", "low", "medium", "okay", "high", "highest"]
          tb.form = Math.floor(Math.random() * (90 - 59 + 1)) + 59; // create a random number between 59 and 90 for form
          // professionalism: faker.random.arrayElement(['lowest', 'low', 'medium', 'okay', 'high', 'highest'])
          tb.professionalism = faker.helpers.arrayElement(["lowest", "low", "medium", "okay", "high", "highest"]);
          tb.sportmanship = faker.helpers.arrayElement(["lowest", "low", "medium", "okay", "high", "highest"]);
          tb.temperament = faker.helpers.arrayElement(["lowest", "low", "medium", "okay", "high", "explosive"]);
          tb.experience = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.leadership = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.morale = faker.helpers.arrayElement(["lowest", "low", "medium", "okay", "high", "highest"]);
          tb.preferedFoot = faker.helpers.arrayElement(["left", "right"]);
          tb.Pronetoinjuries = faker.helpers.arrayElement(["low", "medium", "high", "Made of glass"]);
        });
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default youthPersonalSeeder;
