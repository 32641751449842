import { useState, useEffect } from "react";
import BallPosessionMechanism from "./BallPosessionMechanism";

export const events = [
  "Hutchison looks to go past",
  "Opponent team counters quickly, and yeah it's a goal!",
  "Great save by the goalkeeper",
  "Close miss by the striker",
  "Fierce tackle in the midfield",
  "Referee awards a free kick",
  "Corner kick for the home team, and there comes their mighty air force....",
  "It's a goal for the home team, great cross from $playername and a excellent header goal for $Player!",
  "Shot from distance, But it is miles over the goal!!",
  "The striker is through on goal, but the goalkeeper comes out and saves it!",
  "$Playername is pissed at the referee and receives yellow card for his outburst!",
  "Yellow card for the defender",
  "Substitution for the home team",
  "Corner kick for the away team, it's a goal for the opponent team!",
  "The referee blows the whistle for half time",
  "Great tackle by the defender! Ballpossession for the home team",
  "The referee blows the final whistle",
  "The away team is dominating the game",
  "The home team is dominating the game",
  "Oooh! What a absolute screamer from the striker, It's a goal for the home team!",
  "It's a goal! The away team is passing the ball around the midfield and the striker finds the back of the net!",
];

const MatchOverview = () => {
  const [currentEvent, setCurrentEvent] = useState('Match actions will show here once we kick off the match....');
  const [goalEvents, setGoalEvents] = useState([]);
  const [opponentGoalEvents, setOpponentGoalEvents] = useState([]);

  useEffect(() => {
    const generateEvent = () => {
      const randomIndex = Math.floor(Math.random() * events.length);
      const event = events[randomIndex];
      setCurrentEvent(event);

      if ((event.includes("home") || event.includes("home team")) && event.includes("goal")) {
        setGoalEvents(prevEvents => [...prevEvents, event]);
      }

      if ((event.includes("opponent") || event.includes("away team")) && event.includes("goal")) {
        setOpponentGoalEvents(prevEvents => [...prevEvents, event]);
      }
    };

    const intervalId = setInterval(generateEvent, 5000); // Change event every 5 seconds

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="board-main p-2 mb-2 rounded bg-black/20">
      <div className="game-status mb-2 bg-black/50 text-center font-bold text-yellow-400 py-2">
        $Divisionname matchday!
      </div>
      <div className="date-status mb-2 flex justify-between">
        <div className="day-date bg-black/50 text-blue-300 px-2 py-1">Saturday 18th August 2024</div>
        <div className="match-stats bg-black/50 text-blue-300 px-2 py-1">$Division/HT 1-1</div>
      </div>
      <div className="game-box mb-2 bg-black/50 relative py-2">
        <div className="goal-detail grid grid-cols-2 gap-2">
          <div className="club1-goals px-2 h-56 overflow-y-auto">
            {goalEvents.map((event, index) => (
              <div key={index} className="goal-item flex justify-between">
                <div className="goaler font-semibold text-white">Home team scored</div>
                <div className="goal-time font-semibold text-yellow-400">Time TBD</div>
              </div>
            ))}
          </div>
          <div className="club2-goals px-2 h-72 overflow-y-auto">
            {opponentGoalEvents.map((event, index) => (
              <div key={index} className="goal-item flex justify-between">
                <div className="goaler font-semibold text-white">Opponent scored</div>
                <div className="goal-time font-semibold text-yellow-400">Time TBD</div>
              </div>
            ))}
          </div>
        </div>
        
        <div className="absolute bottom-14 w-full z-10 flex items-center justify-center">
          <div className="runtime-status shadow mx-2 rounded bg-emerald-500 py-1 px-4 text-lg text-white font-semibold text-center">
            {currentEvent}
          </div>
        </div>

        <BallPosessionMechanism />
      </div>
      <div className="stuf-box flex bg-black/50">
        <div className="w-full h-full flex justify-center items-center text-yellow-400 p-1 rounded shadow">Referee - $Referee Name</div>
        <div className="w-full h-full flex justify-center items-center text-yellow-400 p-1 rounded shadow">$StadiumName (Stadium.Capacity) Attendance - 34994</div>
        <div className="w-full h-full flex justify-center items-center text-yellow-400 p-1 rounded shadow">Weather - Dry, 28c</div>
      </div>
    </div>
  );
};

export default MatchOverview;