import { Model } from "@nozbe/watermelondb";
import { field, text, relation, date, children } from "@nozbe/watermelondb/decorators";

class Round extends Model {
  static table = "rounds";

  static associations = {
    matches: { type: "has_many", foreignKey: "round_id" },
    divisions: { type: "belongs_to", key: "division_id" },
  };

  @children("matches") matches;
  @relation("divisions", "division_id") division;

  @field("round_no") roundNo;
  @text("round_type") roundType;
}

export default Round;
