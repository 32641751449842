import database from "..";
import { Q } from "@nozbe/watermelondb";

class PlayersController {
  tableName = "players";

  index = async (page = 1, perPage = 20, keywords = "") => {
    let skip = 0;
    let take = perPage;
    let search = "%%";
    if (page > 1) {
      skip = (page - 1) * take;
    }
    if (keywords) {
      search = "%" + keywords + "%";
    }
    const table = database.get(this.tableName);
    const players = await table.query(Q.where("name", Q.like(search)), Q.sortBy("name", Q.asc), Q.skip(skip), Q.take(take)).fetch();
    const totalPlayers = await table.query(Q.where("name", Q.like(search))).fetchCount();

    for (const player of players) {
      const [_skills] = await player.skills.fetch();
      if (_skills) {
        player._skills = _skills;
      }
      const [_personal] = await player.personals.fetch();
      if (_personal) {
        player._personal = _personal;
      }
      const [_contract] = await player.contracts.fetch();
      if (_contract) {
        const _club = await _contract.club.fetch();
        if (_club) {
          _contract._club = _club;
        }
        player._contract = _contract;
      }
    }
    return { players, totalPlayers };
  };

  findOne = async (id) => {
    const table = database.get(this.tableName);
    const player = await table.find(id);
    const [_skills] = await player.skills.fetch();
    if (_skills) {
      player._skills = _skills;
    }
    const [_personal] = await player.personals.fetch();
    console.log(_personal);
    if (_personal) {
      player._personal = _personal;
    }
    const [_contract] = await player.contracts.fetch();
    if (_contract) {
      const _club = await _contract.club.fetch();
      if (_club) {
        _contract._club = _club;
      }
      player._contract = _contract;
    }
    return player;
  };

  seniorPlayers = async () => {
    const table = database.get(this.tableName);
    const players = await table.query(Q.where("is_youth", false)).fetch();
    return players;
  };

  youthPlayers = async () => {
    const table = database.get(this.tableName);
    const players = await table.query(Q.where("is_youth", true)).fetch();
    return players;
  };

  // findOne = async (req, res) => {
  //   try {
  //     const player = await Player.findByPk(req.params.id, { include: [PlayerSkills, PlayerPersonal, { model: PlayerContract, include: [Club] }] });
  //     return res.send({ player });
  //   } catch (err) {
  //     console.log(err);
  //     return res.status(500).send({ message: err });
  //   }
  // };
}

export default new PlayersController();
