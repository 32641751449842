// import { exit } from "process";
export const es = {
  translation: {

    general: {
      alldivision: "Todas las divisiones",
      division1: "Primera división",
      division2: "Segunda división",
      division3: "Tercera división",
      division4: "Cuarta división",
      founded: "Fundado",
      generalinfo: "Información general",
      stadiumground: "Vista general del estadio",
      stadiumname: "Nombre del estadio",
      stadiumcapacity: "Capacidad del estadio",
      fanbase: "Base de fans",
      reputation: "Reputación",
      youthfacilities: "Instalaciones juveniles",
      trainingfacilities: "Instalaciones de entrenamiento",
      trainingfields: "Campos de entrenamiento",
      transferbudget: "Presupuesto de transferencia",
      wagebudget: "Presupuesto de salarios",
      league: "Liga",
      league_position: "Posición en la liga",
    },

    header: {
      title: "¡Bienvenido a FootballClubBuilder!",
    },

    menu: {
      inbox: "Mensajes",
      introduction: "Introducción",
      selectClub: "Seleccionar club",
      scouting: "Exploración",
      settings: "Configuración",
      credits: "Créditos",
      startGame: "Iniciar juego",
      clubInfo: "Información del club",
      clubFinances: "Finanzas del club",
      clubFixtures: "Calendario del club",
      clubSquad: "Equipo del club",
      clubManage: "Gestionar club",
      clubYouthPlayers: "Jugadores juveniles del club",
      competitions: "Competiciones",
      competitionsSecondDivision: "Segunda división",
      competitionsThirdDivision: "Tercera división",
      competitionsFourthDivision: "Cuarta división",
      competitionsCupCompetition: "Competición de copa",
      currencySetting: "Configuración de moneda",
      languageSetting: "Configuración de idioma",
      exitGame: "Salir del juego",
      enterDetails: "Ingrese su información personal",
      manageTeam: "Gestionar equipo",
      selectClubToManage: "Seleccione su club para gestionar",
      myTeamTactics: "Tácticas de mi equipo",
      otherTeamTactics: "Tácticas del otro equipo",
      back: "Atrás",
      next: "Siguiente",
    },

    add: {
      addManager: "Añadir manager",
      selectAClub: "Por favor, seleccione un club",
      select: "Seleccionar",
    },
  },
};