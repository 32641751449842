// History will display the player's current season stats and all the past season stats... This will include the division, season, club, matches played, and goals scored.

const History = ({ player }) => {
  return (
    <>
      <div className="font-bold">Player Season Stats</div>
      <table className="w-full border-collapse border border-gray-400 mt-4">
        <thead className="bg-gray-400">
          <tr>
            <th className="border border-gray-400 px-4 py-2">Division</th>
            <th className="border border-gray-400 px-4 py-2">Season</th>
            <th className="border border-gray-400 px-4 py-2">Club</th>
            <th className="border border-gray-400 px-4 py-2">Matches Played</th>
            <th className="border border-gray-400 px-4 py-2">Goals Scored</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Division 1??</td>
            <td className="border border-gray-400 px-4 py-2">2024</td>
            <td className="border border-gray-400 px-4 py-2">{player._contract?._club?.name}</td>
            <td className="border border-gray-400 px-4 py-2">0</td>
            <td className="border border-gray-400 px-4 py-2">0</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default History;
