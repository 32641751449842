import database from "..";
import { faker } from "@faker-js/faker";
import { Q } from "@nozbe/watermelondb";

const playerContractSeeder = {
  tableName: "player_contracts",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);

      const minTransferValue = 600000;
      const maxTransferValue = 25000000;
      const power = 5; // You can adjust this value for more or less skew

      // Generate a random index and some example bonus options for contracts
      const contractBonusOptions = ["Signing Bonus", "Goal Bonus", "Assist Bonus", "Clean Sheet Bonus", "Appearance Bonus", "Win Bonus", "Promotion Bonus", "Relegation Bonus"];
      const randomBonusIndex = Math.floor(Math.random() * contractBonusOptions.length);
      const contractBonus = contractBonusOptions[randomBonusIndex]; // Use the random index to get a random contract bonus
      // Generate a random index and some example bonus options for contracts

      function skewedRandom(min, max, power) {
        const u = Math.random(); // Uniform random number between 0 and 1
        const skewed = Math.pow(u, power); // Apply power transformation to skew
        return min + (max - min) * skewed;
      }

      const clubs = await database.get("clubs").query().fetch();
      const players = await database.get("players").query(Q.where("is_youth", false)).fetch();

      for (let i = 0; i < clubs.length; i++) {
        const playersCount = faker.number.int({ min: 20, max: 26 });
        const playersInClub = players.splice(0, playersCount);
        for (let j = 0; j < playersInClub.length; j++) {
          const transferValue = skewedRandom(minTransferValue, maxTransferValue, power);
          //fixed it to 1% for now    Define a percentage for the monthly wage (e.g., between 0.5% and 1.5% of the transfer value)
          const percentage = 0.01;
          const monthlyWage = Math.floor(transferValue * percentage);
          const contractLengthYears = faker.number.int({ min: 1, max: 5 }); // Determine contract length in years

          await table.create((tb) => {
            tb.club.set(clubs[i]);
            tb.player.set(playersInClub[j]);
            tb.contractBonus = contractBonus; // Add a random bonus option to the contract for now
            tb.contractLength = `${contractLengthYears} Year`;
            tb.contractExpires = new Date(new Date().setFullYear(new Date().getFullYear() + contractLengthYears)).toISOString().split("T")[0]; // Calculate expiration date
            tb.transferValue = transferValue;
            tb.happiness = "Happy";
            tb.onTransferList = false;
            tb.onLoanList = false;
            tb.isYouth = false;
            tb.isCaptain = false;
            tb.monthlyWage = monthlyWage;
          });
        }
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default playerContractSeeder;
