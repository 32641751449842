import database from "..";
import { faker } from "@faker-js/faker";

const chairmanSeeder = {
  tableName: "staffs",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);
      const clubs = await database.get("clubs").query().fetch();

      for (let i = 0; i < clubs.length; i++) {
        await table.create((tb) => {
          tb.name = faker.person.fullName();
          tb.role = "chairman";
          tb.birthDate = faker.date.between({
            //they are older 40 - 58 years old
            from: new Date(new Date().setFullYear(new Date().getFullYear() - 58)),
            to: new Date(new Date().setFullYear(new Date().getFullYear() - 40)),
          });
          tb.experience = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.communication = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.nationality = faker.location.country();
        });
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default chairmanSeeder;
