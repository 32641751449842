import { useSelector } from "react-redux";

const StaffHistory = ({ staff }) => {
  return (
    <>
      <div className="text-center text-lg font-bold">This staff member has the following history of clubs:</div>
      <table className="w-full border-collapse border border-gray-400 mt-4">
        <thead className="bg-gray-400">
          <tr>
            <th className="border border-gray-400 px-4 py-2">Division</th>
            <th className="border border-gray-400 px-4 py-2">Season</th>
            <th className="border border-gray-400 px-4 py-2">Club</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-400 px-4 py-2">Division 1?? WIP</td>
            <td className="border border-gray-400 px-4 py-2">2024</td>
            <td className="border border-gray-400 px-4 py-2">{staff._contract?._club?.name}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default StaffHistory;
