import { useState } from "react";

const ActionZones = () => {
  return (
    <div className="board-main p-2 mb-2 rounded bg-black/30">
      <div className="flex justify-center w-full">
        <div className="w-full mb-2" style={{ width: 600 }}>
          <div className="w-full mb-2 relative" style={{ height: 400 }}>
            <img className="w-full h-full" src={require("../assets/images/field-map.jpg")} alt="" />
            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center py-8">
              <div className="border-r border-dashed border-yellow-400 w-full h-full px-3">
                <div className="h-full flex items-center justify-center">
                  <div className="text-yellow-400 text-2xl font-semibold">16%</div>
                </div>
                <div className="w-full flex shadow rounded bg-emerald-500 py-1 px-1">
                  <div className="predictions w-full flex">
                    <div className="club-1p rounded-l font-semibold shadow-inner text-center bg-red-400 h-3" style={{ width: "62%" }}></div>
                    <div className="club-2p rounded-r font-semibold shadow-inner text-center bg-blue-400 h-3" style={{ width: "38%" }}></div>
                  </div>
                </div>
              </div>
              <div className="w-full h-full px-3">
                <div className="h-full flex items-center justify-center">
                  <div className="text-yellow-400 text-2xl font-semibold">56%</div>
                </div>
                <div className="w-full flex shadow rounded bg-emerald-500 py-1 px-1">
                  <div className="predictions w-full flex">
                    <div className="club-1p rounded-l font-semibold shadow-inner text-center bg-red-400 h-3" style={{ width: "62%" }}></div>
                    <div className="club-2p rounded-r font-semibold shadow-inner text-center bg-blue-400 h-3" style={{ width: "38%" }}></div>
                  </div>
                </div>
              </div>
              <div className="border-l border-dashed border-yellow-400 w-full h-full px-3">
                <div className="h-full flex items-center justify-center">
                  <div className="text-yellow-400 text-2xl font-semibold">36%</div>
                </div>
                <div className="w-full flex shadow rounded bg-emerald-500 py-1 px-1">
                  <div className="predictions w-full flex">
                    <div className="club-1p rounded-l font-semibold shadow-inner text-center bg-red-400 h-3" style={{ width: "62%" }}></div>
                    <div className="club-2p rounded-r font-semibold shadow-inner text-center bg-blue-400 h-3" style={{ width: "38%" }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex shadow rounded bg-emerald-500 py-1 px-2">
            <div className="predictions w-full flex">
              <div className="club-1p rounded-l font-semibold shadow-inner text-center bg-red-400" style={{ width: "62%" }}>
                62%
              </div>
              <div className="club-2p rounded-r font-semibold shadow-inner text-center bg-blue-400" style={{ width: "38%" }}>
                38%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionZones;
