import { Link } from "react-router-dom";
import IntroductionBackgroundImage from "../assets/images/IntroductionBackgroundImage.jpg";
import ClubScreenHeader from "../helpers/ClubScreenHeader";
import { useTranslation } from "react-i18next";


const Introduction = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className="game-main"
      style={{
        backgroundImage: `url(${IntroductionBackgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <ClubScreenHeader title={t('header.title')} />
      {/* <ClubScreenHeader title="Welcome to FootballClubBuilder!" /> */}

      <div className="game-status">
        Welcome aboard, lets start your Football Manager Experience
      </div>

      <div className="text-center text-white board-main p-2 mb-2 rounded bg-black/50">
        <h1 className="text-4xl font-bold mb-4">
          Welcome to Football Club Builder!
        </h1>
        <p className="text-lg mb-4">
          Step into the shoes of a football manager and lead your team to
          victory!
        </p>
        <p className="text-lg mb-4">
          In this game, you'll be able to manage your own football club. You'll
          be responsible for everything from training players, managing
          finances, to setting up strategies for matches.
        </p>
        <p className="text-lg mb-4">
          You'll face challenges, make tough decisions, and experience the
          thrill of victory. Your choices will determine the success of your
          club.
        </p>
        <p className="font-bold text-lg mb-4">
          Are you ready to embark on this exciting journey? Let's get started by
          clicking the Add Manager button!
        </p>
      </div>

      <div className="bottom-menu mb-2 grid grid-cols-1 gap-1">
        <Link to="/add-manager" className="game-status rounded-xl">{t("add.addManager")}</Link>
      </div>
    </div>
  );
};

export default Introduction;