import database from "..";
import { faker } from "@faker-js/faker";
import { Q } from "@nozbe/watermelondb";

const youthSkillSeeder = {
  tableName: "player_skills",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);
      const players = await database.get("players").query(Q.where("is_youth", true)).fetch();

      for (let i = 0; i < players.length; i++) {
        await table.create((tb) => {
          tb.player.set(players[i]);
          tb.acceleration = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.creativity = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.crossing = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.dribbling = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.finishing = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.headers = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.dandling = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.longShots = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.marking = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.pace = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.passing = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.reactions = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.positioning = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.reflexes = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.setPieces = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.shooting = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.stamina = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.strength = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.tackling = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.teamworking = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.technique = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
          tb.workrate = parseFloat(faker.number.float({ min: 1, max: 10, multipleOf: 0.01 }).toFixed(2));
        });
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default youthSkillSeeder;
