const Transfer = ({ player }) => {
  // Example data: player's transfer value, interested clubs, and happiness to stay
  const interestedClubs = ["Real Matchit", "Manchester", "Barcelona"];

  return (
    <>
      <div className="font-bold">Player Transfer Details:</div>

      <div className="mt-2">
        <div className="font-bold">On Transferlist?</div>
        <div className="text-xl mt-2">{player._contract?.onTransferList ? "Yes" : "No"}</div>
      </div>
      <div className="mt-2">
        <div className="font-bold">On Loanlist?</div>
        <div className="text-xl mt-2">{player._contract?.onLoanList ? "Yes" : "No"}</div>
      </div>
      <div className="mt-2">
        <div className="font-bold">Transfer Value:</div>
        <div className="text-xl mt-2">${player._contract?.transferValue.toLocaleString()}</div>
      </div>
      <div className="mt-4">
        <div className="font-bold">Interested Clubs:</div>
        <ul className="list-disc ml-8">
          {interestedClubs.map((club, index) => (
            <li key={index}>{club}</li>
          ))}
        </ul>
      </div>
      <div className="mt-4">
        <div className="font-bold">Happiness to Stay:</div>
        <div className="text-xl mt-2 capitalize">{player._contract?.happiness}</div>
      </div>
    </>
  );
};

export default Transfer;
