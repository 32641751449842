import database from "..";
import { Q } from "@nozbe/watermelondb";

// Defining the ClubController clas
class ClubController {
  tableName = "clubs";

  index = async () => {
    const table = database.get(this.tableName);
    const clubs = await table.query(Q.sortBy("name", Q.asc)).fetch();
    for (const club of clubs) {
      const [_participant] = await club.participants.fetch();
      if (_participant) {
        const _division = await _participant.division.fetch();
        if (_division) {
          _participant._division = _division;
        }
        club._participant = _participant;
      }
    }
    return clubs;
  };

  findOne = async (id) => {
    const table = database.get(this.tableName);
    const club = await table.find(id);
    const [_participant] = await club.participants.fetch();

    const _stadium = await club.stadium.fetch();
    if (_stadium) {
      club._stadium = _stadium;
    }

    const [_finance] = await club.finances.fetch();
    if (_finance) {
      club._finance = _finance;
    }

    if (_participant) {
      const _division = await _participant.division.fetch();
      if (_division) {
        _participant._division = _division;
      }
      club._participant = _participant;
    }

    const _staffContracts = await club.staffContracts.fetch();
    for (const _contract of _staffContracts) {
      const _staff = await _contract.staff.fetch();
      if (_staff) {
        _contract._staff = _staff;
      }
    }
    club._staffContracts = _staffContracts;

    const _seniorContracts = [];
    const _youthContracts = [];

    const _playerContracts = await club.playerContracts.fetch();
    for (const _contract of _playerContracts) {
      const _player = await _contract.player.fetch();
      if (_player) {
        const [_personal] = await _player.personals.fetch();
        if (_personal) {
          _player._personal = _personal;
        }
        const [_skills] = await _player.skills.fetch();
        if (_skills) {
          _player._skills = _skills;
        }
        _contract._player = _player;
      }

      if (_player.isYouth) {
        _youthContracts.push(_contract);
      } else {
        _seniorContracts.push(_contract);
      }
    }

    club._seniorContracts = _seniorContracts;
    club._youthContracts = _youthContracts;

    return club;
  };

  // Method to get all clubs
  // index = async (req, res) => {
  //   try {
  //     const clubs = await Club.findAll({
  //       order: [["name", "ASC"]],
  //       include: [{ model: Participant, include: [Division] }],
  //     }); // Fetch all clubs, ordered by name
  //     return res.send({ clubs }); // Send the clubs to the client
  //   } catch (err) {
  //     return res.status(500).send({ message: err });
  //   }
  // };

  // // Method to get a single club by its ID
  // findOne = async (req, res) => {
  //   try {
  //     const id = req.params.id; // Get the ID from the request parameters
  //     // Fetch the club by its primary key (ID), including related data
  //     const club = await Club.findByPk(id, {
  //       include: [
  //         Stadium,
  //         ClubFinance,
  //         {
  //           model: PlayerContract,
  //           as: "SeniorContracts",
  //           include: [
  //             {
  //               model: Player,
  //               where: {
  //                 isYouth: false,
  //               },
  //               include: [PlayerSkills, PlayerPersonal],
  //             },
  //           ],
  //         },
  //         {
  //           model: PlayerContract,
  //           as: "YouthContracts",
  //           include: [
  //             {
  //               model: Player,
  //               where: {
  //                 isYouth: true,
  //               },
  //               include: [PlayerSkills, PlayerPersonal],
  //             },
  //           ],
  //         },
  //         { model: StaffContract, include: [Staff] },
  //       ],
  //     });

  //     if (!club) {
  //       return res.status(404).send({ message: "Club not found!" });
  //     }
  //     return res.send({ club }); // Send the club to the client
  //   } catch (err) {
  //     console.log(err);
  //     return res.status(500).send({ message: err });
  //   }
  // };
}

export default new ClubController();
