import React, { useState } from 'react';

const roundNames = ["Last 32 Match", "Last 16 Match", "Quarter-Final Match", "Semi-Final Match", "Final Match"];

const last32Explanations = [
  "These 32 clubs will take earn 1.000.000 for losing and 1.500.000 for winning this last32 Round!",
  "Last 16 clubs will take 1.000.000 for losing but 2.500.000 for winning this round!",
  "The Quarter Final. Teams will take 2.000.000 for losing but 5.000.000 for winning this round!",
  "Semi Final! Teams will take 3.5000.000 for losing but 5.000.000 for winning this round!",
  "This is the Final! The winner will take a staggering 15.000.000 and the runner up will still take 7.500.000 back home to the club!",
];

const Final32Teams = [
  "Richard Batsbak Boys", "Jong Brabant 7", "FC Bal op het Dak", "Team D",
  "Team E", "Team F", "Team G", "Team H",
  "Team I", "Team J", "Team K", "Team L",
  "Team M", "Team N", "Team O", "Team P",
  "Team Q", "Team R", "Team S", "Team T",
  "Team U", "Team V", "Team W", "Team X",
  "Team Y", "Team Z", "Team AA", "Team BB",
  "Team CC", "Team DD", "Team EE", "Team FF"
];

const generateRounds = (teams) => {
    let rounds = [];
    let currentRound = teams.reduce((acc, team, index) => {
      if (index % 2 === 0) {
        acc.push({
          id: `${roundNames[rounds.length]} ${Math.floor(index / 2) + 1}`,
          teams: [team, teams[index + 1]],
          winner: null
        });
      }
      return acc;
    }, []);
  
    while (currentRound.length > 1) {
      rounds.push(currentRound);
      currentRound = currentRound.reduce((acc, match, index) => {
        if (index % 2 === 0) {
          acc.push({
            id: `${roundNames[rounds.length]} ${Math.floor(index / 2) + 1}`,
            teams: [null, null],
            winner: null
          });
        }
        return acc;
      }, []);
    }
  
    rounds.push(currentRound);
    return rounds;
};

const CompetitionsCupCompetition = () => {
  const roundNames = ["Last 32 Teams", "Last 16 Teams", "Quarter-Final", "Semi-Final", "Final"];
  const [rounds, setRounds] = useState(generateRounds(Final32Teams));

  const updateWinner = (roundIndex, matchIndex, winner) => {
    const newRounds = [...rounds];
    newRounds[roundIndex][matchIndex].winner = winner;

    if (roundIndex < newRounds.length - 1) {
      const nextMatchIndex = Math.floor(matchIndex / 2);
      const teamIndex = matchIndex % 2;
      newRounds[roundIndex + 1][nextMatchIndex].teams[teamIndex] = winner;
    }

    setRounds(newRounds);
  };

  return (
    <>
      <div className="board-main p-2 mb-2 rounded bg-black/20 font-bold text-xl">
        
        <div className="game-status mb-2 bg-black/50 text-center font-bold text-yellow-400 py-2">
          Devnote: Will need to make a logic for Qualifications for the Cup Competition. Top division 18 teams plus 14 remainers from the Qualifications will make it to the Last 32 teams! 
          I want to start with a qualifying tournament that takes all clubs from Divisisions 2 up to 4 and let them play a knockout tournament to determine the 14 remainers that will join the top division clubs in the Last 32 teams!
        </div>
        
        <div className="game-status mb-2 bg-black/50 text-center text-xl font-bold text-yellow-400 py-2">
          Yearly Cup Competition with all teams from all divisions!
        </div>
        <div className="text-center description mb-4 text-white">
          <p className="font-bold text-lg mb-3">Welcome to the most exciting and prestigious cup competition in the world!</p>
          This league offers incredible entertainment and the chance for football clubs to earn significant amounts of prize money. 
          Clubs will battle through various rounds, starting with all teams, and only the best will advance to the final 32 teams and will earn serious TV and prize money. The finalists have a chance at ultimate glory by taking home the prestigious Cup!
        </div>
        <div className="bg-black bg-opacity-50 p-2 rounded mb-6">
          <h2 className="text-center text-bold text-3xl font-bold mb-4 text-yellow-400 animate-pulse">Qualification Round</h2>
          <p className="text-white text-center">This is the Qualification Round. All clubs from Divisions 2 up to 4 will play a knockout tournament to determine the 14 remainers that will join the top division clubs in the Last 32 teams!</p>
        </div>
        

          <div className="h-200 overflow-y-auto">
            {rounds.map((round, roundIndex) => (
              <div key={roundIndex} className="mb-8">
                <div className="bg-black bg-opacity-50 p-2 rounded">
                  <h2 className="text-center text-bold text-3xl font-bold mb-4 text-yellow-400 animate-pulse">{roundNames[roundIndex]}</h2>
                  <p className="text-white text-center">{last32Explanations[roundIndex]}</p>
                </div>
                <div className={roundIndex === rounds.length - 1 ? "" : "grid grid-cols-2 gap-4 mt-8"}>
                  {round.map((match, matchIndex) => (
                    <div key={match.id} className="p-4 border rounded mb-4 bg-gray-700">
                      <h3 className="mb-2 text-white">Match: {roundNames[roundIndex]} {matchIndex + 1}</h3>
                      {/* <h3 className="mb-2 text-white">Match {match.id}</h3> */}
                      <div className="flex justify-between items-center">
                        {match.teams.map((team, index) => (
                          <button 
                            key={index}
                            onClick={() => updateWinner(roundIndex, matchIndex, team)} 
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                          >
                            {team || 'TBD'}
                          </button>
                        ))}
                      </div>
                      {match.winner && <p className="mt-2 text-green-600">Winner: {match.winner}</p>}
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default CompetitionsCupCompetition;