import { Link, Outlet, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import ClubScreenHeader from "../helpers/ClubScreenHeader";

const CompetitionMain = (props) => {
  const tabs = [
    { name: "First Division", to: "/competitions" },
    { name: "Second Division", to: "/competitions/second-division" },
    { name: "Third Division", to: "/competitions/third-division" },
    { name: "Fourth Division", to: "/competitions/fourth-division" },
    { name: "Cup Competition", to: "/competitions/cup-competition" },
  ];

  const location = useLocation();

  /*** @ Club data already retrived at the top level Main.js so no need again here ***/
  /*** GOOD COMMENT THANKS Ruud ***/

  return (
    <div className="game-main">

      <ClubScreenHeader title="All Competitions" />

      <div className="mb-2 w-full grid grid-cols-5 gap-2">
        {tabs.map((tab, index) => (
          <Button
            key={index}
            variant="outlined"
            color="primary"
            component={Link}
            to={tab.to}
            className={`w-full ${location.pathname.replace(/\/$/, "") === tab.to ? "active-tab" : ""}`} // Apply active-tab class for the active tab
          >
            {tab.name}
          </Button>
        ))}
      </div>

      <Outlet />

      <div className="bottom-menu mb-2 grid grid-cols-1 gap-1">
        <button onClick={props.onGoBack} className="system-btn">
          Back
        </button>
      </div>
    </div>
  );
};

export default CompetitionMain;