import { useState } from "react";

const PlayerRatings = () => {
   return (
      <div className="board-main mb-2 rounded bg-black/30 p-2">
         <div className="h-96 overflow-y-auto grid grid-cols-2">
            <div>
               <table className="w-full">
                  {Array(10)
                     .fill(1)
                     .map((v) => (
                        <tr className="border-b border-gray-500">
                           <td className="w-16 text-yellow-400 px-2 text-center">
                              <button className="border px-4 rounded-sm shadow-sm">1</button>
                           </td>
                           <td className="text-white">Gavin Kelly</td>
                           <td className="text-orange-300 text-center">inj 30</td>
                           <td className="text-blue-300 text-center w-6">5</td>
                           <td className="text-yellow-300 text-center w-6">1</td>
                        </tr>
                     ))}
               </table>
            </div>
            <div>
               <table className="w-full">
                  {Array(10)
                     .fill(1)
                     .map((v) => (
                        <tr className="border-b border-gray-500">
                           <td className="w-16 text-yellow-400 px-2 text-center">
                              <button className="border px-4 rounded-sm shadow-sm">1</button>
                           </td>
                           <td className="text-white">Gavin Kelly</td>
                           <td className="text-orange-300 text-center">on 30</td>
                           <td className="text-blue-300 text-center w-6">5</td>
                           <td className="text-yellow-300 text-center w-6"></td>
                        </tr>
                     ))}
               </table>
            </div>
         </div>
      </div>
   );
};

export default PlayerRatings;
