import { Model } from "@nozbe/watermelondb";
import { field, text, children, date } from "@nozbe/watermelondb/decorators";

class Competition extends Model {
  static table = "competitions";

  static associations = {
    divisions: { type: "has_many", foreignKey: "competition_id" },
  };

  @children("divisions") divisions;

  @text("name") name;
}

export default Competition;
