import database from "..";

const participantSeeder = {
  tableName: "participants",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);

      const clubs = await database.get("clubs").query().fetch();
      const divisions = await database.get("divisions").query().fetch();

      for (let j = 0; j < divisions.length; j++) {
        let selectedClubs = clubs.splice(0, divisions[j].participantsCount);
        for (let i = 0; i < selectedClubs.length; i++) {
          await table.create((tb) => {
            tb.club.set(selectedClubs[i]);
            tb.division.set(divisions[j]);
          });
        }
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default participantSeeder;
