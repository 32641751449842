import { useEffect, useState } from "react";
import PlayerHeader from "./PlayerHeader";
import Profile from "./Profile";
import Contract from "./Contract";
import Injuries from "./Injuries";
import Transfer from "./Transfer";
import History from "./History";
import { playersController } from "../../database/controllers";

const Modal = ({ children }) => {
  return (
    <div className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-black/50" id="exampleModalXl">
      <div className="pointer-events-none relative w-auto translate-y-[-50px] transition-all duration-300 ease-in-out mt-20 min-[576px]:mx-auto min-[576px]:mt-20 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]">
        <div className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-4 outline-none dark:bg-surface-dark">
          <div className="relative p-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

const PlayerMain = ({ playerId, closeModal }) => {
  const [player, setPlayer] = useState(null);
  const [currentTab, setCurrentTab] = useState("profile");

  useEffect(() => {
    playersController
      .findOne(playerId)
      .then((player) => {
        console.log(player);
        setPlayer(player);
      })
      .catch(console.error);
  }, [playerId]);

  const getCurrentTab = () => {
    switch (currentTab) {
      case "profile":
        return <Profile player={player} />;
      case "injuries":
        return <Injuries player={player} />;
      case "contract":
        return <Contract player={player} />;
      case "transfer":
        return <Transfer player={player} />;
      case "history":
        return <History player={player} />;
      default:
        return <Profile player={player} />;
    }
  };

  if (!player) {
    return (
      <Modal>
        <div className="w-full bg-gray-100">
          <div className="py-3 text-center text-xl">Loading...</div>
          <div className="bottom-menu mt-3 grid grid-cols-1 gap-1">
            <button onClick={closeModal} className="system-btn">
              Close
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal>
      <div className="w-full bg-gray-100">
        <PlayerHeader player={player} currentTab={currentTab} setCurrentTab={setCurrentTab} />

        <div>{getCurrentTab()}</div>

        <div className="bottom-menu mt-3 grid grid-cols-1 gap-1">
          <button onClick={closeModal} className="system-btn">
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PlayerMain;
