import moment from "moment";
import { Link } from "react-router-dom";

const StaffContract = ({ staff }) => {
  // const staffMonthlyWage = club.StaffContracts.find(contract => contract.staffId === staffId)?.monthlyWage;

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-2 py-2 game-status mb-2 bg-black/75 text-center text-xl font-bold text-white">
        To hire more staff
        <Link to="/club/manage" className="ml-2 text-blue-500 underline">
          visit and upgrade the staffbuilding.
        </Link>
      </div>

      <table className="table-auto w-full text-left whitespace-no-wrap">
        <tbody className="mb-3 mt-3">
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Montly wage</td>
            <td className="px-4 py-2 border-b border-gray-200">${staff._contract?.monthlyWage}</td>
            {/* For player this is   $ {player.PlayerContract?.monthlyWage.toLocaleString()} */}
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Offer New Contract</td>
            <td className="px-4 py-2 border-b border-gray-200">
              <button className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-700">Perform</button>
            </td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Terminate Contract</td>
            <td className="px-4 py-2 border-b border-gray-200">
              <button className="px-4 py-2 rounded bg-red-500 text-white hover:bg-red-700">Perform</button>
            </td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Contract Length</td>
            <td className="px-4 py-2 border-b border-gray-200">{staff._contract?.contractLength}</td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Contract Expire Date</td>
            <td className="px-4 py-2 border-b border-gray-200">{staff._contract?.contractExpires ? moment(staff._contract?.contractExpires).format("ll") : ""}</td>
          </tr>
          <tr className="hover:bg-gray-100 font-bold text-lg">
            <td className="px-4 py-2 border-b border-gray-200">Contract Bonusses</td>
            <td className="px-4 py-2 border-b border-gray-200">{staff._contract?.bonuses}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default StaffContract;
