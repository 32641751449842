import database from "..";
import { faker } from "@faker-js/faker";

const physioSeeder = {
  tableName: "staffs",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);
      const clubs = await database.get("clubs").query().fetch();

      const maxPerClub = 3;
      const physiosCount = clubs.length * maxPerClub;

      for (let i = 0; i < physiosCount; i++) {
        await table.create((tb) => {
          tb.name = faker.person.fullName();
          tb.role = "physio";
          //Physio are older then footballplayers so the must be aged between 35 and 50
          tb.birthDate = faker.date.between({
            from: new Date(new Date().setFullYear(new Date().getFullYear() - 50)),
            to: new Date(new Date().setFullYear(new Date().getFullYear() - 35)),
          });
          tb.nationality = faker.location.country();
          tb.physiotherapy = faker.number.int({ min: 3, max: 10 });
          tb.rehabilitationTherapy = faker.number.int({ min: 2, max: 10 });
          tb.communication = faker.number.int({ min: 1, max: 10 });
          tb.experience = faker.number.int({ min: 1, max: 10 });
          tb.workingWithYouth = faker.number.int({ min: 1, max: 10 });
        });
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default physioSeeder;
