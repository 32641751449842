import { Model } from "@nozbe/watermelondb";
import { field, text, children, relation, date } from "@nozbe/watermelondb/decorators";

class Participant extends Model {
  static table = "participants";

  static associations = {
    clubs: { type: "belongs_to", key: "club_id" },
    divisions: { type: "belongs_to", key: "division_id" },
  };

  @relation("clubs", "club_id") club;
  @relation("divisions", "division_id") division;

  @field("played") played;
  @field("wins") wins;
  @field("draws") draws;
  @field("loses") loses;
  @field("points") points;
  @field("goals_ag") goalsAg;
  @field("goals_for") goalsFor;
}

export default Participant;
