import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ClubHeader from "./ClubHeader";
import ClubViewInfo from "./ClubInfo";
import ClubViewFinances from "./ClubFinances";
import ClubViewFixtures from "./ClubFixtures";
// import ClubViewManage from "./ClubManage";
import ClubViewSquad from "./ClubSquad";
import ClubViewYouthPlayers from "./ClubYouthPlayers";
import { clubsController } from "../../database/controllers";

const Modal = ({ children }) => {
  return (
    <div className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-black/50" id="exampleModalXl">
      <div className="pointer-events-none relative w-auto translate-y-[-50px] transition-all duration-300 ease-in-out mt-20 min-[576px]:mx-auto min-[576px]:mt-20 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]">
        <div className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-4 outline-none dark:bg-surface-dark">
          <div className="relative p-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

const ClubViewMain = ({ clubId, closeModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [club, setClub] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Info");

  useEffect(() => {
    if (clubId) {
      clubsController
        .findOne(clubId)
        .then((club) => {
          setClub(club);
        })
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    } else {
      navigate("/");
    }
  }, [clubId, dispatch, navigate]);

  const getSelectedTab = () => {
    switch (selectedTab) {
      case "Info":
        return <ClubViewInfo club={club} />;
      case "Finances":
        return <ClubViewFinances club={club} />;
      case "Fixtures":
        return <ClubViewFixtures club={club} />;
      // case "Manage":
      //   return <ClubViewManage club={club} />;
      case "Squad":
        return <ClubViewSquad club={club} />;
      case "Youth":
        return <ClubViewYouthPlayers club={club} />;
      default:
        return <ClubViewInfo club={club} />;
    }
  };

  if (!club) {
    return (
      <Modal>
        <div className="game-main">
          <div className="py-3 text-center text-xl">Loading...</div>
          <div className="bottom-menu mt-3 grid grid-cols-1 gap-1">
            <button onClick={closeModal} className="system-btn">
              Close
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal>
      <div className="game-main">
        <ClubHeader club={club} selectedTab={selectedTab} setSelectedTab={(tab) => setSelectedTab(tab)} />

        {getSelectedTab()}

        <div className="bottom-menu mb-2 grid grid-cols-1 gap-1">
          <button onClick={closeModal} className="system-btn">
            Back
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ClubViewMain;
