import { Model } from "@nozbe/watermelondb";
import { field, text, children, date } from "@nozbe/watermelondb/decorators";

class Staff extends Model {
  static table = "staffs";

  static associations = {
    staff_contracts: { type: "has_many", foreignKey: "staff_id" },
  };

  @children("staff_contracts") contracts;

  @text("name") name;
  @text("role") role;
  @date("birth_date") birthDate;
  @text("nationality") nationality;
  @field("experience") experience;
  @field("physiotherapy") physiotherapy;
  @field("rehabilitation_therapy") rehabilitationTherapy;
  @field("communication") communication;
  @field("judging_ability") judgingAbility;
  @field("vision_on_potential") visionOnPotential;
  @field("working_with_youth") workingWithYouth;
  @field("tactical_knowledge") tacticalKnowledge;
  @field("motivator_abilities") motivatorAbilities;
  @field("negotiation") negotiation;
  @field("networking") networking;
  @field("development_abilities") developmentAbilities;
  @field("coaching_goalkeepers") coachingGoalkeepers;
  @field("coaching_field_players") coachingFieldPlayers;
}

export default Staff;
