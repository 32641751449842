// import React from "react";

// @@@@@@@ This logic must be moved to the TacticsFormationScreenHelper.js file @@@@@@@@@@@@@@@@@@@@@@@@@@@
// Temporary data for the tactical positions should be the positions and player from your Squat selection
const tacticalPositions = ["GK", "DL", "DC", "DC", "DC", "DR", "ML", "MC", "MR", "FC", "FC", "SB1", "SB2", "SB3", "SB4", "SB5", "SB6", "SB7", "SB8", "SB9"];
const PlayerPositions = ({ tacticalPositions }) => {
  const handleDragStart = (event, position) => {
    event.dataTransfer.setData("text/plain", position.id);
  };

  return (
      <div className="rounded positions mb-2 bg-black/50 text-center text-sm sm:text-xs md:text-lg lg:text-xl font-bold text-white py-1 gap-0.5 flex">
      {tacticalPositions.map((position, index) => (
        <button
          key={index}
          className={position.playerId ? "field-position-btn bg-custom-gold text-black" : "field-position-btn border-gray-700 bg-black text-white opacity-50"}
          draggable
          onDragStart={(event) => handleDragStart(event, position)}
        >
          {position.name}
        </button>
      ))}
    </div>
  );
};

export { tacticalPositions, PlayerPositions };