import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import { useState } from "react";
import playIcon from "./assets/images/icons/play.svg";
import fastForwardIcon from "./assets/images/icons/forward.svg";
import { NavLink, Outlet } from "react-router-dom";
import logo from "./assets/images/logo.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./assets/css/game.css";

const Main = (props) => {
  const { t } = useTranslation();
  //addded for showing new inbox items to manager within the Inbox Button
  const unreadCount = 5; // Example count, replace with your dynamic value

  const [currentDate, setCurrentDate] = useState(new Date());

  const options = { day: "2-digit", year: "numeric", month: "short" };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);

  const incrementDate = (days) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + days);
    setCurrentDate(newDate);
  };

  const handlePlayClick = () => {
    incrementDate(1);
  };

  const handleFastForwardClick = () => {
    incrementDate(7);
  };

  const clubId = useSelector((state) => state.club.id);

  return (
    <div className="game-cont flex min-h-screen">
      <div className="left-menu">
        <img src={logo} alt="Logo" className="mx-auto mb-4" />
        <div className="animate-pulse text-black font-bold text-lg border border-black rounded bg-gray-300 shadow w-full flex justify-center mb-2 py-2 p-2">{formattedDate}</div>
        <div className="flex justify-between">
          <button onClick={handlePlayClick} className="menu-btn rounded-lg flex-grow mb-4 mr-1 h-full">
            <img src={playIcon} alt="Play" className="mx-auto" />
          </button>
          <button onClick={handleFastForwardClick} className="menu-btn rounded-lg flex-grow h-full">
            <img src={fastForwardIcon} alt="Fast Forward" className="mx-auto" />
          </button>
        </div>

        <div className="menu-btn rounded-lg" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Badge color="primary" badgeContent={unreadCount} invisible={unreadCount === 0}>
            <NavLink
              to="/inbox"
              style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center", height: "100%" }}
              className={({ isActive }) => (isActive ? "active-menu-btn" : "")}
            >
              {t("menu.inbox")} <MailIcon className="ml-2" color="secondary" sx={{ "& .MuiBadge-badge": { backgroundColor: "green", color: "white", fontSize: "0.75rem" } }} />
            </NavLink>
          </Badge>
        </div>

        <NavLink to="/competitions" className={({ isActive }) => (isActive ? "menu-btn active-menu-btn" : "menu-btn rounded-lg")}>
          {t("menu.competitions")}
        </NavLink>
        {clubId && (
          <NavLink to="/club" className={({ isActive }) => (isActive ? "menu-btn active-menu-btn" : "menu-btn rounded-lg")}>
            {t("menu.manageTeam")}
          </NavLink>
        )}
        <NavLink to="/scouting" className={({ isActive }) => (isActive ? "menu-btn active-menu-btn" : "menu-btn rounded-lg")}>
          {t("menu.scouting")}
        </NavLink>
        <NavLink to="/settings" className={({ isActive }) => (isActive ? "menu-btn active-menu-btn" : "menu-btn rounded-lg")}>
          {t("menu.settings")}
        </NavLink>
        <NavLink to="/credits" className={({ isActive }) => (isActive ? "menu-btn active-menu-btn" : "menu-btn rounded-lg")}>
          {t("menu.credits")}
        </NavLink>
        <NavLink to="/match" className={({ isActive }) => (isActive ? "menu-btn active-menu-btn" : "menu-btn rounded-lg")}>
          inGame match screen
        </NavLink>

        <button onClick={props.onEndGame} className="menu-btn">
          {t("menu.exitGame")}
        </button>
        <a href="https://github.com/InteractiveWebdesign/football-new" target="_blank" rel="noopener noreferrer">
          <div className="text-black text-lg border border-cyan-500 rounded bg-gray-200 font-bold shadow w-full flex justify-center mb-2 py-2">Version 1.0.4</div>
        </a>
      </div>

      <Outlet />
    </div>
  );
};

export default Main;
