import database from "..";
import { faker } from "@faker-js/faker";
import { Q } from "@nozbe/watermelondb";

const scoutContractSeeder = {
  tableName: "staff_contracts",

  async up() {
    await database.write(async () => {
      const table = database.get(this.tableName);

      const clubs = await database.get("clubs").query().fetch();
      const staffs = await database.get("staffs").query(Q.where("role", "scout")).fetch();

      for (let i = 0; i < clubs.length; i++) {
        const min = 3;
        const max = 5;
        const staffCount = Math.floor(Math.random() * (max - min) + min);

        for (let j = 0; j < staffCount; j++) {
          const contractLengthYears = faker.number.int({ min: 1, max: 5 }); // Generate contract length for each contract

          await table.create((tb) => {
            tb.club.set(clubs[i]);
            tb.staff.set(staffs[i]); // Ensure staffId cycles through available staffs
            tb.contractLength = `${contractLengthYears} Year`;
            tb.contractExpires = new Date(new Date().setFullYear(new Date().getFullYear() + contractLengthYears)).toISOString().split("T")[0];
            tb.monthlyWage = Math.floor(faker.number.int({ min: 4, max: 20 })) * 400;
            tb.value = faker.number.float({ min: 6000, max: 999999 });
            tb.bonuses = faker.number.int({ min: 1000, max: 10000 });
            tb.happiness = "Happy";
          });
        }
      }

      const all = await database.get(this.tableName).query().fetch();
      console.log(all);
    });
  },

  async down() {
    await database.write(async () => {
      const all = await database.get(this.tableName).query().fetch();
      for (let _std of all) {
        const std = await database.get(this.tableName).find(_std.id);
        std.destroyPermanently();
      }
    });
  },
};

export default scoutContractSeeder;
