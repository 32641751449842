import { useEffect, useState } from "react";
import ChartContainer from "../helpers/ClubFinancePieChart";
import { useSelector } from "react-redux";
import { clubsController } from "../../database/controllers";

const renderTableRow = (label, value, type) => {
  let textColor = type === "income" ? "text-green-500 text-center" : "text-red-400 text-center";
  let bgColor = label === "Total" ? "bg-white" : "";

  return (
    <tr className={`${textColor} ${bgColor} border-b border-gray-500`}>
      <td className="text-custom-gold px-2 font-extrabold text-center">{label}</td>
      <td className={textColor}>{value}</td>
    </tr>
  );
};

const ClubFinances = () => {
  const clubId = useSelector((state) => state.club.id);
  const [club, setClub] = useState(null);

  useEffect(() => {
    if (clubId) {
      clubsController
        .findOne(clubId)
        .then((club) => {
          setClub(club);
        })
        .catch(console.error);
    }
  }, [clubId]);

  const calculateIncome = (clubFinance) => {
    const { iTvMoney, iSponsors, iTransfers, iPriceMoney, iTicketSales, iMerchandise, iOther } = clubFinance;
    return (iTvMoney + iSponsors + iTransfers + iPriceMoney + iTicketSales + iMerchandise + iOther).toFixed(2);
  };

  const calculateExpenditure = (clubFinance) => {
    const { ePlayerSalaries, eStaffSalaries, eTransferFees, eStadiumMaintenance, eYouthDevelopment, eScouting, eOther } = clubFinance;
    return (ePlayerSalaries + eStaffSalaries + eTransferFees + eStadiumMaintenance + eYouthDevelopment + eScouting + eOther).toFixed(2);
  };

  const getProfitLoss = () => {
    return calculateIncome(club._finance) - calculateExpenditure(club._finance);
  };

  const getProfitLossClass = () => {
    const profitLoss = calculateIncome(club._finance) - calculateExpenditure(club._finance);
    // const formattedProfitLoss = profitLoss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return profitLoss >= 0 ? "game-status border-b border-gray-500 bg-green-200 text-green-700" : "game-status bg-red-200 text-red-700";
  };

  // added to start showing a message based on the profit/loss in the UI
  const getProfitLossMessage = () => {
    const profitLoss = calculateIncome(club._finance) - calculateExpenditure(club._finance);
    if (profitLoss > 0) {
      return <span>The club made a profit this month. Great job Manager!</span>;
    } else if (profitLoss < 0) {
      return <span>The club made a loss this month. Let's try to improve this if structural.</span>;
    } else {
      return <span>The club broke even this month.</span>;
    }
  };

  if (!club) {
    return <div className="game-main">Loading...</div>;
  }

  return (
    <>
      <div className="game-status rounded">{getProfitLossMessage()}</div>
      <div className="board-main mb-2 rounded bg-black/50 p-2">
        <div className="overflow-y-auto grid sm:grid-cols-1 md:grid-cols-2 gap-2 mb-6">
          <button className="system-btn">Income</button>
          <button className="system-btn">Expenditure</button>
        </div>

        <div className="overflow-y-auto grid sm:grid-cols-1 md:grid-cols-2 gap-2" style={{ height: 240 }}>
          <table className="w-full">
            <tbody>
              {renderTableRow("Tv Money", "$ " + club._finance.iTvMoney.toLocaleString(), "income")}
              {renderTableRow("Sponsors", "$ " + club._finance.iSponsors.toLocaleString(), "income")}
              {renderTableRow("Transfers", "$ " + club._finance.iTransfers.toLocaleString(), "income")}
              {renderTableRow("Price Money", "$ " + club._finance.iPriceMoney.toLocaleString(), "income")}
              {renderTableRow("Ticket Sales", "$ " + club._finance.iTicketSales.toLocaleString(), "income")}
              {renderTableRow("Merchandise", "$ " + club._finance.iMerchandise.toLocaleString(), "income")}
              {renderTableRow("Other", "$ " + club._finance.iOther.toLocaleString(), "income")}
            </tbody>
          </table>

          <table className="w-full">
            <tbody>
              {renderTableRow("Player Salaries", "$ " + club._finance.ePlayerSalaries.toLocaleString(), "expenditure")}
              {renderTableRow("Staff Salaries", "$ " + club._finance.eStaffSalaries.toLocaleString(), "expenditure")}
              {renderTableRow("Transfer Fees", "$ " + club._finance.eTransferFees.toLocaleString(), "expenditure")}
              {renderTableRow("Stadium Maintenance", "$ " + club._finance.eStadiumMaintenance.toLocaleString(), "expenditure")}
              {renderTableRow("Youth Development", "$ " + club._finance.eYouthDevelopment.toLocaleString(), "expenditure")}
              {renderTableRow("Scouting", "$ " + club._finance.eScouting.toLocaleString(), "expenditure")}
              {renderTableRow("Other", "$ " + club._finance.eOther.toLocaleString(), "expenditure")}
            </tbody>
          </table>
        </div>

        <div className="rounded bg-black/50 text-center text-lg font-bold text-white overflow-y-auto grid grid-cols-2 gap-2">
          <div className="highlight text-center">
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="bigText">Total Income</td>
                  <td className="game-status py-2 border-b border-gray-500 bg-green-200 text-green-700 roundex-xl">
                    {"$ " + Number(calculateIncome(club._finance)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="ml-2 highlight text-center">
            <table className="w-full">
              <thead>
                <tr>
                  <td className="bigText">Total Expenditure</td>
                  <td className="game-status bg-red-200 text-red-700 roundex-xl py-2">
                    {"$ " + Number(calculateExpenditure(club._finance)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>

      <div className="game-status py-2 px-2 overflow-y-auto grid grid-cols-2 gap-2 rounded">
        <div className="bigText px-2 text-left justify-center items-center">Profit/Loss</div>
        <div className={`game-status border-b border-gray-500 bg-green-200 text-green-700 ${getProfitLossClass()}`}>
          {"$ " + getProfitLoss().toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </div>
      </div>

      <div className="game-status">Expected Season Results</div>

      <ChartContainer />
    </>
  );
};

export default ClubFinances;
