import { Model, Q } from "@nozbe/watermelondb";
import { field, text, children, date, lazy } from "@nozbe/watermelondb/decorators";

class Player extends Model {
  static table = "players";

  static associations = {
    player_skills: { type: "has_many", foreignKey: "player_id" },
    player_personals: { type: "has_many", foreignKey: "player_id" },
    player_contracts: { type: "has_many", foreignKey: "player_id" },
  };

  @children("player_skills") skills;
  @children("player_personals") personals;
  @children("player_contracts") contracts;

  @text("name") name;
  @date("birth_date") birthDate;
  @text("nationality") nationality;
  @text("playing_position") playingPosition;
  @field("is_youth") isYouth;
  @text("morale") morale;
  @field("condition") condition;
  @field("injured") injured;
  @text("squad_status") squadStatus;

  // @lazy
  // skills = this.collections.get("player_skills").query(Q.where("club_id", this.id));

  // @lazy
  // personal = this.collections.get("player_personals").query(Q.where("club_id", this.id));

  // @lazy
  // contract = this.collections.get("player_contracts").query(Q.where("club_id", this.id));
}

export default Player;
